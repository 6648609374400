import { concatSlash } from "../../../core/helper/text";
import { FormConfig } from "../../../core/interfaces/form-config/basic-form";
import { CommonListConfig } from "../page-configs.helper";

const rootPath = '../../../../assets/json/market';

export const MARKET_TABLE = concatSlash([rootPath, 'market-table.json']);
export const MARKET_SEARCH_FORM = concatSlash([rootPath, 'market-search-form.json']);
export const MARKET_FORM = concatSlash([rootPath, 'market-form.json']);
export const EDIT_FORM = concatSlash([rootPath, 'edit-detail-form.json']);
export const WAREHOUSE_FORM = concatSlash([rootPath, 'warehouse-form.json']);
export const WAREHOUSE_TABLE = concatSlash([rootPath, 'warehouse-table.json']);

export const ConfigFeatureKey = 'marketConfig';

export interface ConfigState {
    marketConfig?: CommonListConfig;
    warehouseConfig?: CommonListConfig;
    marketInfoConfig?: FormConfig;
}