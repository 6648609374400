import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { assignIn, clone, cloneDeep, forEach, isString, trim } from 'lodash';
import { Observable, map } from 'rxjs';

import { FormType } from '../../constant/form';
import {
    ControlAction,
    FormConfig,
    FormMode,
} from '../../interfaces/form-config/basic-form';
import { MessageDialogService } from '../message-dialog/message-dialog.service';
import { DynamicControlComponent } from './dynamic-control/dynamic-control.component';

@Component({
    selector: 'app-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent implements OnInit, OnChanges {
    @ViewChild('controls') controls?: DynamicControlComponent;

    @Input() config!: FormConfig;
    @Input() value?: any;
    @Input() options?: { [key: string]: any[] };
    @Input() mode?: FormMode;
    @Input() preventReload?: boolean;

    @Output() inputActionClick = new EventEmitter<ControlAction>();
    @Output() discard = new EventEmitter();
    @Output() ready = new EventEmitter();
    @Output() download = new EventEmitter();

    mode$!: Observable<FormMode>;

    FormType = FormType;
    submit: boolean = false;
    backupValue: any = {};

    constructor(
        private route: ActivatedRoute,
        private messageService: MessageDialogService
    ) {}

    get formValue() {
        return clone(this.controls?.formGroup.getRawValue());
    }

    get formGroup() {
        return this.controls?.formGroup;
    }

    get formValid() {
        return this.controls?.formGroup.valid;
    }

    get formDirty() {
        return this.controls?.formGroup.dirty;
    }

    ngOnInit(): void {
        this.mode$ = this.route.queryParams.pipe(map((res) => res['mode']));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['value']?.currentValue)
            this.backupValue = cloneDeep(this.value);
    }

    getControl(name: string) {
        return this.formGroup?.get(name);
    }

    refresh(backupValue: any) {
        this.backupValue = cloneDeep(backupValue);
        this.submit = false;
        this.controls?.formGroup.markAsPristine();
    }

    reset(value?: any) {
        this.controls?.formGroup.reset(value);
    }

    checkError(submit: boolean = true) {
        // Set submit flag
        this.submit = submit;

        forEach(this.controls?.formGroup.controls, (e, i) => {
            if (isString(e.value)) {
                e.setValue(trim(e.value));
            } else {
                e.setValue(e.value);
            }

            // Mark dirty and touch to detect leave page
            e.markAsDirty();
            e.markAsTouched();
        });

        // check change detection
        setTimeout(() => {
            this.controls?._cdr.markForCheck();
        }, 100);
    }

    discardChanges(dialog?: boolean) {
        const formGroup = this.controls?.formGroup;

        if (formGroup?.dirty || dialog) {
            this.messageService.show(
                {
                    message: 'MSG_CONFIRM_002',
                    action: {
                        accept: () => {
                            this.discardValue();
                        },
                    },
                },
                'confirm'
            );
        } else {
            this.discard.emit();
        }
    }

    discardValue(isEmit: boolean = true) {
        this.formGroup?.patchValue(
            assignIn(this.formGroup?.getRawValue(), this.backupValue)
        );
        this.formGroup?.markAsPristine();
        this.submit = false;
        if (isEmit) {
            this.discard.emit();
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    onReloadPage($event: any) {
        if (this.formGroup?.dirty && this.preventReload) {
            $event.preventDefault();
            $event.returnValue = '';
        }
    }
}
