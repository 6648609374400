import { createReducer, on } from '@ngrx/store';
import { ConfigState } from './dashboard-config';
import { DashboardActions } from './dashboard-config.action';

export const reducer = createReducer(
    {} as ConfigState,
    on(DashboardActions.loadLocalDashboardSuccess, (state, { data }) => ({
        ...state,
        localDashboardConfig: data,
    })),
    on(DashboardActions.loadCountAssignmentSuccess, (state, { data }) => ({
        ...state,
        countAssignmentConfig: data,
    })),
    on(DashboardActions.loadCountingStatusPerDocSuccess, (state, { data }) => ({
        ...state,
        countingStatusPerDocConfig: data,
    })),
    on(
        DashboardActions.loadPrincipalCountResultsDashboardSuccess,
        (state, { data }) => ({
            ...state,
            principalCountResultsDashboardConfig: data,
        })
    ),
    on(DashboardActions.loadRegionalDashboardSuccess, (state, { data }) => ({
        ...state,
        regionalDashboardConfig: data,
    })),
    on(
        DashboardActions.loadLocationAccuracyDashboardSuccess,
        (state, { data }) => ({
            ...state,
            locationAccuracyRateConfig: data,
        })
    ),
    on(DashboardActions.loadItemAccuracyRateSuccess, (state, { data }) => ({
        ...state,
        itemAccuracyRateConfig: data,
    })),
    on(DashboardActions.loadStockAccuracyNetSuccess, (state, { data }) => ({
        ...state,
        stockAccuracyNetConfig: data,
    })),
    on(DashboardActions.loadStockAccuracyGrossSuccess, (state, { data }) => ({
        ...state,
        stockAccuracyGrossConfig: data,
    })),
    on(
        DashboardActions.loadStockCountingFrequencySuccess,
        (state, { data }) => ({
            ...state,
            stockCountingFrequencyConfig: data,
        })
    ),
    on(DashboardActions.loadMarketPerformanceSuccess, (state, { data }) => ({
        ...state,
        marketPerformanceConfig: data,
    }))
);
