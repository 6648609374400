import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigFeatureKey, ConfigState } from './action-history-config';

const PageConfigState = createFeatureSelector<ConfigState>(ConfigFeatureKey);

export class ActionHistorySelector {
    static readonly searchFormConfig = createSelector(PageConfigState, (state) => state.searchConfig);
    static readonly materialTableConfig = createSelector(PageConfigState, (state) => state.materialTable);
    static readonly storageBinTableConfig = createSelector(PageConfigState, (state) => state.storageBinTable);
    static readonly productTableConfig = createSelector(PageConfigState, (state) => state.productTable);
    static readonly actionHistoryDetail = createSelector(PageConfigState, (state) => state.detailConfig);
}