export class CommonRegex {
    static POSITIVE_DECIMAL = /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)?$/;
    static POSITIVE_2_DECIMAL_PLACES = /^(?!(?:0|0\.0|0\.00)$)[+]?\d+(\.\d|\.\d[0-9])?$/;
    static POSITIVE_INTEGER = /^\d+$/;
    static ISO_STRING = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
    static IMG_TAG = /<img.*?src="(.*?)"[^\>]+>/;
    static POSITIVE_INTEGER_GREATER_THAN_ZERO = /^[1-9]\d*$/;
    static ALPHABET_NUMERIC = /^[a-zA-Z0-9]*$/;
    static NUMERIC = /^[0-9]*$/;
    static SHORTNAME = /./;
    static NUMERIC_1_TO_10_VALIDATION = /^([1-9]|10|(0[1-9]))$/;
    static NUMERIC_1_TO_9999 = /^(?:[1-9][0-9]{3}|[1-9][0-9]{2}|[1-9][0-9]|[1-9]|0[1-9][0-9]{1,2}|0{1,2}[1-9][0-9]|0{1,3}[1-9])$/;
    static USERNAME = /^[a-zA-Z0-9_]{6,20}$/;
    // static PASSWORD = /(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9]*$)/;
    static PASSWORD = /^(?=.{8,14})(((?=.*[a-z])((?=.*[A-Z])|(?=.*\d)|(?=.*[!#%&\(\)\*\+,-\.\/:;\?@\$[\\\]\^_`\{}\~""\'])))|((?=.*[A-Z])((?=.*\d)|(?=.*[!#%&\(\)\*\+,-\.\/:;\?@\$[\\\]\^_`\{}\~""'])))|((?=.*\d)(?=.*[!#%&\(\)\*\+,-\.\/:;\?@\$[\\\]\^_`\{}\~""'])))(^[a-zA-Z0-9!#%&\(\)\*\+,-\.\/:;\?@\$[\\\]\^_`\{}\~""']*$)/;
    static MAIL = /^([a-zA-Z0-9]+\.?)(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~\_]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~\_]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9-]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    static DATE_FORMAT = /[12]\d{3}\/(0[1-9]|[1-9]|1[0-2])\/(0[1-9]|[1-9]|[12]\d|3[01])$/;
    static MOBILE_AGENT = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i;
    static PASSWORD_REGEXP = /(?=.{8,})(((?=.*[a-z])((?=.*[A-Z])|(?=.*\d)|(?=.*[!#%&\(\)\*\+,-\.\/:;\?@\$[\\\]\^_`\{}~"'<=>])))|((?=.*[A-Z])((?=.*\d)|(?=.*[!#%&\(\)\*\+,-\.\/:;\?@\$[\\\]\^_`\{}~"'<=>])))|((?=.*\d)(?=.*[!#%&\(\)\*\+,-\.\/:;\?@\$[\\\]\^_`\{}~"'<=>])))(^[a-zA-Z0-9!#%&\(\)\*\+,-\.\/:;\?@\$[\\\]\^_`\{}~"'<=>]*$)/;
    static PHONE_REGEX = /^[0-9]{10,20}/;
    static URL = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i;
    static GUID = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$/;
    static TIME = /^[0-9]{1,2}:[0-9]{1,2}$/;
    static NEW_PHONE_REGEX = /^[0-9\)\(\-]*$/;
    static PHONE_REGEX_LENGTH = /^[0-9\)\(\-]{10,20}/;
    static ROOM_CODE = /^[a-zA-Z0-9_\-]*$/;
    static MAC_ADDRESS = /^(?:[0-9a-fA-F]{2}:){5}[0-9a-fA-F]{2}$/;
    static HEXADECIMAL = /^([0-9A-Fa-f]{2})+$/;
    static PASSWORD_LENGTH = /^.{8,14}$/;
    static REQUIRED = /[^\s]+/;
    static USER_ID = /^([\w-\.]{3,})@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    static WAREHOUSE = /^[0-9\)\(\-]{4}$/;
    static MARKET = /^[a-zA-Z0-9]{4}$/;
    static UNICODE_SPACES = /[\u00A0]/g;
    static LEADING_APOSTROPHES = /^([\u0027])+/g;
}
