import { createAction, props } from '@ngrx/store';
import { FormConfig } from "../../../core/interfaces/form-config/basic-form";
import { TableColumn } from "../../../core/interfaces/dynamic-table";
import { CommonListConfig } from "../page-configs.helper";

export class ActionHistoryActions {
    // Action history search form config loading
    static readonly loadSearchForm = createAction('[Action History Search From] Loading');
    static readonly loadSearchFormSuccess = createAction('[Action History Search From] Success', props<{ data: FormConfig }>());

    // Load material form config
    static readonly loadMaterialTable = createAction('[Material Table Config] Loading');
    static readonly loadMaterialTableSuccess = createAction('[Material Table Config] Success', props<{ data: TableColumn[] }>());

    // Load storage bin form config
    static readonly loadStorageTable = createAction('[Storage Bin Table Config] Loading');
    static readonly loadStorageTableSuccess = createAction('[Storage Bin Table Config] Success', props<{ data: TableColumn[] }>());

    // Load product form config
    static readonly loadProductTable = createAction('[Product Table Config] Loading');
    static readonly loadProductTableSuccess = createAction('[Product Table Config] Success', props<{ data: TableColumn[] }>());

    // Load Action History Detail.
    static readonly loadActionHistoryDetailConfigs = createAction('[Action History Detail] Loading');
    static readonly loadActionHistoryDetailSuccess = createAction('[Action History Detail] Success', props<{ data: CommonListConfig }>());
}
