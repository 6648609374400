import { findIndex, isMatch } from 'lodash';

class ZpStorageItem {
    key!: string;
    value!: string;
}

export class StorageService {
    constructor() {}

    public static KEY_EZ_CACHE: string = 'KEY_EZ_CACHE';
    public static KEY_IS_LOGGED_OUT: string = 'KEY_IS_REQUEST_LOGOUT_STATE';
    public static KEY_USER_IS_FI_TEAM: string = 'KEY_USER_IS_FI_TEAM';
    public static KEY_ACTIVE_MARKET: string = 'KEY_ACTIVE_MARKET';
    public static KEY_SEARCH_PARAMS: string = 'KEY_SEARCH_PARAMS';
    public static KEY_COMPARE_PARAMS: string = 'KEY_COMPARE_PARAMS';
    public static KEY_SEARCH_TEAM_PARAMS: string = 'KEY_SEARCH_TEAM_PARAMS';
    public static KEY_SEARCH_TEAM_MEMBER_PARAMS: string = 'KEY_SEARCH_TEAM_MEMBER_PARAMS';
    public static KEY_SEARCH_STOCK_COUNT_PARAMS: string = 'KEY_SEARCH_STOCK_COUNT_PARAMS';
    public static KEY_SEARCH_STOCK_COUNT_REVIEW_PARAMS: string =
        'KEY_SEARCH_STOCK_COUNT_REVIEW_PARAMS';

    static KEY_SEARCH_LC_DSB_COUNT_ASSIGNMENT = 'KEY_SEARCH_LC_DSB_COUNT_ASSIGNMENT';
    static KEY_SEARCH_LC_DSB_COUNTING_STATUS = 'KEY_SEARCH_LC_DSB_COUNTING_STATUS';

    static KEY_SEARCH_RG_DSB_LOCATION_ACCURACY_RATE = 'KEY_SEARCH_RG_DSB_LOCATION_ACCURACY_RATE';
    static KEY_SEARCH_RG_DSB_ITEM_ACCURACY_RATE = 'KEY_SEARCH_RG_DSB_ITEM_ACCURACY_RATE';
    static KEY_SEARCH_RG_DSB_COUNTING_FREQUENCY = 'KEY_SEARCH_RG_DSB_COUNTING_FREQUENCY';
    static KEY_SEARCH_RG_DSB_STOCK_ACCURACY_NET = 'KEY_SEARCH_RG_DSB_STOCK_ACCURACY_NET';
    static KEY_SEARCH_RG_DSB_STOCK_ACCURACY_GROSS = 'KEY_SEARCH_RG_DSB_STOCK_ACCURACY_GROSS';

    public static clear() {
        localStorage.clear();
    }

    public static add(key: string, value: any): Array<ZpStorageItem> {
        const item: ZpStorageItem = new ZpStorageItem();
        item.key = key;
        item.value = value;
        let items: Array<ZpStorageItem>;
        const jsonStrage = localStorage.getItem(StorageService.KEY_EZ_CACHE);
        if (!jsonStrage) {
            items = new Array<ZpStorageItem>();
        } else if (jsonStrage.length === 0) {
            items = new Array<ZpStorageItem>();
        } else {
            items = JSON.parse(jsonStrage);
        }
        let isExsist: boolean = false;
        for (let i: number = 0; i < items.length; i++) {
            if (items[i].key === key) {
                items[i].value = value;
                isExsist = true;
            }
        }
        if (!isExsist) {
            items.push(item);
        }
        localStorage.setItem(StorageService.KEY_EZ_CACHE, JSON.stringify(items));
        return items;
    }

    public static get(key: string): string {
        const jsonStrage = localStorage.getItem(StorageService.KEY_EZ_CACHE);
        if (jsonStrage) {
            if (jsonStrage.length !== 0) {
                const items: Array<ZpStorageItem> = JSON.parse(jsonStrage);
                for (let i: number = 0; i < items.length; i++) {
                    if (key === items[i].key) {
                        return items[i].value;
                    }
                }
            }
        }
        return '';
    }

    public static delete(key: string): Array<ZpStorageItem> {
        const jsonStrage = localStorage.getItem(StorageService.KEY_EZ_CACHE);
        if (jsonStrage) {
            if (jsonStrage.length !== 0) {
                const items: Array<ZpStorageItem> = JSON.parse(jsonStrage);
                const newItems: Array<ZpStorageItem> = new Array<ZpStorageItem>();
                for (let i: number = 0; i < items.length; i++) {
                    if (key !== items[i].key) {
                        newItems.push(items[i]);
                    }
                }
                localStorage.setItem(StorageService.KEY_EZ_CACHE, JSON.stringify(newItems));
                return newItems;
            }
        }
        return [];
    }

    public static multipleDelete(vms: any, ...key: Array<string>): Array<ZpStorageItem> {
        const jsonStrage = localStorage.getItem(StorageService.KEY_EZ_CACHE);
        if (jsonStrage) {
            if (jsonStrage.length !== 0) {
                const items: Array<ZpStorageItem> = JSON.parse(jsonStrage);
                const newItems: Array<ZpStorageItem> = new Array<ZpStorageItem>();
                for (let i: number = 0; i < items.length; i++) {
                    if (findIndex(key, (o) => isMatch(o as any, items[i].key as any)) === -1) {
                        newItems.push(items[i]);
                    }
                }
                localStorage.setItem(StorageService.KEY_EZ_CACHE, JSON.stringify(newItems));
                return newItems;
            }
        }
        return [];
    }

    public static saveQueryParams(params: any, storageKey: string, excludedParams?: string[]) {
        if (excludedParams) excludedParams.forEach((key: any) => this.delete(params as any)[key]);
        StorageService.add(storageKey, JSON.stringify(params));
    }

    public static loadQueryParams(storageKey: string) {
        const storedParams = StorageService.get(storageKey);
        return storedParams ? JSON.parse(storedParams) : {};
    }
}
