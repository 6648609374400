import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { LazyLoadTranslateModule } from '../../../modules/lazy-load-translate.module';

@Component({
    selector: 'app-button',
    standalone: true,
    template: `
        <p-button
            #button
            [label]="label || '' | translate"
            [badge]="badge || ''"
            [icon]="icon || ''"
            [iconPos]="iconPos || 'left'"
            [loading]="loading || false"
            [disabled]="disabled || false"
            [styleClass]="styleClass || ''"
            (onClick)="onClick($event)"></p-button>
    `,
    styleUrls: ['./button.component.scss'],
    imports: [CommonModule, ButtonModule, LazyLoadTranslateModule],
})
export class ButtonComponent {
    @ViewChild('button') button?: any;

    @Input() label?: string;
    @Input() badge?: string;
    @Input() icon?: string;
    @Input() iconPos?: 'left' | 'right';
    @Input() loading?: boolean;
    @Input() appendTo?: string;
    @Input() disabled: boolean = false;
    @Input() styleClass?: string;

    @Output() actionClick = new EventEmitter();

    onClick = (e: any) => {
        this.actionClick.emit(e);
    };
}
