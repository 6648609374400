import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep, forEach, isEmpty, map } from 'lodash';

import { Control } from '../../../constant/form';
import { CommonRegex } from '../../../helper/regex';
import {
    ControlAction,
    FormConfig,
    FormMode,
    IValidators,
} from '../../../interfaces/form-config/basic-form';

@Component({
    selector: 'app-dynamic-control',
    templateUrl: './dynamic-control.component.html',
    styleUrls: ['./dynamic-control.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicControlComponent implements OnInit, OnChanges {
    @ViewChildren('panelControl')
    panelControl?: QueryList<DynamicControlComponent>;

    @Output() ready = new EventEmitter<FormGroup>();

    @Input() submit: boolean = false;
    @Input() mode!: FormMode;
    @Input() config!: FormConfig;
    @Input() value: any;
    @Input() options?: { [key: string]: any[] };
    @Input() delay: number = 0;

    @Output() inputActionClick = new EventEmitter<ControlAction>();

    formGroup = new FormGroup<any>({});
    Control = Control;
    renderer = 0;
    viewSkeleton = true;
    isTouched = false;

    constructor(public _cdr: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if ('config' in changes) {
            this.config = cloneDeep(changes['config']?.currentValue);
            this.createForm();
        }

        if ('value' in changes) this.config.value = this.value;

        if ('options' in changes) this.config.options = this.options;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.viewSkeleton = false;
            this._cdr.markForCheck();
        }, this.delay);
    }

    private createForm(): void {
        const { controls, updateOn } = this.config;

        forEach(controls, async (control) => {
            const { config, validators, key, required, value } = control;

            // if key exist return to prevent add more;
            if (!key || this.formGroup.get(key)) return;

            // Add default value for validators
            if (isEmpty(validators)) control.validators = [];

            // If require auto push message
            if (required)
                control.validators!.push({
                    msgId: 'IEM_001',
                    name: 'required',
                });

            // Add new control to form group
            this.formGroup.addControl(
                key,
                new FormControl(value, {
                    validators: this.getValidators(control.validators),
                    updateOn,
                })
            );

            if (config?.disabled) this.formGroup.get(key)?.disable();
        });
        this._cdr.markForCheck();
        this.config.value = this.value;
        this.ready.emit(this.formGroup);
    }

    private getValidators(validators?: IValidators[]) {
        return map(validators, (v) => {
            switch (v.name) {
                case 'userId':
                    return Validators.pattern(CommonRegex.USER_ID);
                case 'email':
                    return Validators.email;
                case 'phonenumber':
                    return Validators.pattern(CommonRegex.PHONE_REGEX_LENGTH);
                case 'max':
                    return Validators.max(v.max || 0);
                case 'min':
                    return Validators.min(v.min || 0);
                case 'maxlength':
                    return Validators.maxLength(v.maxLength || 0);
                case 'minlength':
                    return Validators.minLength(v.minLength || 0);
                case 'pattern':
                    return Validators.pattern(v.regex ? `${v.regex}` : '');
                case 'required':
                    return Validators.required;
                default:
                    return () => null;
            }
        });
    }

    ngOnInit(): void {}

    markFormPristine = () => {
        Object.keys(this.formGroup.controls).forEach((control) => {
            this.formGroup.controls[control].markAsPristine();
        });
    };

    onDropdownSearch(e: any, key: string) {
        if (this.options) this.options[key] = e;
    }

    onInputActionClick(e: ControlAction) {
        this.inputActionClick.emit(e);
    }
}
