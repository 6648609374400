import { concatSlash } from '../../../core/helper/text';
import { FormConfig } from '../../../core/interfaces/form-config/basic-form';
import { CommonListConfig } from '../page-configs.helper';

const ROOT = '../../../../assets/json/count-assignment';
export const COUNT_ASSIGNMENT_SEARCH_FORM = concatSlash([
    ROOT,
    'count-assignment-search-form.json'
]);
export const COUNT_ASSIGNMENT_COMPARE_FORM = concatSlash([
    ROOT,
    'count-assignment-compare-form.json'
]);
export const COUNT_ASSIGNMENT_CLEAR_FORM = concatSlash([ROOT, 'count-assignment-clear-form.json']);
export const COUNT_ASSIGNMENT_TABLE = concatSlash([ROOT, 'count-assignment-table.json']);
export const COMPARE_SEARCH_FORM = concatSlash([ROOT, 'compare-search-form.json']);
export const COMPARE_DETAILS_FORM = concatSlash([ROOT, 'compare-details-form.json']);
export const COMPARE_TABLE = concatSlash([ROOT, 'compare-table.json']);

export const ConfigFeatureKey = 'countAssignmentConfig';

export interface ConfigState {
    listPage?: CountAssignmentListConfig;
    clearForm?: FormConfig;
    comparePage?: CommonListConfig;
    detailsForm?: FormConfig;
}

export interface CountAssignmentListConfig extends CommonListConfig {
    compareFormConfig?: FormConfig;
}
