import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigFeatureKey, ConfigState } from './dashboard-config';

const DashboardConfigState =
    createFeatureSelector<ConfigState>(ConfigFeatureKey);

export class DashboardSelector {
    /**
     *
     * Local dashboards
     */
    static readonly localDashboardConfig = createSelector(
        DashboardConfigState,
        (state) => state.localDashboardConfig
    );
    static readonly countAssignmentConfig = createSelector(
        DashboardConfigState,
        (state) => state.countAssignmentConfig
    );
    static readonly countingStatusPerDocConfig = createSelector(
        DashboardConfigState,
        (state) => state.countingStatusPerDocConfig
    );
    static readonly principalCountResultsDashboardConfig = createSelector(
        DashboardConfigState,
        (state) => state.principalCountResultsDashboardConfig
    );

    /**
     *
     * Regional dashboards
     */
    static readonly regionalDashboardConfig = createSelector(
        DashboardConfigState,
        (state) => state.regionalDashboardConfig
    );
    static readonly locationAccuracyDashboardConfig = createSelector(
        DashboardConfigState,
        (state) => state.locationAccuracyRateConfig
    );
    static readonly itemAccuracyRateConfig = createSelector(
        DashboardConfigState,
        (state) => state.itemAccuracyRateConfig
    );
    static readonly stockAccuracyNetConfig = createSelector(
        DashboardConfigState,
        (state) => state.stockAccuracyNetConfig
    );
    static readonly stockAccuracyGrossConfig = createSelector(
        DashboardConfigState,
        (state) => state.stockAccuracyGrossConfig
    );
    static readonly stockCountingFrequencyConfig = createSelector(
        DashboardConfigState,
        (state) => state.stockCountingFrequencyConfig
    );
    static readonly marketPerformanceConfig = createSelector(
        DashboardConfigState,
        (state) => state.marketPerformanceConfig
    );
}
