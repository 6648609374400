import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, of, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CoreHttpService } from '../../../core/services/common/json.service';
import { getFormConfig, getTableConfig } from '../page-configs.helper';
import {
    DETAIL_FORM,
    DETAIL_TABLE,
    MATERIAL_TABLE,
    PRODUCT_TABLE,
    SEARCH_FORM,
    STORAGE_BIN_TABLE
} from './action-history-config';
import { ActionHistoryActions } from './action-history-config.action';
import { ActionHistorySelector } from './action-history-config.selector';

@Injectable()
export class ActionHistoryEffects {
    constructor(private actions$: Actions, private _http: CoreHttpService, private store: Store) {
    }

    searchFormConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ActionHistoryActions.loadSearchForm),
            withLatestFrom(this.store.select(ActionHistorySelector.searchFormConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return getFormConfig(SEARCH_FORM, this._http).pipe(
                    map((searchFormConfig) =>
                        ActionHistoryActions.loadSearchFormSuccess({
                            data: searchFormConfig
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    materialTableConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ActionHistoryActions.loadMaterialTable),
            withLatestFrom(this.store.select(ActionHistorySelector.materialTableConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return getTableConfig(MATERIAL_TABLE, this._http).pipe(
                    map((table) =>
                        ActionHistoryActions.loadMaterialTableSuccess({
                            data: table
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    storageBinTableConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ActionHistoryActions.loadStorageTable),
            withLatestFrom(this.store.select(ActionHistorySelector.storageBinTableConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return getTableConfig(STORAGE_BIN_TABLE, this._http).pipe(
                    map((table) =>
                        ActionHistoryActions.loadStorageTableSuccess({
                            data: table
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    productTableConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ActionHistoryActions.loadProductTable),
            withLatestFrom(this.store.select(ActionHistorySelector.productTableConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return getTableConfig(PRODUCT_TABLE, this._http).pipe(
                    map((table) =>
                        ActionHistoryActions.loadProductTableSuccess({
                            data: table
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    // Get action history detail config
    actionHistoryDetailConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ActionHistoryActions.loadActionHistoryDetailConfigs),
            withLatestFrom(this.store.select(ActionHistorySelector.actionHistoryDetail)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([getFormConfig(DETAIL_FORM, this._http), getTableConfig(DETAIL_TABLE, this._http)]).pipe(
                    map(([form, table]) => ActionHistoryActions.loadActionHistoryDetailSuccess({
                        data: {
                            searchConfig: form,
                            tableConfig: table
                        }
                    })),
                    catchError(() => EMPTY)
                );
            })
        );
    });
}
