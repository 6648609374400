import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConfigFeatureKey } from './external-config';
import { ExternalEffects } from './external-config.effects';
import { reducer } from './external-config.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(ConfigFeatureKey, reducer),
        EffectsModule.forFeature([ExternalEffects])
    ]
})
export class ExternalConfigModule {}
