import { createReducer, on } from '@ngrx/store';
import { ConfigState } from './audit-trail-config';
import { AuditTrailActions } from './audit-trail-config.actions';

export const reducer = createReducer(
    {} as ConfigState,

    // System Configuration - Country
    on(AuditTrailActions.loadAuditTrailSuccess, (state, { data }) => ({
        ...state,
        tableConfigs: data
    }))
);
