import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, of, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CoreHttpService } from '../../../core/services/common/json.service';
import { getFormConfig, getTableConfig } from '../page-configs.helper';
import { BIN_CONFIGURATION_SEARCH_FORM, BIN_CONFIGURATION_TABLE } from './bin-configuration-config';
import { BinConfigurationActions } from './bin-configuration-config.action';
import { BinConfigurationSelector } from './bin-configuration-config.selector';

@Injectable()
export class BinConfigurationEffects {
    constructor(private actions$: Actions, private _http: CoreHttpService, private store: Store) {}

    // Get Account List config
    binConfigurationListConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BinConfigurationActions.loadListConfigs),
            withLatestFrom(this.store.select(BinConfigurationSelector.listConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(BIN_CONFIGURATION_SEARCH_FORM, this._http),
                    getTableConfig(BIN_CONFIGURATION_TABLE, this._http),
                ]).pipe(
                    map(([searchConfig, tableConfig]) =>
                        BinConfigurationActions.loadListSuccess({
                            data: { searchConfig, tableConfig },
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });
}
