import { Pipe, PipeTransform } from '@angular/core';
import { DropdownConfig } from '../../../../interfaces/form-config';

@Pipe({
    name: 'optionLabel'
})
export class OptionLabelPipe implements PipeTransform {
    transform(options: any[], value: any, config?: DropdownConfig): any {
        if (!config) return;

        const { optionValue, optionLabel } = config;

        if (optionValue && optionLabel) {
            return options.find((e) => e[optionValue] === value)?.[optionLabel];
        }
    }
}
