import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { applyFuncByMode, classObjToArray } from '../../../helper/form';
import {
    Control,
    FormConfig,
    FormMode,
} from '../../../interfaces/form-config/basic-form';

@Pipe({
    name: 'formClass',
})
export class FormClassPipe implements PipeTransform {
    transform(config: FormConfig, mode: FormMode): any {
        const _config = config?.layoutConfig;

        // Get gap control class
        const controlGap = applyFuncByMode(mode, {
            edit: () => [
                // set space x beetween controls
                _config?.controlGapX
                    ? `gap-x-${_config?.controlGapX || 4}`
                    : '',

                // set space y beetween controls
                _config?.controlGapY
                    ? `gap-y-${_config?.controlGapY || 4}`
                    : '',
            ],
            detail: () => [
                // set space x beetween controls for detail
                _config?.detailControlGapX
                    ? `gap-x-${_config?.detailControlGapX || 4}`
                    : '',

                // set space y beetween controls for detail
                _config?.detailControlGapY
                    ? `gap-y-${_config?.detailControlGapY || 4}`
                    : '',
            ],
            // create: () => {}
        });

        const formWidth = applyFuncByMode(mode, {
            edit: () => [_config?.formWidth === 'half' ? 'w-1/2' : 'w-full'],
            detail: () => [
                _config?.detailFormWidth === 'half' ? 'w-1/2' : 'w-full',
            ],
            // create: () => {}
        });

        return [
            mode,
            // set number of control will show on row
            _config?.controlPerRow
                ? `grid grid-cols-${_config?.controlPerRow}`
                : 'grid grid-cols-1',

            // set gap of control
            ...controlGap,

            // set form container width
            ...formWidth,

            // set custom class,
            _config?.customClass,
        ].join(' ');
    }
}

@Pipe({
    name: 'formContainerClass',
})
export class FormContainerClassPipe implements PipeTransform {
    transform(
        config: FormConfig,
        mode: FormMode,
        type: string,
        styleClass?: string
    ): any {
        const _config = config?.layoutConfig;

        const containerGap = applyFuncByMode(mode, {
            edit: () => [
                _config?.containerGapX ? `gap-x-${_config?.containerGapX}` : '',
            ],
            detail: () => [
                _config?.detailContainerGapX
                    ? `gap-x-${_config?.detailContainerGapX}`
                    : '',
            ],
            // create: () => {}
        });

        return [
            // set number of control will show on row
            styleClass,
            _config?.labelPosition === 'left' ? 'grid grid-cols-6' : '',
            type === 'empty' ? 'empty' : '',
            ...containerGap,
        ].join(' ');
    }
}

@Pipe({
    name: 'formLabelClass',
})
export class FormLabelClassPipe implements PipeTransform {
    transform(config: FormConfig, mode: FormMode): any {
        const _config = config?.layoutConfig;

        const labelWidth = applyFuncByMode(mode, {
            // create: () => {},
            edit: () => [
                classObjToArray({
                    'col-span-1': _config?.labelWidth === 'one-third',
                    'col-span-2':
                        !_config?.labelWidth || _config?.labelWidth === 'half',
                    'col-span-3': _config?.labelWidth === 'full',
                }),
            ],
            detail: () => [
                classObjToArray({
                    'col-span-1': _config?.detailLabelWidth === 'one-third',
                    'col-span-2':
                        !_config?.labelWidth ||
                        _config?.detailLabelWidth === 'half',
                    'col-span-3': _config?.detailLabelWidth === 'full',
                }),
            ],
        });

        return [
            // set class position for labe
            _config?.labelPosition || 'left',

            // set class text align
            `text-${_config?.labelAlign || 'right'}`,

            // set class position for label width
            ...labelWidth,
        ].join(' ');
    }
}

@Pipe({
    name: 'formControlClass',
})
export class FormControlClassPipe implements PipeTransform {
    transform(config: FormConfig, styleClass?: string | null): any {
        const _config = config?.layoutConfig;

        return [
            _config?.labelWidth === 'half' ? 'col-span-3' : 'col-span-4',
            // styleClass || '',
        ].join(' ');
    }
}

@Pipe({
    name: 'hiddenControl',
})
export class HiddenControlClassPipe implements PipeTransform {
    transform(controlConfig: Control, mode: FormMode, disabled?: boolean): any {
        const { hideOnDetail, hideOnDisable } = controlConfig;

        return (
            (mode === 'detail' && hideOnDetail) || (disabled && hideOnDisable)
        );
    }
}
