import { createReducer, on } from '@ngrx/store';
import { ConfigState } from './bin-configuration-config';
import { BinConfigurationActions } from './bin-configuration-config.action';

export const reducer = createReducer(
    {} as ConfigState,
    on(BinConfigurationActions.loadListSuccess, (state, { data }) => ({
        ...state,
        binConfigurationList: data,
    }))
);
