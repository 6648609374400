import { Pipe, PipeTransform } from '@angular/core';
import { filter, join, map, some } from 'lodash';
import { MultiSelectConfig } from '../../../../interfaces/form-config';

@Pipe({
    standalone: true,
    name: 'multiSelectValueLabel',
})
export class MultiSelectValueLabelPipe implements PipeTransform {
    // Maps and returns selected values with matching option labels.
    transform(value: any, config?: MultiSelectConfig, option?: any[]): any {
        if (!config) return;
        const { optionValue, optionLabel } = config;
        const _option = option || config.options;

        if (optionValue && optionLabel) {
            return join(
                map(
                    filter(_option, (e) =>
                        some(value, (_e) => e[optionValue] === _e)
                    ),
                    (el) => el[optionLabel]
                ),
                ', '
            );
        }
    }
}

@Pipe({
    standalone: true,
    name: 'multiSelectOptionLabel',
})
export class MultiSelectOptionLabelPipe implements PipeTransform {
    transform(item: any, config?: MultiSelectConfig): any {
        return item[config?.optionLabel || 'label'];
    }
}
