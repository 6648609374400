import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ConfigFeatureKey } from "./market-config";
import { MarketEffects } from "./market-config.effects";
import { reducer } from "./market-config.reducer";

@NgModule({
    imports: [StoreModule.forFeature(ConfigFeatureKey, reducer), EffectsModule.forFeature([MarketEffects])]
})
export class MarketConfigModule { }