import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ChipsModule } from 'primeng/chips';
import { ControlBaseComponent } from '../../../../base/components/control.component';
import { ChipsConfig } from '../../../../interfaces/form-config/input-chips';
import { TextModule } from '../text/text.module';

@Component({
    standalone: true,
    selector: 'app-chips',
    template: `
        <ng-container
            *ngIf="_config?.readonly || mode === FormMode.Detail; else input">
            <app-text
                [config]="config"
                [control]="control">
            </app-text>
        </ng-container>

        <ng-template #input>
            <div
                #wrapper
                class="input-wrapper">
                <p-chips
                    [(ngModel)]="values"
                    [ngClass]="{
                        'font-bold': _config?.fontBold,
                        'border-none': _config?.noBorder,
                        shadow: _config?.shadow
                    }"
                    [formControl]="control"
                    [showClear]="_config?.showClear || false"
                    [placeholder]="_config?.placeholder || '' | translate"
                    [separator]="_config?.separator || separator"
                    [addOnBlur]="_config?.addOnBlur || true"
                    [addOnTab]="_config?.addOnTab || true"
                    (onAdd)="onAdd($event)">
                </p-chips>
            </div>
        </ng-template>
    `,
    styleUrls: ['./chips.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        ChipsModule,
        TextModule,
    ],
})
export class ChipsComponent
    extends ControlBaseComponent<ChipsConfig>
    implements AfterViewInit
{
    @ViewChild('wrapper') wrapper?: ElementRef;

    values?: string[];
    separator: any = /([\r\n |,])+/; // PrimeNG14 Chips module's [separator] works with RegExp but is typed to string only for some reason.

    constructor() {
        super();
    }

    ngAfterViewInit(): void {
        if (!this.wrapper) return;
        const wrapper: HTMLDivElement = this.wrapper.nativeElement;
        const field = wrapper.querySelector('.p-chips');
        const chips = wrapper.querySelectorAll('li');
        const clearButton = chips[chips.length - 1];
        field?.setAttribute('tabindex', '-1');
        field?.appendChild(clearButton);
    }

    /**
     * Custom logic to handle separating values into chips by custom separators.
     * The default [separator] in PrimeNG 14 Chips module cannot handle pasting data from Excel columns.
     */
    onAdd(e: any) {
        if (!this.values || !this.values.length) return;

        this.values?.pop();

        // Splits and trims the initial input into an array of strings (by whitespaces and line breaks by default).
        const split = e.value.split(this._config?.separator || this.separator);
        const trimmed = split.map((val: string) => val.trim());

        // Filters out empty & invalid strings
        const filtered = trimmed.filter((el: string) => Boolean(el));
        this.values.push(...filtered);
    }
}
