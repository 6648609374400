import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { isNull, isUndefined } from 'lodash';
import { FormMode } from '../../constant/form';
import { ControlConfig, FormMode as IFormMode } from '../../interfaces/form-config/basic-form';

@Component({
    template: ``
})
export class ControlBaseComponent<T> implements OnChanges {
    @Input() mode: IFormMode = 'detail';

    @Input() extra?: any;

    @Input() control!: FormControl;

    @Input() key!: string;

    @Input() config?: ControlConfig;

    @Input() value: any;

    FormMode = FormMode;

    _config?: T;

    ngOnChanges(changes: SimpleChanges): void {
        const config = changes['config']?.currentValue;
        const value = changes['value']?.currentValue;

        // To detect interface for every control component
        if (config) {
            this._config = config;
        }

        // Set value for control
        if (!isNull(value) && !isUndefined(value)) {
            this.control.patchValue(value);
        }

        this.onChanges(changes);
    }

    onChanges(changes: SimpleChanges) {}
}
