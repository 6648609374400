import { concatSlash } from "../../../core/helper/text";
import { FormConfig } from "../../../core/interfaces/form-config/basic-form";
import { CommonListConfig } from "../page-configs.helper";

const rootPath = '../../../../assets/json/material-master';
export const MATERIAL_TABLE = concatSlash([rootPath, 'material-master-table.json']);
export const MATERIAL_SEARCH_FORM = concatSlash([rootPath, 'material-master-search-form.json']);
export const MATERIAL_SYNC_FORM = concatSlash([rootPath, 'material-master-sync-form.json']);

export const ConfigFeatureKey = 'materialMasterConfig';

export interface ConfigState {
    materialList?: CommonListConfig;
    syncForm?: FormConfig;
}