import { ValueOf } from '../../shared/utils/common';

export const FUNCTION_CODE = {
    CALENDAR: 'F1',
    DASHBOARD: 'F2',
    SAP_MATERIAL_MASTER: 'F3',
    SAP_BIN_MASTER: 'F4',
    SAP_STOCK_ON_HAND: 'F5',
    SAP_INVENTORY_DOC: 'F6',
    APLI_SAP_INVENTORY_DOC: 'F7',
    STOCK_COUNTING: 'F8',
    STOCK_COUNT_REVIEW: 'F9',
    STOCK_COUNT_PLAN: 'F10',
    TEAM_ASSIGNMENT: 'F11',
    COUNT_ASSIGNMENT: 'F12',
    HELP_DESK_MESSAGES: 'F13',
    STOCK_VARIANCE_REPORT: 'F14',
    AUDIT_TRAIL_LOGS: 'F15',
    MARKET_MANAGEMENT: 'F16',
    ACCOUNT_MANAGEMENT: 'F17',
    BIN_CONFIGURATION: 'F18',
} as const;

export const PERMISSION_CODE = {
    CREATE: 'C',
    READ: 'R',
    UPDATE: 'U',
    DELETE: 'D',
    IMPORT: 'I',
    EXPORT: 'E',
    ACTIVATE: 'A', // Activate records in data tables
    DEACTIVATE: 'W', // Deactivate records in data tables
    SAP_TRIGGER: 'S',
    AUTO_COUNT: 'H',
    COPY_BI: 'J',
    APPROVE: 'P',
    VERIFY: 'V',
    RECOUNT: 'T',
    REVERT: 'M',
    MASS_VERIFY: 'B',
    MASS_RECOUNT: 'L',
    MASS_REVERT: 'N',
    MASS_COPY_BI: 'F',
    ASSIGN_USER: 'Q',
    REPORT: 'Y', // Generate Variance Report
    COMPARE: 'O', // Compare Documents in Count Assignment
    UPDATE_PRODUCT: 'G', // Update Document in Count Assignment
    BARCODE_SCAN: 'X',
    AUDITOR: 'Z',
} as const;

export type FunctionCode = ValueOf<typeof FUNCTION_CODE>;

export type PermissionCode = ValueOf<typeof PERMISSION_CODE>;
