import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { debounceTime, fromEvent, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-toast',
    standalone: true,
    template: `
        <!-- Default toast channel, always defaults to this on mobile displays -->
        <p-toast
            [position]="isPhone ? 'top-center' : 'bottom-right'"
            [showTransformOptions]="isPhone ? 'translateY(-100%)' : 'translateX(25%)'"
            [preventOpenDuplicates]="true"
        ></p-toast>

        <!-- Notification center toast channel -->
        <p-toast
            [key]="'notification'"
            [position]="'top-right'"
            [showTransformOptions]="'translateX(25%)'"
            [preventOpenDuplicates]="true"
        ></p-toast>
    `,
    styleUrls: ['./toast.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, ToastModule]
})
export class ToastComponent implements OnDestroy {
    destroy$ = new Subject<void>();
    isPhone: boolean = window.innerWidth < 480;

    constructor() {
        fromEvent(window, 'resize')
            .pipe(takeUntil(this.destroy$), debounceTime(300))
            .subscribe(() => (this.isPhone = window.innerWidth < 480));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
}
