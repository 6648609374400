import { createReducer, on } from '@ngrx/store';
import { DocumentInfoActions } from "./document-info-config.actions";
import { ConfigState } from "./document-info-config";

export const reducer = createReducer(
    {} as ConfigState,

    // System Configuration - Country
    on(DocumentInfoActions.loadDocumentInfoSuccess, (state, { data }) => ({
        ...state,
        documentInfo: data
    }))
);
