import { createAction, props } from '@ngrx/store';
import { TableColumn } from '../../../core/interfaces/dynamic-table';

export class AuditTrailActions {
    static readonly loadAuditTrail = createAction('[AuditTrail] Loading');

    static readonly loadAuditTrailSuccess = createAction(
        '[AuditTrail] Success',
        props<{ data: TableColumn[] }>()
    );

    static readonly loadAuditTrailFailure = createAction(
        '[AuditTrail] Failure',
        props<{ error: any }>()
    );
}
