import { createAction, props } from '@ngrx/store';
import { FormConfig } from '../../../core/interfaces/form-config/basic-form';
import { CommonListConfig } from '../page-configs.helper';
import { CountAssignmentListConfig } from './count-assignment-config';

export class CountAssignmentActions {
    /**
     *
     * Count Assignment List
     */
    static readonly loadListPageConfigs = createAction(
        '[Count Assignment List Page Config] Loading'
    );
    static readonly loadListPageSuccess = createAction(
        '[Count Assignment List Page Config] Success',
        props<{ data: CountAssignmentListConfig }>()
    );
    /**
     *
     * Count Assignment List - Clear Dialog
     */
    static readonly loadClearFormConfigs = createAction(
        '[Count Assignment List - Clear Form Config] Loading'
    );
    static readonly loadClearFormSuccess = createAction(
        '[Count Assignment List - Clear Form Config] Success',
        props<{ data: FormConfig }>()
    );
    /**
     *
     * Count Assignment Compare
     */
    static readonly loadComparePageConfigs = createAction(
        '[Count Assignment Compare Page Config] Loading'
    );
    static readonly loadComparePageSuccess = createAction(
        '[Count Assignment Compare Page Config] Success',
        props<{ data: CommonListConfig }>()
    );
    /**
     *
     * Count Assignment Compare - Details Sidebar
     */
    static readonly loadDetailsFormConfigs = createAction(
        '[Count Assignment Compare - Details Form Config] Loading'
    );
    static readonly loadDetailsFormSuccess = createAction(
        '[Count Assignment Compare - Details Form Config] Success',
        props<{ data: FormConfig }>()
    );
}
