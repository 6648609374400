import { createReducer, on } from '@ngrx/store';
import { ConfigState } from './permission-config';
import { RoleActions } from './permission-config.actions';

export const reducer = createReducer(
    {} as ConfigState,

    // Permission
    on(RoleActions.loadRoleListSuccess, (state, action) => ({
        ...state,
        roleList: action.data
    })),

    on(RoleActions.loadRolePermissionsSuccess, (state, action) => ({
        ...state,
        rolePermissions: action.data
    }))
);
