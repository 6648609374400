import { createAction, props } from "@ngrx/store";
import { FormConfig } from "../../../core/interfaces/form-config/basic-form";
import { CommonListConfig } from "../page-configs.helper";

export class StockCountReviewActions {
    // Stock Counting Review Configuration Actions
    static readonly loadStockCountReviewConfigs = createAction('[Stock Count Review] Loading');
    static readonly loadStockCountReviewSuccess = createAction('[Stock Count Review] Success', props<{ data: CommonListConfig }>());
    static readonly loadStockCountReviewError = createAction('[Stock Count Review] Error', props<{ error: any }>());

    static readonly loadStockCountReviewOverviewConfigs = createAction('[Stock Count Review Overview] Loading');
    static readonly loadStockCountReviewOverviewSuccess = createAction('[Stock Count Review Overview] Success', props<{ data: FormConfig }>());
    static readonly loadStockCountReviewOverviewError = createAction('[Stock Count Review Overview] Error', props<{ error: any }>());

    static readonly loadStockCountReviewDetailConfigs = createAction('[Stock Count Review Detail] Loading');
    static readonly loadStockCountReviewDetailSuccess = createAction('[Stock Count Review Detail] Success', props<{ data: FormConfig }>());
    static readonly loadStockCountReviewDetailError = createAction('[Stock Count Review Detail] Error', props<{ error: any }>());

    static readonly loadStockCountReviewAssignConfigs = createAction('[Stock Count Review Assign Config] Loading');
    static readonly loadStockCountReviewAssignConfigsSuccess = createAction('[Stock Count Review Assign Config] Success', props<{ data: FormConfig }>());
    static readonly loadStockCountReviewAssignConfigsError = createAction('[Stock Count Review Assign Config] Error', props<{ error: any }>());
}