import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigFeatureKey, ConfigState } from './count-assignment-config';

const PageConfigState = createFeatureSelector<ConfigState>(ConfigFeatureKey);

export class CountAssignmentSelector {
    static readonly listPageConfig = createSelector(PageConfigState, (state) => state.listPage);
    static readonly clearFormConfig = createSelector(PageConfigState, (state) => state.clearForm);
    static readonly comparePageConfig = createSelector(
        PageConfigState,
        (state) => state.comparePage
    );
    static readonly detailsFormConfig = createSelector(
        PageConfigState,
        (state) => state.detailsForm
    );
}
