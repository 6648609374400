import { concatSlash } from '../../../core/helper/text';
import { TableColumn } from '../../../core/interfaces/dynamic-table';
import { FormConfig } from '../../../core/interfaces/form-config/basic-form';
import { CommonListConfig } from '../page-configs.helper';

const ROOT_PATH = '../../../../assets/json/account';
export const ACCOUNT_TABLE = concatSlash([ROOT_PATH, 'account-table.json']);
export const ACCOUNT_SEARCH_FORM = concatSlash([ROOT_PATH, 'account-search-form.json']);
export const ACCOUNT_CRUD_FORM = concatSlash([ROOT_PATH, 'account-form.json']);
export const ACCOUNT_ROLE_TABLE = concatSlash([ROOT_PATH, 'account-role-table.json']);
export const ACCOUNT_ROLE_ASSIGN_FORM = concatSlash([ROOT_PATH, 'account-role-assign-form.json']);
export const ACCOUNT_ROLE_DETAILS_FORM = concatSlash([ROOT_PATH, 'account-role-details-form.json']);

export const ConfigFeatureKey = 'accountConfig';

export interface ConfigState {
    accountList?: CommonListConfig;
    accountInfo?: AccountInfoConfig;
    roleSidebar?: FormConfig;
}

export interface AccountInfoConfig {
    formConfig?: FormConfig;
    roleFormConfig?: FormConfig;
    roleTableConfig?: TableColumn[];
}
