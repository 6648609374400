import { createAction, props } from "@ngrx/store";
import { FormConfig } from "../../../core/interfaces/form-config/basic-form";
import { CommonListConfig } from "../page-configs.helper";

export class MarketConfigActions{
    // Load market list config
    static readonly loadMarketConfigs = createAction('[Market] Loading');
    static readonly loadMarketSuccess = createAction('[Market] Success', props<{ data: CommonListConfig }>());
    static readonly loadMarketError = createAction('[Market] Error', props<{ error:any }>());
    // Load warehouse config
    static readonly loadWarehouseConfigs = createAction('[Warehouse] Loading');
    static readonly loadWarehouseSuccess = createAction('[Warehouse] Success', props<{ data: CommonListConfig }>());
    static readonly loadWarehouseError = createAction('[Warehouse] Error', props<{ error:any }>());

    // Load market info list config
    static readonly loadMarketInfoConfigs = createAction('[Market Info] Loading');
    static readonly loadMarketInfoSuccess = createAction('[Market Info] Success', props<{ data: FormConfig }>());
    static readonly loadMarketInfoError = createAction('[Market Info] Error', props<{ error:any }>());
}