import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, exhaustMap, map, of, withLatestFrom } from 'rxjs';
import { CoreHttpService } from '../../../core/services/common/json.service';
import { getTableConfig } from '../page-configs.helper';
import { AUDIT_PATH } from './audit-trail-config';
import { AuditTrailActions } from './audit-trail-config.actions';
import { AuditTrailSelectors } from './audit-trail-config.selectors';

@Injectable()
export class AuditTrailEffects {
    tableConfigs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditTrailActions.loadAuditTrail),
            withLatestFrom(this.store.select(AuditTrailSelectors.tableConfigs)),
            exhaustMap(([, state]) => {
                if (state) return of();
                return getTableConfig(AUDIT_PATH, this._http).pipe(
                    map((data) => AuditTrailActions.loadAuditTrailSuccess({ data })),
                    catchError((err) => EMPTY)
                );
            })
        );
    });

    constructor(private actions$: Actions, private _http: CoreHttpService, private store: Store) {}
}
