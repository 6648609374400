import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ControlBaseComponent } from '@app/core/base/components/control.component';
import { FormOption } from '@app/core/interfaces/form-config/basic-form';
import { SelectButtonConfig } from '@app/core/interfaces/form-config/select-button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { LazyLoadTranslateModule } from '../../../../modules/lazy-load-translate.module';

@Component({
    selector: 'app-select-button',
    standalone: true,
    template: `
        <p-selectButton
            [formControl]="control"
            [multiple]="_config?.multiple || false"
            [options]="options || _config?.options || []"
            [optionLabel]="_config?.optionLabel || 'label'"
            [optionValue]="_config?.optionValue || 'value'"
            [styleClass]="_config?.styleClass || ''"
            [disabled]="mode === FormMode.Detail">
            <ng-template let-item>
                <span
                    class="p-button-label"
                    [innerText]="item.label | translate"></span>
            </ng-template>
        </p-selectButton>
    `,
    styleUrls: ['./select-button.component.scss'],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        LazyLoadTranslateModule,
        SelectButtonModule,
    ],
})
export class SelectButtonComponent extends ControlBaseComponent<SelectButtonConfig> {
    @Input() options?: FormOption[];
}
