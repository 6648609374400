import { reduce } from 'lodash';
import { PageError } from '../interfaces/page-error';
import { FileItem, UploaderContext } from '../models/upload';
import { HTTP_STATUS_CODE } from './page-error';

export const COMMON_CONSTANT = {
    AUTO_CLOSE_SUCCESS_MSG_TIMER: 3000, // 3s
};

export class CommonFunction {
    static autoGenerateGuidId() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    static createHttpCode(errorCode: string): PageError {
        const code = HTTP_STATUS_CODE[errorCode] as PageError;
        const res: PageError = {
            code: code.code,
            content: code.content,
            header: code.header,
            message: code.message,
            status: code.status
        };
        return res;
    }

    static GenerateId(): string {
        let id = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 10; i++) {
            id += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        const date = new Date();

        return id + date.getTime().toString();
    }

    // get icon by file extension
    static getFileIcon(url: string, fileExtension: any) {
        let iconType = 'pdf';
        switch (fileExtension) {
            case 'csv':
                iconType = 'csv';
                break;
            case 'gif':
                iconType = 'gif';
                break;
            case 'ppt':
            case 'pptx':
                iconType = 'ppt';
                break;
            case 'pst':
            case 'ost':
                iconType = 'pst';
                break;
            case '7z':
            case 'rar':
            case 'gz':
            case 'z':
            case 'zip':
                iconType = 'zip';
                break;
            case 'pdf':
                iconType = 'pdf';
                break;
            case 'xls':
            case 'xlsx':
                iconType = 'excel';
                break;
            case 'doc':
            case 'docx':
                iconType = 'word';
                break;
            case 'jpg':
            case 'JPG':
            case 'jpeg':
            case 'JPEG':
            case 'png':
            case 'PNG':
            case 'tif':
            case 'tiff':
                iconType = 'img';
                break;
            case 'txt':
                iconType = 'txt';
                break;
            default:
                break;
        }
        return url + 'icon_' + iconType + '-file.svg';
    }

    static buildFileSelection(returnedFiles: Array<any>, uploaderContext: UploaderContext) {
        if (returnedFiles && returnedFiles.length > 0) {
            returnedFiles.forEach((file) => this.createUploaderFile(file, uploaderContext));
        }
    }

    private static createUploaderFile(file: any, uploaderContext: UploaderContext) {
        const fileItem = new FileItem();
        fileItem.fileItem = new Blob(['']);
        fileItem.fileItem.name = file.OriginalFileName + '.' + file.FileExtension;
        fileItem.id = file.Id;
        fileItem.originalName = file.OriginalFileName;
        fileItem.ext = file.FileExtension;
        fileItem.version = file.version;
        fileItem.isAllowDelete = !file.IsAllowDelete ? fileItem.isAllowDelete : file.IsAllowDelete;
        fileItem.uploadedBy = file.UploadedBy;
        fileItem.uploadedDate = file.UploadedDate;
        uploaderContext.uploadList.push(fileItem);

        return fileItem;
    }

    static numberToBoolan(value: number): any {
        switch (value) {
            case 1:
                return true;
            case 2:
                return false;
            default:
                return null;
        }
    }

    static boolToNumber(value?: boolean) {
        switch (value) {
            case true:
                return 1;
            case false:
                return 2;
            default:
                return null;
        }
    }
    static removeUnusedParams = (obj: any) => {
        return reduce(
            obj,
            (pre, cur, key) => (cur ? { ...pre, [key]: cur } : pre),
            {}
        );
    }
}
