import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
    private loadingSource = new BehaviorSubject<boolean>(true);

    get loading$() {
        return this.loadingSource.asObservable();
    }

    show = () => this.loadingSource.next(true);

    hide = () => this.loadingSource.next(false);
}
