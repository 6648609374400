import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConfigFeatureKey } from './permission-config';
import { RoleEffects } from './permission-config.effects';
import { reducer } from './permission-config.reducer';

@NgModule({
    imports: [StoreModule.forFeature(ConfigFeatureKey, reducer), EffectsModule.forFeature([RoleEffects])]
})
export class PermissionConfigModule {}
