import { filter, isEqual, join, toLower, toString } from 'lodash';

// trim field with check undefined
export function trimField(value: string) {
    return value ? String(value).trim() : value;
}

// trim all properties in object
export function trimObj(
    obj: any,
    exceptionList: Array<string> = [],
    exceptionTrimEmptyList: Array<string> = []
) {
    Object.keys(obj).forEach((element) => {
        if (
            (obj[element] === '' ||
                obj[element] === undefined ||
                obj[element] === null ||
                (Array.isArray(obj[element]) && obj[element].length === 0)) &&
            !exceptionTrimEmptyList.some((x) => x === element)
        ) {
            delete obj[element];
        }
        if (typeof obj[element] === 'string' && trimField(obj[element]) === '') {
            delete obj[element];
        }
        if (typeof obj[element] === 'string' && !exceptionList.some((x) => x === element)) {
            // type of field is string and not exist in exception list
            obj[element] = trimField(obj[element]);
        }
    });
}

// trim all properties in object
export function trimSearchObj(obj: any, exceptionList: Array<string> = []) {
    Object.keys(obj).forEach((element) => {
        if (typeof obj[element] === 'string' && !exceptionList.some((x) => x === element)) {
            // type of field is string and not exist in exception list
            obj[element] = trimField(obj[element]);
        }
    });
}

export const concatStrings = (data: any[], separator: string) => {
    const string = join(
        filter(data, (e) => e),
        separator
    );

    return string ?? null;
};

export const concatSlash = (data: any[]) => {
    const string = join(
        filter(data, (e) => e),
        '/'
    );

    return string ?? null;
};

export const concatDash = (data: any[]) => {
    const string = join(
        filter(data, (e) => e),
        ' - '
    );

    return string ?? null;
};

export const concatBar = (data: any[]) => {
    const string = join(
        filter(data, (e) => e),
        ' | '
    );

    return string ?? null;
};

export const isSameString = (word: any, target: any) => {
    const lowerWord = toLower(toString(word));
    const lowerTarget = toLower(toString(target));
    return isEqual(lowerWord, lowerTarget);
};
