import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, EMPTY, forkJoin, map, of, switchMap, withLatestFrom } from "rxjs";
import { CoreHttpService } from "../../../core/services/common/json.service";
import { getFormConfig, getTableConfig } from "../page-configs.helper";
import { MARKET_FORM, MARKET_SEARCH_FORM, MARKET_TABLE, WAREHOUSE_FORM, WAREHOUSE_TABLE } from "./market-config";
import { MarketConfigActions } from "./market-config.action";
import { MarketConfigSelector } from "./market-config.selector";

@Injectable()
export class MarketEffects {
    constructor(private actions$: Actions, private _http: CoreHttpService, private store: Store) { }
    marketConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MarketConfigActions.loadMarketConfigs),
            withLatestFrom(this.store.select(MarketConfigSelector.marketConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(MARKET_SEARCH_FORM, this._http),
                    getTableConfig(MARKET_TABLE, this._http)]
                ).pipe(
                    map(([searchFormConfig, tableConfig]) =>
                        MarketConfigActions.loadMarketSuccess({
                            data: { searchConfig: searchFormConfig, tableConfig }
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    warehouseConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MarketConfigActions.loadWarehouseConfigs),
            withLatestFrom(this.store.select(MarketConfigSelector.warehouseConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(WAREHOUSE_FORM, this._http),
                    getTableConfig(WAREHOUSE_TABLE, this._http)]
                ).pipe(
                    map(([searchFormConfig, tableConfig]) =>
                        MarketConfigActions.loadWarehouseSuccess({
                            data: { searchConfig: searchFormConfig, tableConfig }
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    marketInfoConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MarketConfigActions.loadMarketInfoConfigs),
            withLatestFrom(this.store.select(MarketConfigSelector.marketInfoConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return getFormConfig(MARKET_FORM, this._http).pipe(
                    map((searchFormConfig) =>
                        MarketConfigActions.loadMarketInfoSuccess({
                            data: searchFormConfig
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });
}