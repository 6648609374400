import { ChangeDetectionStrategy, Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { distinctUntilChanged, Observable, tap } from 'rxjs';
import { LoadingService } from './loading.service';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent implements OnInit {
    loading$?: Observable<boolean>;

    constructor(private loadingService: LoadingService, private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.loading$ = this.loadingService.loading$.pipe(
            distinctUntilChanged(),
            tap((value) => {
                if (value) {
                    this.renderer.addClass(document.body, 'overflow-hidden');
                } else {
                    this.renderer.removeClass(document.body, 'overflow-hidden');
                }
            })
        );
    }
}
