import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormPipeModule } from '../../../../shared/pipes/form.pipe';
import { CalendarComponent } from '../control-types/calendar/calendar.component';
import { CheckboxComponent } from '../control-types/checkbox/checkbox.component';
import { ChipsComponent } from '../control-types/chips/chips.component';
import { DropdownComponent } from '../control-types/dropdown/dropdown.component';
import { ErrorMessageComponent } from '../control-types/error-message/error-message.component';
import { InputNumberComponent } from '../control-types/input-number/input-number.component';
import { InputSwitchComponent } from '../control-types/input-switch/input-switch.component';
import { InputTextComponent } from '../control-types/input-text/input-text.component';
import { LabelComponent } from '../control-types/label/label.component';
import { MultiSelectComponent } from '../control-types/multi-select/multi-select.component';
import { NumberModule } from '../control-types/number/number.module';
import { RadioButtonComponent } from '../control-types/radio-button/radio-button.component';
import { SelectButtonComponent } from '../control-types/select-button/select-button.component';
import { TextAreaComponent } from '../control-types/text-area/text-area.component';
import { TitleComponent } from '../control-types/title/title.component';
import { DynamicControlComponent } from './dynamic-control.component';
import { AppendToDivDirective } from './dynamic-control.directive';
import * as Pipe from './dynamic-control.pipe';

@NgModule({
    declarations: [
        DynamicControlComponent,
        Pipe.FormClassPipe,
        Pipe.FormContainerClassPipe,
        Pipe.FormLabelClassPipe,
        Pipe.FormControlClassPipe,
        Pipe.HiddenControlClassPipe,
        AppendToDivDirective,
    ],
    imports: [
        CommonModule,
        TitleComponent,
        LabelComponent,
        ChipsComponent,
        TextAreaComponent,
        CheckboxComponent,
        CalendarComponent,
        DropdownComponent,
        InputTextComponent,
        InputNumberComponent,
        InputSwitchComponent,
        MultiSelectComponent,
        RadioButtonComponent,
        SelectButtonComponent,
        ErrorMessageComponent,
        ReactiveFormsModule,
        FormPipeModule,
        NumberModule,
    ],
    exports: [DynamicControlComponent],
})
export class DynamicControlModule {}
