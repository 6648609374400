import { createAction, props } from "@ngrx/store";
import { CommonListConfig } from "../page-configs.helper";
import { FormConfig } from "../../../core/interfaces/form-config/basic-form";

export class MaterialMasterActions {
    // Material Master List Configuration Actions
    static readonly loadMaterialListConfigs = createAction('[Material Master List] Loading');
    static readonly loadMaterialListSuccess = createAction('[Material Master List] Success', props<{ data: CommonListConfig }>());
    static readonly loadMaterialListError = createAction('[Material Master List] Error', props<{ error: any }>());

    static readonly loadMaterialSyncConfigs = createAction('[Material Master Sync] Loading');
    static readonly loadMaterialSyncSuccess = createAction('[Material Master Sync] Success', props<{ data: FormConfig }>());
    static readonly loadMaterialSyncError = createAction('[Material Master Sync] Error', props<{ error: any }>());
}