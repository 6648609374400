import { createReducer, on } from "@ngrx/store";
import { ConfigState } from "./stock-count-review-config";
import { StockCountReviewActions } from "./stock-count-review-config.action";

export const reducer = createReducer(
    {} as ConfigState,
    on(StockCountReviewActions.loadStockCountReviewSuccess, (state, {data}) => ({
        ...state,
        stockCountReviewList: data
    })),
    on(StockCountReviewActions.loadStockCountReviewAssignConfigsSuccess, (state, {data}) => ({
        ...state,
        assignConfig: data
    })),
    on(StockCountReviewActions.loadStockCountReviewOverviewSuccess, (state, {data}) => ({
        ...state,
        stockCountReviewOverview: data
    })),
    on(StockCountReviewActions.loadStockCountReviewDetailSuccess, (state, {data}) => ({
        ...state,
        stockCountReviewDetail: data
    }))
);