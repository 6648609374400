import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { IMessageButton } from '../../interfaces/message';
import { MessageItem } from '../../models/message';
import { MessageDialogService } from './message-dialog.service';

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageDialogComponent implements OnInit {
    messageItem$?: Observable<MessageItem>;
    message?: string;
    visible: boolean = false;

    constructor(private msgDialogService: MessageDialogService) {}

    ngOnInit(): void {
        this.messageItem$ = this.msgDialogService.message$.pipe(tap((res) => (this.visible = Boolean(res))));
    }

    /**
     * handler event when user click on buttons
     * @param item
     */
    async onAction(item: IMessageButton) {
        if (item.action) {
            // do action which is defined at parent component
            await Promise.resolve(item.action());
        }

        this.onHide();
    }

    /**
     * handler event when user close popup
     */
    onHide = () => this.msgDialogService.close();
}
