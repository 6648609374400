import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { isNil, omitBy, toString } from 'lodash';
import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';
import { BaseLinkAPI } from '../../constant/api';
import { CommonFunction } from '../../constant/common';
import { CustomHttpUrlEncodingCodec } from '../../helper/encoder';
import { concatSlash } from '../../helper/text';
import { AccessToken } from '../../interfaces/access-token';
import { GenericResult } from '../../interfaces/generic-result';

export abstract class HttpBaseService {
    protected httpClient: HttpClient;
    protected actionUrl!: string;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    public defaultHeaders = new HttpHeaders();

    setActionUrlWithId(url: string, id: any) {
        return url.includes('%s')
            ? sprintf(url || this.actionUrl, id)
            : concatSlash([url || this.actionUrl, id]);
    }

    /**
     * Get all object
     * @returns Return list object
     */
    getAll(): Observable<GenericResult> {
        return this.httpClient.get(this.actionUrl, {
            headers: this.defaultHeaders
        });
    }

    /**
     * Get one object by id
     * @param id This is id of object
     * @returns return one object
     */
    get(id: any, linkApi?: string): Observable<GenericResult> {
        const url = this.setActionUrlWithId(linkApi || this.actionUrl, id);
        return this.httpClient.get(url, {
            headers: this.defaultHeaders
        });
    }

    getJson<T>(path: string): Observable<T> {
        return this.httpClient.get<T>(path);
    }

    /**
     * Post object to api
     * @param obj This is a object
     * @returns return result object
     */
    post(obj: any): Observable<GenericResult> {
        return this.httpClient.post(this.actionUrl, obj, {
            headers: this.defaultHeaders
        });
    }

    /**
     * Post data with x-www-form-urlencoded
     * @param url Link to post data
     * @param obj Model to get token
     * @returns return result token
     */
    public postFormData(url: string, obj: any): Observable<AccessToken> {
        const encodedBody = Object.keys(obj)
            .map((key) => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
            })
            .join('&');

        return this.httpClient.post(url, encodedBody, {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        });
    }

    /**
     * Put object to api
     * @param obj This is a object
     * @returns return result object
     */
    put(obj: any): Observable<GenericResult> {
        return this.httpClient.put(`${this.actionUrl}`, obj, {
            headers: this.defaultHeaders
        });
    }

    /**
     * Get one object by id
     * @param id This is id of object
     * @returns return one object
     */
    putId(id: string): Observable<GenericResult> {
        this.actionUrl = this.setActionUrlWithId(this.actionUrl, id);
        return this.httpClient.put(this.actionUrl, {
            headers: this.defaultHeaders
        });
    }

    /**
     * @param  obj
     * @param  customHeaders
     * @returns Observable
     */
    patch(obj: any, headers?: HttpHeaders): Observable<GenericResult> {
        return this.httpClient.patch(toString(this.actionUrl), obj, {
            headers: headers || this.defaultHeaders
        });
    }

    /**
     * Delete object on api
     * @param obj This is a object
     * @returns return result object
     */
    delete(id: string): Observable<GenericResult> {
        this.actionUrl = this.setActionUrlWithId(this.actionUrl, id);
        return this.httpClient.delete(this.actionUrl, {
            headers: this.defaultHeaders
        });
    }

    /**
     * build search object on api
     * @param params This is a model
     * @returns return object http params
     */
    private buildParams(params: any) {
        params = CommonFunction.removeUnusedParams(params);
        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        const _params = omitBy(params, isNil);
        return queryParameters.appendAll(_params);
    }

    setActionUrl(path: string, version = BaseLinkAPI.API_V1, baseUrl = BaseLinkAPI.API_HOST) {
        this.actionUrl = baseUrl + version + path;
        return this.actionUrl;
    }

    generateUrlWithId(url: string, id: number | string) {
        return url.includes('%s')
            ? sprintf(url || this.actionUrl, id)
            : concatSlash([url || this.actionUrl, id]);
    }

    /**
     * search object on api
     * @param obj This is a object
     * @returns return result list object
     */
    search(
        url: string,
        searchCondition?: Object,
        id?: string,
        header?: HttpHeaders
    ): Observable<GenericResult> {
        this.actionUrl = this.setActionUrlWithId(url || this.actionUrl, id);
        return this.httpClient.get(this.actionUrl, {
            params: this.buildParams(searchCondition),
            headers: header || this.defaultHeaders
        });
    }

    /**
     * Options api
     * @returns return result object
     */
    options(): Observable<GenericResult> {
        return this.httpClient.options(this.actionUrl, {
            headers: this.defaultHeaders
        });
    }

    getFile(): Observable<Blob> {
        //const options = { responseType: 'blob' }; there is no use of this
        let uri = '/my/uri';
        // this.http refers to HttpClient. Note here that you cannot use the generic get<Blob> as it does not compile: instead you "choose" the appropriate API in this way.
        // return this.http.get(uri, { responseType: 'blob' });
        return this.httpClient.get(this.actionUrl, {
            headers: this.defaultHeaders,
            responseType: 'blob'
        });
    }

    exportFile(url: string, searchCondition: Object, method?: string): Observable<Blob> {
        this.actionUrl = url;
        if (method === 'POST') {
            return this.httpClient.post(this.actionUrl, searchCondition, {
                headers: this.defaultHeaders,
                responseType: 'blob'
            });
        } else {
            return this.httpClient.get(this.actionUrl, {
                params: this.buildParams(searchCondition),
                headers: this.defaultHeaders,
                responseType: 'blob'
            });
        }
    }
}
