import { map } from 'lodash';
import { FormMode } from '../constant/form';
import { FormMode as IFormMode } from '../interfaces/form-config/basic-form';
import { FormGroup, Validators } from "@angular/forms";

export const applyFuncByMode = (
    mode: IFormMode,
    func: { edit: () => any; detail: () => any }
): any | undefined => {
    // if (mode === FormMode.Create) return func.create();
    if (mode === FormMode.Detail) return func.detail();
    if (mode === FormMode.Edit || mode === FormMode.Create) return func.edit();
};

export const classObjToArray = (classList: { [key: string]: boolean }) => {
    return map(classList, (v, k) => {
        if (v) return k;
        return '';
    }).join(' ');
};

export const setRequired = (formGroup: FormGroup, key: string, condition: boolean) => {
    const control = formGroup.get(key);

    if (condition) {
        control?.addValidators(Validators.required);
    } else {
        control?.removeValidators(Validators.required);
    }

    control?.updateValueAndValidity(opts());
};
export const opts = () => ({ emitEvent: false, onlySelf: true });
