import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConfigFeatureKey } from './account-config';
import { AccountEffects } from './account-config.effects';
import { reducer } from './account-config.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(ConfigFeatureKey, reducer),
        EffectsModule.forFeature([AccountEffects])
    ]
})
export class AccountConfigModule {}
