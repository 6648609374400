import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { debounceTime, Observable } from 'rxjs';
import { IValidators } from '../../../../interfaces/form-config/basic-form';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    selector: 'app-error-message',
    template: `
        <div
            *ngIf="submit"
            [@flyInOut]>
            <p
                class="text-sm text-red-500"
                [ngClass]="{ 'mt-1': message }">
                {{ message || '' | translate }}
            </p>
        </div>
    `,
    styleUrls: ['./error-message.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('in', style({ opacity: 1, transform: 'translateY(100%)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateY(0)' }),
                animate(300),
            ]),
            transition('* => void', [
                animate(300, style({ opacity: 0, transform: 'translateY(0)' })),
            ]),
        ]),
    ],
    imports: [CommonModule, TranslateModule],
})
export class ErrorMessageComponent implements OnInit {
    @Input() submit?: boolean;
    @Input() control!: AbstractControl;
    @Input() validators?: IValidators[];

    message$?: Observable<string | undefined>;
    message?: string;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.getError();
        this.control?.statusChanges.pipe(debounceTime(50)).subscribe(() => {
            this.getError();
            this.cdr.markForCheck();
        });
    }

    getError() {
        this.message = this.validators?.find((v) => {
            if (v.pattern) return this.control?.getError('pattern');
            else return this.control?.getError(v.name);
        })?.msgId;
    }
}
