import { concatSlash } from "../../../core/helper/text";
import { FormConfig } from "../../../core/interfaces/form-config/basic-form";
import { CommonListConfig } from "../page-configs.helper";

const rootPath = '../../../../assets/json/stock-count-review';
export const STOCK_COUNT_REVIEW_TABLE = concatSlash([rootPath, 'stock-count-review-table.json']);
export const STOCK_COUNT_REVIEW_SEARCH_FORM = concatSlash([rootPath, 'stock-count-review-search-form.json']);
export const STOCK_COUNT_REVIEW_OVERVIEW_FORM = concatSlash([rootPath, 'stock-count-review-overview-form.json']);
export const STOCK_COUNT_REVIEW_DETAIL_FORM = concatSlash([rootPath, 'stock-count-review-details-form.json']);
export const ASSIGN_FORM = concatSlash([rootPath, 'assign-dialog-form.json']);

export const ConfigFeatureKey = 'stockCountReviewListConfig';

export interface ConfigState {
    stockCountReviewList?: CommonListConfig;
    assignConfig?: FormConfig;
    stockCountReviewDetail?: FormConfig;
    stockCountReviewOverview?: FormConfig
}