import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ControlBaseComponent } from '../../../../base/components/control.component';
import { RadioButtonConfig } from '../../../../interfaces/form-config/radio-button';
import { TextModule } from '../text/text.module';

@Component({
    standalone: true,
    selector: 'app-radio-button',
    template: `
        <ng-container
            *ngIf="_config?.readonly || mode === FormMode.Detail; else input">
            <app-text
                [config]="config"
                [control]="control"
                [customLabel]="
                    options || _config?.options || []
                        | optionLabel : control.value : _config
                ">
            </app-text>
        </ng-container>

        <ng-template #input>
            <div class="flex items-center space-x-4 h-40px">
                <div
                    *ngFor="let item of options || _config?.options"
                    class="flex items-center space-x-2">
                    <p-radioButton
                        [formControl]="control"
                        [name]="_config?.groupName || 'default'"
                        [value]="item?.[_config?.optionValue || 'value']"
                        [tabindex]="0">
                    </p-radioButton>

                    <label
                        class="text-control"
                        [ngClass]="{ 'font-bold': _config?.fontBold }">
                        {{ item?.[_config?.optionLabel || 'label'] }}
                    </label>
                </div>
            </div>
        </ng-template>
    `,
    styleUrls: ['./radio-button.component.scss'],
    imports: [CommonModule, ReactiveFormsModule, RadioButtonModule, TextModule],
})
export class RadioButtonComponent extends ControlBaseComponent<RadioButtonConfig> {
    @Input() options?: any[];

    constructor() {
        super();
    }
}
