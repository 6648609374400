import { ChangeDetectionStrategy, Component } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputNumberModule } from 'primeng/inputnumber';
import { ControlBaseComponent } from '../../../../base/components/control.component';
import { LENGTH } from '../../../../constant/control-config';
import { NumberFormat } from '../../../../enum/common';
import { InputNumberConfig } from '../../../../interfaces/form-config/input-number';
import { TextModule } from '../text/text.module';
import { InputNumberClassPipe } from './input-number.pipe';

@Component({
    standalone: true,
    selector: 'app-input-number',
    template: `
        <ng-container
            *ngIf="_config?.readonly || mode === FormMode.Detail; else input">
            <app-text
                [config]="config"
                [control]="control">
            </app-text>
        </ng-container>

        <ng-template #input>
            <p-inputNumber
                class="w-full"
                [styleClass]="_config | inputNumberClass"
                [placeholder]="_config?.placeholder || '' | translate"
                [formControl]="control"
                [mode]="_config?.mode || 'decimal'"
                [min]="_config?.min || DEFAULT_MIN"
                [max]="_config?.max || DEFAULT_MAX"
                [minFractionDigits]="
                    _config?.minFractionDigits || MIN_DECIMAL_PLACES
                "
                [maxFractionDigits]="
                    _config?.maxFractionDigits || MAX_DECIMAL_PLACES
                "
                [maxlength]="_config?.maxLength || MAX_LENGTH"
                [useGrouping]="_config?.useGrouping || false">
            </p-inputNumber>
        </ng-template>
    `,
    styleUrls: ['./input-number.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        InputNumberModule,
        TextModule,
        ReactiveFormsModule,
        TranslateModule,
        InputNumberClassPipe,
    ],
})
export class InputNumberComponent extends ControlBaseComponent<InputNumberConfig> {
    MAX_LENGTH = LENGTH.SINGLE_LINE;
    DEFAULT_MIN = NumberFormat.DefaultMinValue;
    DEFAULT_MAX = NumberFormat.DefaultMaxValue;
    MIN_DECIMAL_PLACES = NumberFormat.DefaultMinDecimalPlaces;
    MAX_DECIMAL_PLACES = NumberFormat.DefaultMaxDecimalPlaces;

    constructor() {
        super();
    }
}
