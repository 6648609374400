import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { MessageService } from 'primeng/api';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DynamicFormModule } from './core/components/dynamic-form/dynamic-form.module';
import { LoadingModule } from './core/components/loading/loading.module';
import { MessageDialogModule } from './core/components/message-dialog/message-dialog.module';
import { ToastComponent } from './core/components/toast/toast.component';
import { HttpLoaderFactory } from './core/modules/lazy-load-translate.module';
import { AppInsightsService } from './core/services/common/app-insights.service';
import { HttpCustomRequestInterceptor } from './core/services/interceptors/custom.request.interceptor';
import { HttpErrorsInterceptor } from './core/services/interceptors/errors.interceptor';
import { HttpLoaderInterceptor } from './core/services/interceptors/loader.interceptor';
import { HttpRequestInterceptor } from './core/services/interceptors/request.interceptor';
import { metaReducers, reducers } from './store';
import { AccountConfigModule } from './store/page-configs/account-config/account-config.module';
import { ExternalConfigModule } from './store/page-configs/external-config/external-config.module';
import { ActionHistoryConfigModule } from './store/page-configs/action-history/action-history-config.module';
import { AuditTrailConfigModule } from './store/page-configs/audit-trail-config/audit-trail-config.module';
import { BinConfigurationConfigModule } from './store/page-configs/bin-configuration-config/bin-configuration-config.module';
import { CountAssignmentConfigModule } from './store/page-configs/count-assignment-config/count-assignment-config.module';
import { DashboardConfigModule } from './store/page-configs/dashboard-config/dashboard-config.module';
import { DocumentInfoConfigModule } from './store/page-configs/document-info-config/document-info-config.module';
import { MarketConfigModule } from './store/page-configs/market-config/market-config.module';
import { MaterialMasterConfigModule } from './store/page-configs/material-master-config/material-master-config.module';
import { PermissionConfigModule } from './store/page-configs/permission-config/permission-config.module';
import { StockCountConfigModule } from './store/page-configs/stock-count-config/stock-count-config.module';
import { StockCountReviewListConfigModule } from './store/page-configs/stock-count-review-config/stock-count-review-config.module';

const STORE_MODULES = [
    DashboardConfigModule,
    PermissionConfigModule,
    AuditTrailConfigModule,
    StockCountConfigModule,
    MarketConfigModule,
    AccountConfigModule,
    ExternalConfigModule,
    BinConfigurationConfigModule,
    StockCountReviewListConfigModule,
    DocumentInfoConfigModule,
    MaterialMasterConfigModule,
    ActionHistoryConfigModule,
    CountAssignmentConfigModule,
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        LoadingModule,
        DynamicFormModule,
        ToastComponent,
        MessageDialogModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AuthModule.forRoot({
            config: environment.authenticate,
        }),
        LoadingBarHttpClientModule,
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        EffectsModule.forRoot([]),
        StoreModule.forRoot(reducers, { metaReducers }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        StoreRouterConnectingModule.forRoot(),
        ...STORE_MODULES,
    ],
    providers: [
        MessageService,
        AppInsightsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorsInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpLoaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpCustomRequestInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
