import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { LinkAPI } from '../../../services/api';
import { LoadingService } from '../../components/loading/loading.service';

@Injectable()
export class HttpLoaderInterceptor implements HttpInterceptor {
    constructor(private _loader: LoadingService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // API Endpoints exclusively used for populating dropdowns
        const loaderExempts = [
            { url: 'assets/img/' },
            { url: LinkAPI.BIN_OPTIONS },
            { url: LinkAPI.DOCUMENT_OPTIONS },
            { url: LinkAPI.PRODUCT_OPTIONS },
            { url: LinkAPI.MASTER_STORAGE_BIN_OPTIONS },
            { url: LinkAPI.DOCUMENT_PID_OPTIONS },
            { url: LinkAPI.DOCUMENT_PRODUCT_OPTIONS },
            { url: LinkAPI.DOCUMENT_TEAM_OPTIONS },
            { url: LinkAPI.DOCUMENT_VENDOR_OPTIONS },
            { url: LinkAPI.MARKET_OPTIONS },
            { url: LinkAPI.PLAN_OPTIONS },
            { url: LinkAPI.PRODUCT_OPTIONS },
            { url: LinkAPI.TEAM_OPTIONS },
            { url: LinkAPI.USER_OPTIONS },
            { url: LinkAPI.VENDOR_OPTIONS },
            { url: LinkAPI.WAREHOUSE_OPTIONS },
            { url: LinkAPI.STORAGE_BLOB_FILE_LIST },
            { url: LinkAPI.STOCK_COUNT_OPTIONS },
            { url: LinkAPI.STOCK_COUNT_REVIEW_OPTIONS },
            { url: LinkAPI.STOCK_COUNT_REVIEW_EXPORT },
            { url: LinkAPI.VARIANCE_REPORT_EXPORT },
            { url: LinkAPI.STOCK_COUNT_PRODUCT_SCAN },
            { url: LinkAPI.STOCK_COUNT_HANDLING_UNIT_SCAN },
            { url: LinkAPI.CUSTOMIZATION },
            { url: LinkAPI.USER_OPTIONS },
            { url: LinkAPI.PRODUCT_LIST },
            { url: LinkAPI.NOTIFICATIONS_CHANNEL },
            { url: LinkAPI.NOTIFICATIONS_LIST },
            { url: LinkAPI.NOTIFICATIONS_READ },
            { url: LinkAPI.MESSAGE_RECIPIENT_OPTIONS },
            { url: LinkAPI.MESSAGE },
            { url: LinkAPI.MESSAGE_LIST },
            { url: LinkAPI.MESSAGE_THREAD_LIST },
            { url: LinkAPI.MESSAGE_THREAD_STATUS },
        ];

        // Checks if request matches exemptions, only show loader if it's not
        let isExempt = loaderExempts.find((e) => req.url.includes(e.url));

        if (!isExempt) this._loader.show();
        return next.handle(req).pipe(
            finalize(() => {
                if (!isExempt) this._loader.hide();
            })
        );
    }
}
