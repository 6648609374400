import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberComponent } from './number.component';
import { TranslateModule } from '@ngx-translate/core';
import { TextModule } from '../text/text.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NumberDirective } from './number.directive';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
    declarations: [NumberComponent, NumberDirective],
    imports: [CommonModule, TranslateModule, TextModule, ReactiveFormsModule, InputTextModule],
    exports: [NumberComponent]
})
export class NumberModule {}
