import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ConfigFeatureKey } from "./stock-count-config";
import { StockCountEffects } from "./stock-count-config.effects";
import { reducer } from "./stock-count-config.reducer";

@NgModule({
    imports: [StoreModule.forFeature(ConfigFeatureKey, reducer), EffectsModule.forFeature([StockCountEffects])]
})
export class StockCountConfigModule { }