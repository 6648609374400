import { concatSlash } from '../../../core/helper/text';
import { TableColumn } from '../../../core/interfaces/dynamic-table';
import { FormConfig } from '../../../core/interfaces/form-config/basic-form';
import { CommonListConfig } from '../page-configs.helper';

const ACCOUNT_PATH = '../../../../assets/json/account';
const EXTERNAL_PATH = '../../../../assets/json/external';
export const EXTERNAL_TABLE = concatSlash([EXTERNAL_PATH, 'external-table.json']);
export const EXTERNAL_SEARCH_FORM = concatSlash([EXTERNAL_PATH, 'external-search-form.json']);
export const EXTERNAL_CRUD_FORM = concatSlash([EXTERNAL_PATH, 'external-form.json']);
export const EXTERNAL_ROLE_TABLE = concatSlash([ACCOUNT_PATH, 'account-role-table.json']);
export const EXTERNAL_ROLE_ASSIGN_FORM = concatSlash([ACCOUNT_PATH, 'account-role-assign-form.json']);
export const EXTERNAL_ROLE_DETAILS_FORM = concatSlash([ACCOUNT_PATH, 'account-role-details-form.json']);

export const ConfigFeatureKey = 'externalAccountConfig';

export interface ConfigState {
    externalAccountList?: CommonListConfig;
    externalAccountInfo?: ExternalInfoConfig;
    roleSidebar?: FormConfig;
}

export interface ExternalInfoConfig {
    formConfig?: FormConfig;
    roleFormConfig?: FormConfig;
    roleTableConfig?: TableColumn[];
}
