import { TableColumn } from '../../core/interfaces/dynamic-table';
import { FormConfig } from '../../core/interfaces/form-config/basic-form';
import { TabConfig } from '../../core/interfaces/form-config/tab-view';
import { CoreHttpService } from '../../core/services/common/json.service';

export const getFormConfig = (path: string, _http: CoreHttpService) =>
    _http.getJson<FormConfig>(path);

export const getTableConfig = (path: string, _http: CoreHttpService) =>
    _http.getJson<TableColumn[]>(path);

export const getTabViewConfig = (path: string, _http: CoreHttpService) =>
    _http.getJson<TabConfig[]>(path);

export interface CommonListConfig {
    searchConfig: FormConfig;
    tableConfig: TableColumn[];
}
