import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timeout } from 'rxjs';

import { GlobalVariables } from '../../base/variable/global-variable';
import { CommonFunction } from '../../constant/common';



@Injectable({
    providedIn: 'root'})
export class HttpCustomRequestInterceptor {
    constructor() {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Added items to Header
        let headers = req.headers
            .set('Content-Type', 'application/json')
            .append('Accept-Language', GlobalVariables.language)
            .append('requestId', CommonFunction.autoGenerateGuidId())
            .append('language', GlobalVariables.language);

        req = req.clone({
            headers: headers
        });

        return next.handle(req).pipe(timeout(120000)); //120s
    }
}
