import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { ControlBaseComponent } from '../../../../base/components/control.component';
import { CheckBoxConfig } from '../../../../interfaces/form-config/checkbox';

@Component({
    standalone: true,
    selector: 'app-checkbox',
    template: `
        <p-checkbox
            [formControl]="control"
            [binary]="true">
        </p-checkbox>
    `,
    styleUrls: ['./checkbox.component.scss'],
    imports: [CommonModule, ReactiveFormsModule, CheckboxModule],
})
export class CheckboxComponent extends ControlBaseComponent<CheckBoxConfig> {
    constructor() {
        super();
    }
}
