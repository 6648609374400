import { concatSlash } from '../../../core/helper/text';
import { CommonListConfig } from '../page-configs.helper';

const ROOT_PATH = '../../../../assets/json/bin-configuration';
export const BIN_CONFIGURATION_TABLE = concatSlash([ROOT_PATH, 'bin-configuration-table.json']);
export const BIN_CONFIGURATION_SEARCH_FORM = concatSlash([
    ROOT_PATH,
    'bin-configuration-search-form.json',
]);

export const ConfigFeatureKey = 'binConfigurationConfig';

export interface ConfigState {
    binConfigurationList?: CommonListConfig;
}
