import { every, isArray, isEqual, isNaN, isNull, isUndefined, size } from 'lodash';

export const isNullUndefiend = (data: any) =>
    isUndefined(data) || isNull(data) || isNaN(data) ? undefined : data;

export const emptyObject = (data: any) => (every(data, (e) => e === undefined) ? undefined : data);

export const isEmpty = (data: any) => {
    return (
        (isArray(data) && isEqual(size(data), 0)) ||
        isUndefined(data) ||
        isNull(data) ||
        isNaN(data) ||
        isEqual(data, 0) ||
        isEqual(data, '')
    );
};
