import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isEqual } from 'lodash';
import { catchError, EMPTY, Observable, tap } from 'rxjs';

import { APP_ROUTES } from '../../../routers/app.routes';
import { GlobalVariables } from '../../base/variable/global-variable';
import { LoadingService } from '../../components/loading/loading.service';
import { MessageDialogService } from '../../components/message-dialog/message-dialog.service';
import { CommonFunction } from '../../constant/common';
import { HTTP_STATUS } from '../../enum/http-status';
import { AppInsightsService } from '../common/app-insights.service';

@Injectable({ providedIn: 'root' })
export class HttpErrorsInterceptor implements HttpInterceptor {
    constructor(
        private _msg: MessageDialogService,
        private router: Router,
        private _loading: LoadingService,
        private _appInsights: AppInsightsService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            !isEqual(
                GlobalVariables.currentHttpStatus,
                CommonFunction.createHttpCode(HTTP_STATUS.UNAUTHORIZED)
            )
        ) {
            return next.handle(req).pipe(
                tap((evt) => {
                    if (evt instanceof HttpResponse) {
                        if (evt.body && evt.body.IsSuccess) {
                            if (
                                GlobalVariables.currentHttpStatus !==
                                    CommonFunction.createHttpCode(HTTP_STATUS.UNAUTHORIZED) &&
                                (!GlobalVariables.currentHttpStatus ||
                                    GlobalVariables.currentHttpStatus.status === 'ok')
                            ) {
                                GlobalVariables.currentHttpStatus = CommonFunction.createHttpCode(
                                    evt.status.toString()
                                );
                            }
                        } else {
                            // TODO: show error when export file with no dat
                        }
                    }
                }),
                catchError((err: any) => {
                    // TODO: handle when start and end initial APIs, to detect when all APIs ended.
                    if (req.method !== 'GET') {
                    }

                    this._appInsights.logException(err.error);
                    if (err instanceof HttpErrorResponse) {
                        try {
                            this.catchErrorAPI(err, req);
                            // console.error('An error occurred:', err.error);
                        } catch (e) {
                            // console.error('An error occurred');
                        }
                        this._appInsights.logException(err.error);
                    }
                    return new Observable((observer) => observer.next(err)) as Observable<
                        HttpEvent<any>
                    >;
                })
            );
        } else {
            return EMPTY;
        }
    }

    private catchErrorAPI(err: HttpErrorResponse, req: HttpRequest<any>) {
        const status = err.status.toString();
        switch (status) {
            case HTTP_STATUS.UNAUTHORIZED:
                this.router.navigate([APP_ROUTES.Unauthorized], { state: { logout: false } });
                break;

            case HTTP_STATUS.FORBIDDEN: // 403
            case HTTP_STATUS.BAD_REQUEST: // 400
                this.handleErrorDialog(`MSG_ERROR_${status}`);
                break;

            case HTTP_STATUS.UNKNOWN:
            case HTTP_STATUS.PAGE_NOT_FOUND: // 404
            case HTTP_STATUS.SERVER_ERROR: // 500
            case HTTP_STATUS.BAD_GATEWAY: // 502
                this.handleErrorDialog(
                    status === HTTP_STATUS.UNKNOWN ? 'MSG_ERROR_NETWORK' : `MSG_ERROR_${status}`
                );
                GlobalVariables.currentHttpStatus = CommonFunction.createHttpCode(status);
                break;

            default:
                GlobalVariables.currentHttpStatus = CommonFunction.createHttpCode(status);
                break;
        }
    }

    /**
     * Function trigger error dialog
     */
    private handleErrorDialog(msgId: string) {
        this._loading.hide();
        this._msg.show(
            {
                message: msgId,
                action: {
                    accept: () => {
                        // if (GlobalVariables.userSignInInfo.authorizeToken) {
                        //     this.router.navigateByUrl(LayoutRoutes.Dashboard);
                        // } else {
                        //     // TODO: request logout SSO
                        // }
                    }
                }
            },
            'error'
        );
    }
}
