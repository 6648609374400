import { BaseLinkAPI } from '../core/constant/api';

export class LinkAPI extends BaseLinkAPI {
    // Initialize dropdown options
    static BIN_OPTIONS = 'storagebin/init';
    static DOCUMENT_OPTIONS = 'master/documents-stockcounting';
    static MASTER_STORAGE_BIN_OPTIONS = 'master/storage-bin';
    static DOCUMENT_VENDOR_OPTIONS = 'master/vendors-document';
    static DOCUMENT_PRODUCT_OPTIONS = 'master/product-document';
    static DOCUMENT_PID_OPTIONS = 'master/pid';
    static DOCUMENT_TEAM_OPTIONS = 'master/team';
    static MESSAGE_RECIPIENT_OPTIONS = 'master/user-message-options';
    static MARKET_OPTIONS = 'master/system-market';
    static PLAN_OPTIONS = 'stockcountplan/init';
    static PRODUCT_OPTIONS = 'master/products';
    static TEAM_OPTIONS = 'countteam/init';
    static USER_OPTIONS = 'account/users';
    static VENDOR_OPTIONS = 'master/vendors';
    static WAREHOUSE_OPTIONS = 'warehouse/init';
    static HU_OPTIONS = 'master/hus';
    static PRODUCT_INFORMATION_OPTIONS = 'master/product-information';

    static NOTIFICATIONS_CHANNEL = 'notification/generate-client-url';
    static NOTIFICATIONS_LIST = 'notification/list';
    static NOTIFICATIONS_READ = 'notification/read';

    // User profile
    static MASTER = 'master';
    static MASTER_USER_ASSIGN_TEAM = 'master/user-assign-team/init';
    static MASTER_TEMPLATE_IMPORT = 'master/template-import/init';

    static USER_PROFILE = 'userprofile';
    static USER_INIT = 'user/init';
    static USER = 'user';

    // Market
    static MARKET = 'market';
    static MARKET_LIST = 'market/list';
    static MARKET_EXPORT = 'market/export';

    // Account
    static ACCOUNT = 'account';
    static ACCOUNT_LIST = 'account/list';
    static ACCOUNT_IMPORT = 'account/import';
    static ACCOUNT_EXPORT = 'account/export';
    static EXTERNAL_UPDATE = 'account/external-update';

    // Material Master
    static MATERIAL_MASTER = 'materialmaster';
    static MATERIAL_MASTER_LIST = 'materialmaster/list';
    static MATERIAL_MASTER_IMPORT = 'materialmaster/import';
    static MATERIAL_MASTER_EXPORT = 'materialmaster/export';
    static MATERIAL_MASTER_OPTIONS = 'master/material-options';

    // Bin Master
    static BIN_MASTER = 'storagebin';
    static BIN_MASTER_LIST = 'storagebin/list';
    static BIN_MASTER_IMPORT = 'storagebin/import';
    static BIN_MASTER_EXPORT = 'storagebin/export';

    static BIN_CONFIGURATION_LIST = 'storagebin/list-configuration';
    static BIN_CONFIGURATION_UPDATE = 'storagebin/update-configuration';
    static BIN_CONFIGURATION_IMPORT = 'storagebin/import-configuration';
    static BIN_CONFIGURATION_EXPORT = 'storagebin/export-configuration';

    static WAREHOUSE = 'warehouse';

    // Country
    static COUNTRY = 'country';

    // Team Management
    static TEAM = 'countteam';
    static TEAM_LIST = 'countteam/list';
    static TEAM_IMPORT = 'countteam/import';
    static TEAM_EXPORT = 'countteam/export';
    static TEAM_MEMBER = 'countteam/user-team';
    static TEAM_MEMBER_LIST = 'countteam/user-team/list';
    static TEAM_MEMBER_IMPORT = 'countteam/user-team/import';
    static TEAM_MEMBER_EXPORT = 'countteam/user-team/export';

    // Count Assignment
    static COUNT_ASSIGNMENT_LIST = 'countassignment/list';
    static COUNT_ASSIGNMENT_IMPORT = 'countassignment/import';
    static COUNT_ASSIGNMENT_EXPORT = 'countassignment/export';
    static COUNT_ASSIGNMENT_DETAIL = 'countassignment/detail';
    static COUNT_ASSIGNMENT_UPDATE = 'countassignment';
    static COUNT_ASSIGNMENT_CLEAR = 'countassignment/clear';
    static COUNT_ASSIGNMENT_COMPARE_LIST = 'countassignment/compare';
    static COUNT_ASSIGNMENT_COMPARE_EXPORT = 'countassignment/export-compare';
    static COUNT_ASSIGNMENT_UPDATE_DOCUMENT = 'countassignment/update-document';

    // Stock Count Plan
    static PLAN = 'stockcountplan';
    static PLAN_LIST = 'stockcountplan/list';
    static PLAN_INIT = 'stockcountplan/init';

    // Inventory Stock-on-Hand
    static STOCK = 'inventorysoh';
    static STOCK_LIST = 'inventorysoh/list';
    static STOCK_IMPORT = 'inventorysoh/import';
    static STOCK_EXPORT = 'inventorysoh/export';

    // Inventory Documents
    static DOCUMENT = 'inventorydoc';
    static DOCUMENT_LIST = 'inventorydoc/list';
    static DOCUMENT_IMPORT = 'inventorydoc/import';
    static DOCUMENT_EXPORT = 'inventorydoc/export';

    // Stock Count Review
    static STOCK_COUNT_REVIEW_LIST = 'stockcountreview/list';
    static STOCK_COUNT_REVIEW_INFO = 'stockcountreview/detail';
    static STOCK_COUNT_REVIEW_GENERATE_REPORT =
        'stockcountreview/generate-report';
    static STOCK_COUNT_REVIEW_EXPORT = 'stockcountreview/export';

    // Variance Report
    static VARIANCE_REPORT_LIST = 'stockcountreview/reports';
    static VARIANCE_REPORT_INFO = 'stockcountreview/report/detail';
    static VARIANCE_REPORT_EXPORT = 'stockcountreview/report/detail/export';

    // Storage Blob
    static STORAGE_BLOB = 'storageBlob';
    static STORAGE_BLOB_LIST = 'storageblob/list';
    static STORAGE_BLOB_FILE_LIST = 'storageblob/files/list';
    static STORAGE_BLOB_FILE_SAVE = 'storageblob/files/save-inventory';
    static STORAGE_BLOB_EXPORT = 'storageblob/export';

    // SAP
    static SAP_MATERIAL = 'sap/material';
    static SAP_STOCK = 'sap/soh';
    static SAP_STOCK_SYNC = 'sap/soh-sync';
    static SAP_STOCK_MATERIAL_SYNC = 'sap/soh-material-sync';
    static SAP_STOCK_EXPORT = 'sap/soh/export';
    static SAP_STORAGE_BIN = 'sap/storagebin';
    static SAP_STORAGE_TYPES = 'sap/storagetypes';
    static ACTION_HISTORY = 'sap/actionhistories';
    static ACTION_HISTORY_DETAIL = 'sap/action-history-details';
    static ACTION_HISTORY_DETAIL_EXPORT = 'sap/action-history-details/export';

    // Stock Counting
    static PRODUCT_LIST = 'stockcounting/products';
    static PRODUCT_CREATE = 'stockcounting/product';
    static STOCK_COUNT_LIST = 'stockcounting/list';
    static STOCK_COUNT_RESET = 'stockcounting/reset';
    static STOCK_COUNT_EMPTY_BIN = 'stockcounting/emptybin';
    static STOCK_COUNT_ASSIGN = 'stockcounting/assign';
    static STOCK_COUNT_STATUS = 'stockcounting/status';
    static STOCK_COUNT_SUBMIT = 'stockcounting/submit';
    static STOCK_COUNT_RECOUNT = 'stockcounting/recount';
    static STOCK_COUNT_VERIFY = 'stockcounting/verify';
    static STOCK_COUNT_APPROVE = 'stockcounting/approve';
    static STOCK_COUNT_START = 'stockcounting/start';
    static STOCK_COUNT_AUTO_COUNT = 'stockcounting/auto/send';
    static STOCK_COUNT_FI_COUNT = 'stockcounting/fi-count';
    static STOCK_COUNT_MISSING = 'stockcounting/missing';
    static STOCK_COUNT_COPY_BI = 'stockcounting/copy-bi';
    static STOCK_COUNT_CLEAR_COUNT = 'stockcounting/clear-count';
    static STOCK_COUNT_BIN_SCAN = 'stockcounting/scan-bin';
    static STOCK_COUNT_PRODUCT_SCAN = 'stockcounting/scan-count-update';
    static STOCK_COUNT_HANDLING_UNIT_SCAN = 'stockcounting/scan-hu';
    static STOCK_COUNT_OPTIONS = 'master/stock-counting-options';
    static MASTER_STORAGE_TYPE_OPTIONS = 'master/storage-type';
    static MASTER_STORAGE_SECTION_OPTIONS = 'master/storage-section';
    static MASTER_BIN_SECTION_OPTIONS = 'master/bin-section';
    static MASTER_AISLE_OPTIONS = 'master/aisle';

    // Stock Count Review
    static STOCK_COUNT_REVIEW_REVERT = 'stockcountreview/reverts';
    static STOCK_COUNT_REVIEW_MASS_RECOUNT = 'stockcountreview/mass-recount';
    static STOCK_COUNT_REVIEW_MASS_VERIFY = 'stockcountreview/mass-verify';
    static STOCK_COUNT_REVIEW_OPTIONS = 'master/stock-count-review-options';
    static STOCK_COUNT_REVIEW_MASS_COPY_BI = 'stockcountreview/mass-copy-bi';

    // Dashboard
    static DASHBOARD_COUNT_ASSIGNMENT = 'dashboard/count-assignment';
    static DASHBOARD_COUNT_ASSIGNMENT_EXPORT =
        'dashboard/count-assignment/export';

    static DASHBOARD_COUNTING_STATUS_PER_BIN = 'dashboard/status-per-bin';

    static DASHBOARD_COUNTING_STATUS_PER_ITEM = 'dashboard/status-per-item';

    static DASHBOARD_COUNTING_STATUS_PER_DOC = 'dashboard/status-per-doc';
    static DASHBOARD_COUNTING_STATUS_PER_DOC_EXPORT =
        'dashboard/status-per-doc/export';

    static DASHBOARD_PRINCIPAL_COUNT_RESULTS =
        'dashboard/principal-count-result';
    static DASHBOARD_PRINCIPAL_COUNT_RESULTS_EXPORT =
        'dashboard/principal-count-result/export';

    static DASHBOARD_WH_ACCURACY_RATE = 'regionaldashboard/wh-accuracy-rate';
    static DASHBOARD_WH_ACCURACY_RATE_EXPORT =
        'regionaldashboard/wh-accuracy-rate/export';

    static DASHBOARD_WH_STOCK_ACCURACY_LEVEL =
        'regionaldashboard/stock-accuracy-level';
    static DASHBOARD_WH_STOCK_ACCURACY_LEVEL_EXPORT =
        'regionaldashboard/stock-accuracy-level/export';

    static DASHBOARD_WH_STOCK_COUNTING_FREQUENCY =
        'regionaldashboard/stock-counting-frequency';

    static DASHBOARD_MARKET_PERFORMANCE =
        'regionaldashboard/market-performance';
    static DASHBOARD_MARKET_PERFORMANCE_EXPORT =
        'regionaldashboard/market-performance/export';

    // Calendar
    static CALENDAR_PLAN = 'calendar/plan';

    // Help Desk
    static UPLOAD_TOKEN = 'storageblob/upload/token';
    static MESSAGE_THREAD = 'message';
    static MESSAGE_THREAD_LIST = 'message/list';
    static MESSAGE_THREAD_STATUS = 'message/status';
    static MESSAGE = 'message/send';
    static MESSAGE_LIST = 'message/detail';

    // Permission
    static PERMISSION = 'permission';
    static PERMISSION_ROLES = 'permission/roles';

    // Common
    static AUDIT_TRAIL = 'common/audit-trails';
    static AUDIT_TRAIL_EXPORT = 'common/audit-trails/export';
    static CUSTOMIZATION = 'common/data-grid-customizations';
    static CUSTOMIZATION_RESET = 'common/data-grid-customizations/reset';
}
