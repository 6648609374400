import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { LoadingService } from '../../components/loading/loading.service';

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate {
    constructor(private _loading: LoadingService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this._loading.hide();
        return true;
    }
}
