export const environment = {
    baseUrl: 'https://ezstock-dev-api.zuelligpharma.com/',
    production: false,
    authenticate: {
        authority: 'https://ezstock-dev-sso.zuelligpharma.com',
        redirectUrl: 'https://ezstock-dev.zuelligpharma.com',
        postLogoutRedirectUri: 'https://ezstock-dev.zuelligpharma.com',
        clientId: 'EZSTOCK-LOCAL',
        scope: 'openid profile ezstock',
        responseType: 'id_token token',
        silentRenew: true,
        useRefreshToken: true,
    },
    scandit: {
        licenseKey:
            'AVHkiz3xGdzhErGxIkSgrYcwl4o5PmnXQm7VSGVXRecHaDmDMU8wLG5IvtVzbu4dQkeqK1khJWTbWsf5P1DMQjFkj4+tKM+GgycTvU8wP3juM58BdHMQlTBB5FuhV0v/0F7VuDQazN7LAujasEhyiQFCfv46Dl70QRddPKpK5uWRcbnqtUVzaccYym3wUdgNXjsqW/5/PbBzWH6YuigrdY0EHNeSNaqwsR3p27QpyC4bdQtEh3eLlypaUeCWUG8IO2sY6qN/NdTCYt/lNUYqB2RWUKaseTjgVhmGq4tBZHokDLcWmF4+dt5JJZocWc8La2O2lxdbqaT/C5DNc25gtZUKUHtvaQ3LIk2jnK5TJtkmYIDb4nRV0F0OQYhuCUXJ0WoWEYVo2oZHZs7AZlYIDYhdIzwAYhBFnydl/75wgvMiYHvmVgUJmToZv3fqAWtha3Na26xHwI2obE6w9kbDcldtaiVaMsxJqGklEh0iVYe4Umzb9QFYBplxYoqWQ0YOcA1Mh+kQlal1QdKp5kJ/ULAzpgy2AJj8fHaysWfsQrTGU8Ws6h70yJam31nqA6D6ppiPOBK1IIXCY1AYj8SVg9grh+qKpa58QQgMpCSQuCxrL0mBFdCH+soSLm61+HO659QuFwAH+S7RFXcTCHOiTNPaBuOmfCC6A6gkKpL9v0gN7xREILlAeSjkZ4YY2Cc0CYGNKIuEjoQzE/ojhTrqn3twA242uBdr0+tNzbsyy8QgNVPqxLLHFlWURjig8GYhSK6SFBa8kRZD7k7cm40AodnAvtTdOI2RDZjNKTuQAcEUOQd/BIrsvdPdi/Fw9KZh29TcQJMdP5d08EiWOxsAD38GTDGhHmOnkjO7zfSDwhxjQ+2FsqT1BbwhsES0R6BJnyI0yf4spyApO/P4KD/pBPl2HrAHPIyFEKPEeawTg9IoQlR+YVpSCYyaagQ9567kq4fVQgsb6Skp5bkK0y5PiUoly4H6T5b6vJKwHubcBRbhNX1WG2F0xG0t/Lms5wMq6w8PCJ8w2ytQCTinyz7dErkx0wgM/YNJSwJS/rKfSzyNqRtqpt10uHzFocuWmazCFaazp5D0HpM6lKJWXisJbPh+ixqVKWQIExS7bX8NvCYJmoIDX4DKEASIpZysLjWOgw++fyblm7VvYXAKwlZX+jI4HLTZyIqMUtuQ/GIYZCpv4IQ7fupiLYiZYUXTTjUGFc25bLbG',
    },
    appInsights: {
        instrumentationKey: '',
        disableTelemetry: true,
        enableAutoRouteTracking: false,
        roleName: '',
        roleInstance: '',
    },
};
