import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ControlBaseComponent } from '../../../../base/components/control.component';
import { InputSwitchConfig } from '../../../../interfaces/form-config';
import { TextModule } from '../text/text.module';

@Component({
    standalone: true,
    selector: 'app-input-switch',
    template: `
        <ng-container
            *ngIf="_config?.readonly || mode === FormMode.Detail; else input">
            <app-text
                [config]="config"
                [control]="control">
            </app-text>
        </ng-container>

        <ng-template #input>
            <p-inputSwitch
                class="w-full"
                [formControl]="control">
            </p-inputSwitch>
        </ng-template>
    `,
    styleUrls: ['./input-switch.component.scss'],
    imports: [CommonModule, TextModule, InputSwitchModule, ReactiveFormsModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSwitchComponent extends ControlBaseComponent<InputSwitchConfig> {
    constructor() {
        super();
    }
}
