import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { GlobalVariables } from '@app/core/base/variable/global-variable';
import { LoadingService } from '@app/core/components/loading/loading.service';
import { APP_ROUTES } from '@app/routers/app.routes';

@Injectable({ providedIn: 'root' })
export class HomeGuard implements CanActivate {
    user = GlobalVariables.user;

    constructor(private _router: Router, private _loading: LoadingService) {}

    async canActivate() {
        if (this.user.token && this.user.profile.uid) {
            this._router.navigate([APP_ROUTES.App]);
            return false;
        }

        this._loading.hide();
        return true;
    }
}
