import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'projects/ezstock/src/environments/environment';

@Injectable()

export class AppInsightsService {

  appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: environment.appInsights.enableAutoRouteTracking, // option to log all route changes
        disableTelemetry: environment.appInsights.disableTelemetry
      }
    });
    this.appInsights.loadAppInsights();

    // Add telemetry
    const telemetryInitializer = (envelope: any) => {
      envelope.tags['ai.cloud.role'] = environment.appInsights.roleName;
      envelope.tags['ai.cloud.roleInstance'] = environment.appInsights.roleInstance;
    };
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
