import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, of, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CoreHttpService } from '../../../core/services/common/json.service';
import {
    getFormConfig,
    getTableConfig,
    getTabViewConfig,
} from '../page-configs.helper';
import {
    COUNTING_STATUS_PER_DOC_FILTER_FORM,
    COUNTING_STATUS_PER_DOC_STOCK_TABLE,
    COUNTING_STATUS_PER_DOC_VARIANCE_TABLE,
    COUNT_ASSIGNMENT_DASHBOARD_SEARCH_FORM,
    COUNT_ASSIGNMENT_DASHBOARD_TABLE,
    ITEM_ACCURACY_RATE_TABLE,
    ITEM_ACCURACY_SEARCH_FORM,
    LOCAL_DASHBOARD_SEARCH_FORM,
    LOCAL_DASHBOARD_TABS,
    LOCATION_ACCURACY_DASHBOARD_SEARCH_FORM,
    LOCATION_ACCURACY_DASHBOARD_TABLE,
    MARKET_PERFORMANCE_SEARCH_FORM,
    MARKET_PERFORMANCE_TABLE,
    PRINCIPAL_COUNT_RESULTS_SEARCH_FORM,
    PRINCIPAL_COUNT_RESULTS_TABLE,
    REGIONAL_DASHBOARD_CHART_FILTER_FORM,
    REGIONAL_DASHBOARD_TABS,
    STOCK_ACCURACY_GROSS_SEARCH_FORM,
    STOCK_ACCURACY_GROSS_TABLE,
    STOCK_ACCURACY_NET_SEARCH_FORM,
    STOCK_ACCURACY_NET_TABLE,
    STOCK_COUNTING_FREQUENCY_SEARCH_FORM,
    STOCK_COUNTING_FREQUENCY_TABLE,
} from './dashboard-config';
import { DashboardActions } from './dashboard-config.action';
import { DashboardSelector } from './dashboard-config.selector';

@Injectable()
export class DashboardEffects {
    constructor(
        private _actions$: Actions,
        private _http: CoreHttpService,
        private _store: Store
    ) {}

    // Get Local Dashboard config
    localDashboardConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(DashboardActions.loadLocalDashboardConfigs),
            withLatestFrom(
                this._store.select(DashboardSelector.localDashboardConfig)
            ),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getTabViewConfig(LOCAL_DASHBOARD_TABS, this._http),
                    getFormConfig(LOCAL_DASHBOARD_SEARCH_FORM, this._http),
                ]).pipe(
                    map(([tabViewConfig, searchConfig]) =>
                        DashboardActions.loadLocalDashboardSuccess({
                            data: { tabViewConfig, searchConfig },
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    countAssignmentConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(DashboardActions.loadCountAssignmentConfigs),
            withLatestFrom(
                this._store.select(DashboardSelector.countAssignmentConfig)
            ),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(
                        COUNT_ASSIGNMENT_DASHBOARD_SEARCH_FORM,
                        this._http
                    ),
                    getTableConfig(
                        COUNT_ASSIGNMENT_DASHBOARD_TABLE,
                        this._http
                    ),
                ]).pipe(
                    map(([searchConfig, tableConfig]) =>
                        DashboardActions.loadCountAssignmentSuccess({
                            data: { searchConfig, tableConfig },
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    countingStatusPerDocConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(DashboardActions.loadCountingStatusPerDocConfigs),
            withLatestFrom(
                this._store.select(DashboardSelector.countingStatusPerDocConfig)
            ),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getTableConfig(
                        COUNTING_STATUS_PER_DOC_STOCK_TABLE,
                        this._http
                    ),
                    getTableConfig(
                        COUNTING_STATUS_PER_DOC_VARIANCE_TABLE,
                        this._http
                    ),
                    getFormConfig(
                        COUNTING_STATUS_PER_DOC_FILTER_FORM,
                        this._http
                    ),
                ]).pipe(
                    map(
                        ([
                            stockTableConfig,
                            varianceTableConfig,
                            tableFilterConfig,
                        ]) =>
                            DashboardActions.loadCountingStatusPerDocSuccess({
                                data: {
                                    stockTableConfig,
                                    varianceTableConfig,
                                    tableFilterConfig,
                                },
                            })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    principalCountResultsDashboardConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(DashboardActions.loadPrincipalCountResultsDashboardConfig),
            withLatestFrom(
                this._store.select(
                    DashboardSelector.principalCountResultsDashboardConfig
                )
            ),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(
                        PRINCIPAL_COUNT_RESULTS_SEARCH_FORM,
                        this._http
                    ),
                    getTableConfig(PRINCIPAL_COUNT_RESULTS_TABLE, this._http),
                ]).pipe(
                    map(([searchConfig, tableConfig]) =>
                        DashboardActions.loadPrincipalCountResultsDashboardSuccess(
                            { data: { searchConfig, tableConfig } }
                        )
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    regionalDashboardConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(DashboardActions.loadRegionalDashboardConfigs),
            withLatestFrom(
                this._store.select(DashboardSelector.regionalDashboardConfig)
            ),
            switchMap(([, state]) => {
                if (state) return of();
                return getTabViewConfig(
                    REGIONAL_DASHBOARD_TABS,
                    this._http
                ).pipe(
                    map((config) =>
                        DashboardActions.loadRegionalDashboardSuccess({
                            data: { tabViewConfig: config },
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    locationAccuracyDashboardConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(DashboardActions.loadLocationAccuracyDashboardConfigs),
            withLatestFrom(
                this._store.select(
                    DashboardSelector.locationAccuracyDashboardConfig
                )
            ),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(
                        REGIONAL_DASHBOARD_CHART_FILTER_FORM,
                        this._http
                    ),
                    getFormConfig(
                        LOCATION_ACCURACY_DASHBOARD_SEARCH_FORM,
                        this._http
                    ),
                    getTableConfig(
                        LOCATION_ACCURACY_DASHBOARD_TABLE,
                        this._http
                    ),
                ]).pipe(
                    map(([chartFilterConfig, searchConfig, tableConfig]) =>
                        DashboardActions.loadLocationAccuracyDashboardSuccess({
                            data: {
                                chartFilterConfig,
                                searchConfig,
                                tableConfig,
                            },
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    itemAccuracyRateConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(DashboardActions.loadItemAccuracyRateConfigs),
            withLatestFrom(
                this._store.select(DashboardSelector.itemAccuracyRateConfig)
            ),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(
                        REGIONAL_DASHBOARD_CHART_FILTER_FORM,
                        this._http
                    ),
                    getFormConfig(ITEM_ACCURACY_SEARCH_FORM, this._http),
                    getTableConfig(ITEM_ACCURACY_RATE_TABLE, this._http),
                ]).pipe(
                    map(([chartFilterConfig, searchConfig, tableConfig]) =>
                        DashboardActions.loadItemAccuracyRateSuccess({
                            data: {
                                chartFilterConfig,
                                searchConfig,
                                tableConfig,
                            },
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    stockAccuracyNetConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(DashboardActions.loadStockAccuracyNetConfigs),
            withLatestFrom(
                this._store.select(DashboardSelector.stockAccuracyNetConfig)
            ),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(
                        REGIONAL_DASHBOARD_CHART_FILTER_FORM,
                        this._http
                    ),
                    getFormConfig(STOCK_ACCURACY_NET_SEARCH_FORM, this._http),
                    getTableConfig(STOCK_ACCURACY_NET_TABLE, this._http),
                ]).pipe(
                    map(([chartFilterConfig, searchConfig, tableConfig]) =>
                        DashboardActions.loadStockAccuracyNetSuccess({
                            data: {
                                chartFilterConfig,
                                searchConfig,
                                tableConfig,
                            },
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    stockAccuracyGrossConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(DashboardActions.loadStockAccuracyGrossConfigs),
            withLatestFrom(
                this._store.select(DashboardSelector.stockAccuracyGrossConfig)
            ),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(
                        REGIONAL_DASHBOARD_CHART_FILTER_FORM,
                        this._http
                    ),
                    getFormConfig(STOCK_ACCURACY_GROSS_SEARCH_FORM, this._http),
                    getTableConfig(STOCK_ACCURACY_GROSS_TABLE, this._http),
                ]).pipe(
                    map(([chartFilterConfig, searchConfig, tableConfig]) =>
                        DashboardActions.loadStockAccuracyGrossSuccess({
                            data: {
                                chartFilterConfig,
                                searchConfig,
                                tableConfig,
                            },
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    stockCountingFrequencyConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(DashboardActions.loadStockCountingFrequencyConfigs),
            withLatestFrom(
                this._store.select(
                    DashboardSelector.stockCountingFrequencyConfig
                )
            ),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(
                        STOCK_COUNTING_FREQUENCY_SEARCH_FORM,
                        this._http
                    ),
                    getTableConfig(STOCK_COUNTING_FREQUENCY_TABLE, this._http),
                ]).pipe(
                    map(([searchConfig, tableConfig]) =>
                        DashboardActions.loadStockCountingFrequencySuccess({
                            data: { searchConfig, tableConfig },
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    marketPerformanceConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(DashboardActions.loadMarketPerformanceConfigs),
            withLatestFrom(
                this._store.select(DashboardSelector.marketPerformanceConfig)
            ),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(MARKET_PERFORMANCE_SEARCH_FORM, this._http),
                    getTableConfig(MARKET_PERFORMANCE_TABLE, this._http),
                ]).pipe(
                    map(([searchConfig, tableConfig]) =>
                        DashboardActions.loadMarketPerformanceSuccess({
                            data: { searchConfig, tableConfig },
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });
}
