import { createAction, props } from '@ngrx/store';
import { FormConfig } from '../../../core/interfaces/form-config/basic-form';
import { CommonListConfig } from '../page-configs.helper';
import { ExternalInfoConfig } from './external-config';

export class ExternalActions {
    // External List Configuration Actions
    static readonly loadExternalListConfigs = createAction('[External List] Loading');
    static readonly loadExternalListSuccess = createAction(
        '[External List] Success',
        props<{ data: CommonListConfig }>()
    );
    static readonly loadExternalListError = createAction(
        '[External List] Error',
        props<{ error: any }>()
    );

    // External Info Configuration Actions
    static readonly loadExternalInfoConfigs = createAction('[External Info] Loading');
    static readonly loadExternalInfoSuccess = createAction(
        '[External Info] Success',
        props<{ data: ExternalInfoConfig }>()
    );
    static readonly loadExternalInfoError = createAction(
        '[External Info] Error',
        props<{ error: any }>()
    );

    static readonly loadRoleSidebarConfigs = createAction('[Role Sidebar] Loading');
    static readonly loadRoleSidebarSuccess = createAction(
        '[Role Sidebar] Success',
        props<{ data: FormConfig }>()
    );
    static readonly loadRoleSidebarError = createAction(
        '[Role Sidebar] Error',
        props<{ error: any }>(),
    );
}
