import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadTranslateModule } from '@app/core/modules/lazy-load-translate.module';
import { InputTextModule } from 'primeng/inputtext';
import { ControlBaseComponent } from '../../../../base/components/control.component';
import { LENGTH } from '../../../../constant/control-config';
import { InputTextConfig } from '../../../../interfaces/form-config/input-text';
import { ButtonComponent } from '../../../button/button/button.component';
import { TextModule } from '../text/text.module';
import { Control } from '@app/core/constant/form';
import { ControlAction } from '@app/core/interfaces/form-config/basic-form';

@Component({
    standalone: true,
    selector: 'app-input-text',
    template: `
        <div class="input-wrapper">
            <ng-container
                *ngIf="
                    _config?.readonly || mode === FormMode.Detail;
                    else input
                "
            >
                <app-text
                    [config]="config"
                    [control]="control"
                >
                </app-text>
            </ng-container>

            <ng-template #input>
                <input
                    pInputText
                    type="text"
                    [ngClass]="{
                        'font-bold': _config?.fontBold,
                        'border-none': _config?.noBorder,
                        shadow: _config?.shadow
                    }"
                    [formControl]="control"
                    [placeholder]="_config?.placeholder || '' | translate"
                    [maxlength]="_config?.maxLength || MAX_LENGTH"
                />

                <app-button
                    *ngIf="_config?.button"
                    [label]="_config?.button?.label || 'Button' | translate"
                    [icon]="_config?.button?.icon || ''"
                    [styleClass]="'btn ' + _config?.button?.styleClass"
                    (actionClick)="onActionClick()"
                >
                </app-button>
            </ng-template>
        </div>
    `,
    styleUrls: ['./input-text.component.scss'],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InputTextModule,
        TextModule,
        ButtonComponent,
        FormsModule,
        LazyLoadTranslateModule,
    ],
})
export class InputTextComponent extends ControlBaseComponent<InputTextConfig> {
    @Output() actionClick = new EventEmitter<ControlAction>();

    MAX_LENGTH = LENGTH.SINGLE_LINE;

    constructor() {
        super();
    }

    onActionClick() {
        this.actionClick.emit({
            controlKey: this.key,
            controlType: Control.InputText,
        });
    }
}
