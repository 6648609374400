import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormConfig } from '../../core/interfaces/form-config/basic-form';

@Pipe({
    name: 'asFormControl'
})
export class AsFormCotrolPipe implements PipeTransform {
    transform(value: any): any {
        return value as FormControl;
    }
}

@Pipe({
    name: 'asFormConfig'
})
export class AsFormConfigPipe implements PipeTransform {
    transform(value: any): any {
        return value as FormConfig;
    }
}

@NgModule({
    imports: [],
    declarations: [AsFormCotrolPipe, AsFormConfigPipe],
    exports: [AsFormCotrolPipe, AsFormConfigPipe]
})
export class FormPipeModule {}
