import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConfigFeatureKey } from './bin-configuration-config';
import { BinConfigurationEffects } from './bin-configuration-config.effects';
import { reducer } from './bin-configuration-config.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(ConfigFeatureKey, reducer),
        EffectsModule.forFeature([BinConfigurationEffects]),
    ],
})
export class BinConfigurationConfigModule {}
