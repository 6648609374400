import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, of, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CoreHttpService } from '../../../core/services/common/json.service';
import { getFormConfig, getTableConfig } from '../page-configs.helper';
import {
    COMPARE_DETAILS_FORM,
    COMPARE_SEARCH_FORM,
    COMPARE_TABLE,
    COUNT_ASSIGNMENT_CLEAR_FORM,
    COUNT_ASSIGNMENT_COMPARE_FORM,
    COUNT_ASSIGNMENT_SEARCH_FORM,
    COUNT_ASSIGNMENT_TABLE
} from './count-assignment-config';
import { CountAssignmentActions } from './count-assignment-config.action';
import { CountAssignmentSelector } from './count-assignment-config.selector';

@Injectable()
export class CountAssignmentEffects {
    constructor(
        private _actions$: Actions,
        private _http: CoreHttpService,
        private _store: Store
    ) {}

    countAssignmentListConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CountAssignmentActions.loadListPageConfigs),
            withLatestFrom(this._store.select(CountAssignmentSelector.listPageConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(COUNT_ASSIGNMENT_SEARCH_FORM, this._http),
                    getFormConfig(COUNT_ASSIGNMENT_COMPARE_FORM, this._http),
                    getTableConfig(COUNT_ASSIGNMENT_TABLE, this._http)
                ]).pipe(
                    map(([searchConfig, compareFormConfig, tableConfig]) =>
                        CountAssignmentActions.loadListPageSuccess({
                            data: { searchConfig, compareFormConfig, tableConfig }
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    countAssignmentClearFormConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CountAssignmentActions.loadClearFormConfigs),
            withLatestFrom(this._store.select(CountAssignmentSelector.clearFormConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return getFormConfig(COUNT_ASSIGNMENT_CLEAR_FORM, this._http).pipe(
                    map((data) => CountAssignmentActions.loadClearFormSuccess({ data })),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    compareListConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CountAssignmentActions.loadComparePageConfigs),
            withLatestFrom(this._store.select(CountAssignmentSelector.comparePageConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(COMPARE_SEARCH_FORM, this._http),
                    getTableConfig(COMPARE_TABLE, this._http)
                ]).pipe(
                    map(([searchConfig, tableConfig]) =>
                        CountAssignmentActions.loadComparePageSuccess({
                            data: { searchConfig, tableConfig }
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    compareDetailsFormConfig$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CountAssignmentActions.loadDetailsFormConfigs),
            withLatestFrom(this._store.select(CountAssignmentSelector.detailsFormConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return getFormConfig(COMPARE_DETAILS_FORM, this._http).pipe(
                    map((data) => CountAssignmentActions.loadDetailsFormSuccess({ data })),
                    catchError(() => EMPTY)
                );
            })
        );
    });
}
