import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigFeatureKey, ConfigState } from './bin-configuration-config';

const PageConfigState = createFeatureSelector<ConfigState>(ConfigFeatureKey);

export class BinConfigurationSelector {
    static readonly listConfig = createSelector(
        PageConfigState,
        (state) => state.binConfigurationList
    );
}
