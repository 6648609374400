import { createReducer, on } from '@ngrx/store';
import { ConfigState } from './count-assignment-config';
import { CountAssignmentActions } from './count-assignment-config.action';

export const reducer = createReducer(
    {} as ConfigState,
    on(CountAssignmentActions.loadListPageSuccess, (state, { data }) => ({
        ...state,
        listPage: data
    })),
    on(CountAssignmentActions.loadClearFormSuccess, (state, { data }) => ({
        ...state,
        clearForm: data
    })),
    on(CountAssignmentActions.loadComparePageSuccess, (state, { data }) => ({
        ...state,
        comparePage: data
    })),
    on(CountAssignmentActions.loadDetailsFormSuccess, (state, { data }) => ({
        ...state,
        detailsForm: data
    }))
);
