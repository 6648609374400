import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConfigFeatureKey } from './audit-trail-config';
import { AuditTrailEffects } from './audit-trail-config.effects';
import { reducer } from './audit-trail-config.reducer';

@NgModule({
    imports: [StoreModule.forFeature(ConfigFeatureKey, reducer), EffectsModule.forFeature([AuditTrailEffects])]
})
export class AuditTrailConfigModule {}
