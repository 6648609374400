import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigFeatureKey, ConfigState } from './account-config';

const PageConfigState = createFeatureSelector<ConfigState>(ConfigFeatureKey);

export class AccountSelector {
    static readonly accountListConfig = createSelector(
        PageConfigState,
        (state) => state.accountList
    );

    static readonly accountInfoConfig = createSelector(
        PageConfigState,
        (state) => state.accountInfo
    );

    static readonly roleSidebarConfig = createSelector(
        PageConfigState,
        (state) => state.roleSidebar
    );
}
