import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY, forkJoin, of, withLatestFrom } from "rxjs";
import { CoreHttpService } from "../../../core/services/common/json.service";
import { getFormConfig, getTableConfig } from "../page-configs.helper";
import { Injectable } from "@angular/core";
import { MATERIAL_SEARCH_FORM, MATERIAL_SYNC_FORM, MATERIAL_TABLE } from "./material-master-config";
import { MaterialMasterActions } from "./material-master-config.action";
import { MaterialMasterSelector } from "./material-master-config.selector";

@Injectable()
export class MaterialMasterEffects {
    constructor(private actions$: Actions, private _http: CoreHttpService, private store: Store) {
    }

    // Get material master list config
    materialMasterListConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MaterialMasterActions.loadMaterialListConfigs),
            withLatestFrom(this.store.select(MaterialMasterSelector.materialMasterListConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(MATERIAL_SEARCH_FORM, this._http),
                    getTableConfig(MATERIAL_TABLE, this._http)]
                ).pipe(
                    map(([searchFormConfig, tableConfig]) =>
                        MaterialMasterActions.loadMaterialListSuccess({
                            data: {searchConfig: searchFormConfig, tableConfig}
                        })
                    ),
                    catchError((error) => EMPTY)
                );
            })
        );
    });
    // Get material master sync config
    materialMasterSyncConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MaterialMasterActions.loadMaterialSyncConfigs),
            withLatestFrom(this.store.select(MaterialMasterSelector.materialMasterSyncConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return getFormConfig(MATERIAL_SYNC_FORM, this._http).pipe(
                    map((data) => MaterialMasterActions.loadMaterialSyncSuccess({data})),
                    catchError((err) => EMPTY)
                );
            })
        );
    });
}