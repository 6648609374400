import { createReducer, on } from '@ngrx/store';
import { ConfigState } from './account-config';
import { AccountActions } from './account-config.action';

export const reducer = createReducer(
    {} as ConfigState,
    on(AccountActions.loadAccountListSuccess, (state, { data }) => ({
        ...state,
        accountList: data
    })),
    on(AccountActions.loadAccountInfoSuccess, (state, { data }) => ({
        ...state,
        accountInfo: data
    })),
    on(AccountActions.loadRoleSidebarSuccess, (state, { data }) => ({
        ...state,
        roleSidebar: data
    }))
);
