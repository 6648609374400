export const Message = {
    Confirm: {
        styleClass: 'confirm',
        header: 'DLH_MSG_C'
    },

    Delete: {
        styleClass: 'delete',
        header: 'DLH_MSG_D'
    },

    Success: {
        styleClass: 'success',
        header: 'DLH_MSG_S'
    },

    Error: {
        styleClass: 'error',
        header: 'DLH_MSG_E'
    },

    Warning: {
        styleClass: 'warning',
        header: 'DLH_MSG_W'
    }
};

export const DialogType = {
    Confirm: 'confirm',
    Delete: 'delete',
    Success: 'success',
    Warning: 'warning',
    Error: 'error',
    Close: 'close',
    Other: 'other'
};
