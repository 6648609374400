import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppResolver } from './app.resolver';
import { AppGuard } from './core/services/guards/app.guard';
import { HomeGuard } from './core/services/guards/home.guard';
import { PermissionGuard } from './core/services/guards/permission.guard';
import { APP_ROUTES } from './routers/app.routes';

const routes: Routes = [
    {
        path: APP_ROUTES.App,
        loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule),
        canActivate: [AppGuard],
        resolve: { AppResolver },
    },
    {
        path: APP_ROUTES.Home,
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
        canActivate: [HomeGuard],
    },

    {
        path: APP_ROUTES.Unauthorized,
        loadChildren: () => import('./pages/unauthorized/unauthorized.module').then((m) => m.UnauthorizedModule),
        canActivate: [PermissionGuard],
    },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule),
        canActivate: [PermissionGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
