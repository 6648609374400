import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { LazyLoadTranslateModule } from '../../modules/lazy-load-translate.module';
import { ButtonComponent } from '../button/button/button.component';
import { MessageDialogComponent } from './message-dialog.component';

@NgModule({
    declarations: [MessageDialogComponent],
    imports: [CommonModule, FormsModule, DialogModule, LazyLoadTranslateModule, ButtonComponent],
    exports: [MessageDialogComponent]
})
export class MessageDialogModule {}
