import { createAction, props } from '@ngrx/store';
import { TableColumn } from '../../../core/interfaces/dynamic-table';
import { CommonListConfig } from '../page-configs.helper';

export class RoleActions {
    // Role List
    static readonly loadRoleList = createAction('[Role List] Loading');

    static readonly loadRoleListSuccess = createAction(
        '[Role List] Success',
        props<{ data: CommonListConfig }>()
    );

    static readonly loadRoleListFailure = createAction(
        '[Role List] Failure',
        props<{ error: any }>()
    );

    // Role List
    static readonly loadRolePermissions = createAction('[Role Permissions] Loading');

    static readonly loadRolePermissionsSuccess = createAction(
        '[Role Permissions] Success',
        props<{ data: TableColumn[] }>()
    );

    static readonly loadRolePermissionsFailure = createAction(
        '[Role Permissions] Failure',
        props<{ error: any }>()
    );
}
