import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormMode } from '../../../../constant/form';
import { FormMode as IFormMode } from '../../../../interfaces/form-config/basic-form';

@Component({
    standalone: true,
    selector: 'app-label',
    template: `
        <span class="label">
            {{ label || '' | translate }}

            <span
                *ngIf="required && mode !== FormMode.Detail"
                class="required">
                {{ 'LB_000' | translate }}
            </span>
        </span>
    `,
    styleUrls: ['./label.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, TranslateModule],
})
export class LabelComponent {
    @Input() mode?: IFormMode;
    @Input() label?: string;
    @Input() required?: boolean;

    FormMode = FormMode;
}
