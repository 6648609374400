import { concatSlash } from '../../../core/helper/text';
import { TableColumn } from '../../../core/interfaces/dynamic-table';
import { CommonListConfig } from '../page-configs.helper';

const rootPath = '../../../../assets/json/permission';

export const ROLE_SEARCH_FORM = concatSlash([rootPath, 'role-search-form.json']);
export const ROLE_TABLE = concatSlash([rootPath, 'role-table.json']);
export const ROLE_PERMISSION_TABLE = concatSlash([rootPath, 'role-permission-table.json']);

export const ConfigFeatureKey = 'roleConfig';

export interface ConfigState {
    roleList?: CommonListConfig;
    rolePermissions?: TableColumn[];
}
