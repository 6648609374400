import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    selector: 'app-title',
    template: `
        <p class="pb-1 text-xl font-bold border-b text-secondary border-100">
            {{ value | translate }}
        </p>
    `,
    styleUrls: ['./title.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, TranslateModule],
})
export class TitleComponent {
    @Input() value: string = '';
}
