<form
    class="dynamic-form"
    [ngClass]="config | formClass : mode"
    [formGroup]="formGroup"
>
    <!-- Form Container -->
    <ng-container *ngFor="let control of config?.controls">
        <div
            class="form-container"
            [attr.group]="control.group"
            *ngIf="
                !(
                    control
                    | hiddenControl
                        : mode
                        : formGroup.get(control.key)?.disabled
                )
            "
            [ngClass]="
                config
                    | formContainerClass
                        : mode
                        : control.type
                        : control.styleClass
            "
        >
            <!-- Form Label -->
            <div
                class="form-label"
                *ngIf="control.label"
                [ngClass]="config | formLabelClass : mode"
            >
                <app-label
                    class="w-full"
                    [mode]="mode"
                    [required]="!formGroup.get(control.key)?.disabled ? (control.required || !!config.value?.required?.[control.key]) : false"
                    [label]="control.label"
                ></app-label>
            </div>

            <!-- Form Control -->
            <div
                class="form-control"
                [ngClass]="
                    config | formControlClass : control.config?.styleClass
                "
                [ngSwitch]="control.type"
            >
                <app-title
                    *ngSwitchCase="Control.Label"
                    [value]="control.value"
                ></app-title>

                <ng-container *ngIf="formGroup?.get(control.key) as _control">
                    <div class="control">
                        <div
                            appendToDiv
                            *ngSwitchCase="Control.Div"
                            class="group-container"
                            [ngClass]="control.groupParent || {}"
                            [group]="control.groupParent"
                        ></div>

                        <app-number
                            [mode]="mode"
                            [control]="_control | asFormControl"
                            [config]="control.config"
                            [value]="config.value?.[control.key]"
                            *ngSwitchCase="Control.Number"
                        ></app-number>

                        <app-input-text
                            *ngSwitchCase="Control.InputText"
                            [key]="control.key"
                            [mode]="mode"
                            [control]="_control | asFormControl"
                            [config]="control.config"
                            [value]="config.value?.[control.key]"
                            (actionClick)="onInputActionClick($event)"
                        ></app-input-text>

                        <app-input-number
                            [mode]="mode"
                            [control]="_control | asFormControl"
                            [config]="control.config"
                            [value]="config.value?.[control.key]"
                            *ngSwitchCase="Control.InputNumber"
                        ></app-input-number>

                        <app-chips
                            [mode]="mode"
                            [control]="_control | asFormControl"
                            [config]="control.config"
                            [value]="config.value?.[control.key]"
                            *ngSwitchCase="Control.Chips"
                        ></app-chips>

                        <app-dropdown
                            *ngSwitchCase="Control.Dropdown"
                            [key]="control.key"
                            [mode]="mode"
                            [control]="_control | asFormControl"
                            [config]="control.config"
                            [value]="config.value?.[control.key]"
                            [extra]="formGroup.value"
                            [options]="options?.[control.key]"
                            (searchData)="onDropdownSearch($event, control.key)"
                            (actionClick)="onInputActionClick($event)"
                        ></app-dropdown>

                        <app-checkbox
                            [mode]="mode"
                            [control]="_control | asFormControl"
                            [config]="control.config"
                            [value]="config.value?.[control.key]"
                            *ngSwitchCase="Control.Checkbox"
                        ></app-checkbox>

                        <app-calendar
                            [mode]="mode"
                            [control]="_control | asFormControl"
                            [config]="control.config"
                            [value]="config.value?.[control.key]"
                            [minDate]="
                                formGroup.get(control.config?.minField || '')
                                    ?.value
                            "
                            [maxDate]="
                                formGroup.get(control.config?.maxField || '')
                                    ?.value
                            "
                            *ngSwitchCase="Control.Calendar"
                        ></app-calendar>

                        <app-radio-button
                            [mode]="mode"
                            [control]="_control | asFormControl"
                            [config]="control.config"
                            [value]="config.value?.[control.key]"
                            [options]="options?.[control.key]"
                            *ngSwitchCase="Control.Radio"
                        ></app-radio-button>

                        <app-text-area
                            [mode]="mode"
                            [control]="_control | asFormControl"
                            [config]="control.config"
                            [value]="config.value?.[control.key]"
                            *ngSwitchCase="Control.TextArea"
                        ></app-text-area>

                        <app-multi-select
                            [mode]="mode"
                            [control]="_control | asFormControl"
                            [config]="control.config"
                            [value]="config.value?.[control.key]"
                            [options]="options?.[control.key]"
                            [extra]="formGroup.value"
                            *ngSwitchCase="Control.MultiSelect"
                        ></app-multi-select>

                        <app-input-switch
                            [mode]="mode"
                            [control]="_control | asFormControl"
                            [config]="control.config"
                            [value]="config.value?.[control.key]"
                            *ngSwitchCase="Control.InputSwitch"
                        ></app-input-switch>

                        <app-select-button
                            [mode]="mode"
                            [control]="_control | asFormControl"
                            [config]="control.config"
                            [value]="config.value?.[control.key]"
                            [options]="options?.[control.key]"
                            *ngSwitchCase="Control.SelectButton"
                        ></app-select-button>
                    </div>

                    <app-error-message
                        *ngIf="_control.enabled"
                        [submit]="submit"
                        [control]="_control"
                        [validators]="control.validators"
                    ></app-error-message>
                </ng-container>
            </div>
        </div>
    </ng-container>
</form>
