import { createReducer, on } from '@ngrx/store';
import { ConfigState } from './external-config';
import { ExternalActions } from './external-config.action';

export const reducer = createReducer(
    {} as ConfigState,
    on(ExternalActions.loadExternalListSuccess, (state, { data }) => ({
        ...state,
        externalAccountList: data
    })),
    on(ExternalActions.loadExternalInfoSuccess, (state, { data }) => ({
        ...state,
        externalAccountInfo: data
    })),
    on(ExternalActions.loadRoleSidebarSuccess, (state, { data }) => ({
        ...state,
        roleSidebar: data
    }))
);
