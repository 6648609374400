import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';

@Component({
    selector: 'app-text',
    template: `
        <div class="text flex flex-grow items-center p-2 min-h-40px">
            <span
                #label
                class="max-w-full truncate"
                [ngClass]="{ 'font-bold': config?.fontBold }"
                [escape]="false"
                [pTooltip]="
                    customLabel || control.value
                        | tooltip : label : label.scrollWidth : cdr
                "
                [tooltipPosition]="'top'">
                {{ customLabel || control.value }}
            </span>
        </div>
    `,
    styleUrls: ['./text.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent implements OnInit {
    @Input() control!: FormControl;
    @Input() config?: any;
    @Input() customLabel?: string | null;

    constructor(public cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.control.valueChanges
            .pipe(debounceTime(200))
            .subscribe(() => this.cdr.markForCheck());
    }
}
