import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigFeatureKey, ConfigState } from './stock-count-review-config';

const PageConfigState = createFeatureSelector<ConfigState>(ConfigFeatureKey);
export class StockCountReviewSelector {
    static readonly listPageConfig = createSelector(PageConfigState, (state) => state.stockCountReviewList);
    static readonly assignFormConfig = createSelector(PageConfigState, (state) => state.assignConfig);
    static readonly detailsFormConfig = createSelector(PageConfigState, (state) => state.stockCountReviewDetail);
    static readonly overviewFormConfig = createSelector(PageConfigState, (state) => state.stockCountReviewOverview);
}
