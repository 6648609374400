import { createAction, props } from '@ngrx/store';
import { CommonListConfig } from '../page-configs.helper';

export class BinConfigurationActions {
    // Account List Configuration Actions
    static readonly loadListConfigs = createAction('[Bin Configuration List] Loading');
    static readonly loadListSuccess = createAction(
        '[Bin Configuration List] Success',
        props<{ data: CommonListConfig }>()
    );
    static readonly loadListError = createAction(
        '[Bin Configuration List] Error',
        props<{ error: any }>()
    );
}
