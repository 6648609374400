import { concatSlash } from '../../../core/helper/text';
import { FormConfig } from '../../../core/interfaces/form-config/basic-form';
import { TableColumn } from "../../../core/interfaces/dynamic-table";
import { CommonListConfig } from "../page-configs.helper";

const ROOT_PATH = '../../../../assets/json/action-history';
export const SEARCH_FORM = concatSlash([ROOT_PATH, 'action-search-form.json']);
export const MATERIAL_TABLE = concatSlash([ROOT_PATH, 'material-action-table.json']);
export const STORAGE_BIN_TABLE = concatSlash([ROOT_PATH, 'storage-bin-action-table.json']);
export const PRODUCT_TABLE = concatSlash([ROOT_PATH, 'soh-pid-action-table.json']);
export const DETAIL_FORM = concatSlash([ROOT_PATH, 'action-history-details-form.json']);
export const DETAIL_TABLE = concatSlash([ROOT_PATH, 'action-history-details-list-form.json']);
export const ConfigFeatureKey = 'actionHistoryConfig';

export interface ConfigState {
    searchConfig?: FormConfig;
    detailConfig?: CommonListConfig;
    materialTable?: TableColumn[];
    storageBinTable?: TableColumn[];
    productTable?: TableColumn[];
}
