import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { addYears, subYears } from 'date-fns';
import { CalendarModule } from 'primeng/calendar';
import { ControlBaseComponent } from '../../../../base/components/control.component';
import { CalendarConfig } from '../../../../interfaces/form-config/calendar';
import { TextModule } from '../text/text.module';

@Component({
    standalone: true,
    selector: 'app-calendar',
    template: `
        <ng-container
            *ngIf="_config?.readonly || mode === FormMode.Detail; else input">
            <app-text
                [config]="config"
                [control]="control"
                [customLabel]="control.value | date : 'YYYY-MM-dd'">
            </app-text>
        </ng-container>

        <ng-template #input>
            <div class="relative">
                <p-calendar
                    inputId="basic"
                    [placeholder]="_config?.placeholder || '' | translate"
                    [formControl]="control"
                    [view]="_config?.view || 'date'"
                    [minDate]="minDate || MINDATE"
                    [maxDate]="maxDate || MAXDATE"
                    [dataType]="_config?.dataType || 'date'"
                    [dateFormat]="_config?.dateFormat || 'mm/dd/yy'"
                    [selectionMode]="_config?.selectionMode || 'single'"
                    [appendTo]="_config?.appendTo || 'body'"
                    [ngClass]="{ 'font-bold': _config?.fontBold }">
                </p-calendar>

                <div class="calendar-icon">
                    <img
                        *ngIf="_config?.showIcon"
                        src="../../../../../assets/img/calendar.svg"
                        alt="" />
                </div>
            </div>
        </ng-template>
    `,
    styleUrls: ['./calendar.component.scss'],
    imports: [
        CommonModule,
        CalendarModule,
        TextModule,
        ReactiveFormsModule,
        TranslateModule,
    ],
})
export class CalendarComponent
    extends ControlBaseComponent<CalendarConfig>
    implements OnInit
{
    @Input() minDate!: Date;
    @Input() maxDate!: Date;

    MINDATE = subYears(new Date(), 10);
    MAXDATE = addYears(new Date(), 10);

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (this._config?.hasDefaultValue) this.control.setValue(new Date());
    }
}
