import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ConfigFeatureKey } from "./stock-count-review-config";
import { reducer } from "./stock-count-review-config.reducer";
import { StockCountReviewListEffects } from "./stock-count-review-config.effects";

@NgModule({
    imports: [StoreModule.forFeature(ConfigFeatureKey, reducer), EffectsModule.forFeature([StockCountReviewListEffects])]
})
export class StockCountReviewListConfigModule { }