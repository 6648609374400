import { createReducer, on } from "@ngrx/store";
import { MaterialMasterActions } from "./material-master-config.action";
import { ConfigState } from "./material-master-config";

export const reducer = createReducer(
    {} as ConfigState,
    on(MaterialMasterActions.loadMaterialListSuccess, (state, {data}) => ({
        ...state,
        materialList: data
    })),
    on(MaterialMasterActions.loadMaterialSyncSuccess, (state, {data}) => ({
        ...state,
        syncForm: data
    }))
);