export const DASHBOARD_ROUTES = {
    RegionalDashboard: 'regional',
    LocalDashboard: 'local'
};

export const LOCAL_DASHBOARD_ROUTES = {
    // CountAssignment: 'count-assignment',
    CountAssignment: '',
    CountingStatusPerBin: 'counting-status-per-bin',
    CountingStatusPerItem: 'counting-status-per-item',
    CountingStatusPerDoc: 'counting-status-per-doc'
};
