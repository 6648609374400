import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { DynamicControlModule } from './dynamic-control/dynamic-control.module';
import { DynamicFormComponent } from './dynamic-form.component';

@NgModule({
    declarations: [DynamicFormComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        DynamicControlModule,
    ],
    exports: [DynamicFormComponent, DynamicControlModule],
})
export class DynamicFormModule {}
