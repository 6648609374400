import { BehaviorSubject, Subject } from 'rxjs';
import { LANGUAGE } from '../../constant/language';
import { UserInfo } from '../../interfaces/auth-config/user-info';
import { PageError } from '../../interfaces/page-error';

export class GlobalVariables {
    static user: UserInfo = { token: '', profile: {}, markets: [] };
    static language = LANGUAGE.ENGLISH.sortLabel;
    static currentHttpStatus: PageError;

    static ready$ = new BehaviorSubject<Boolean>(false);
}
