import { FormConfig } from 'projects/ezstock/src/app/core/interfaces/form-config/basic-form';
import { concatSlash } from '../../../core/helper/text';
import { TableColumn } from '../../../core/interfaces/dynamic-table';
import { TabConfig } from '../../../core/interfaces/form-config/tab-view';
import { CommonListConfig } from '../page-configs.helper';

const ROOT_PATH = '../../../../../assets/json/dashboard';

export const LOCAL_DASHBOARD_TABS = concatSlash([
    ROOT_PATH,
    'local-dashboard-tabs.json',
]);
export const LOCAL_DASHBOARD_SEARCH_FORM = concatSlash([
    ROOT_PATH,
    'local-dashboard-search-form.json',
]);

/**
 * Count Document Status Dashboard
 */

export const COUNT_ASSIGNMENT_DASHBOARD_SEARCH_FORM = concatSlash([
    ROOT_PATH,
    'count-assignment-dashboard-search-form.json',
]);
export const COUNT_ASSIGNMENT_DASHBOARD_TABLE = concatSlash([
    ROOT_PATH,
    'count-assignment-dashboard-table.json',
]);

/**
 * Principal Count Results Dashboard
 */

export const PRINCIPAL_COUNT_RESULTS_SEARCH_FORM = concatSlash([
    ROOT_PATH,
    'principal-count-results-search-form.json',
]);
export const PRINCIPAL_COUNT_RESULTS_TABLE = concatSlash([
    ROOT_PATH,
    'principal-count-results-table.json',
]);

/**
 * Counting Status per Doc Dashboard
 */

export const COUNTING_STATUS_PER_DOC_STOCK_TABLE = concatSlash([
    ROOT_PATH,
    'couting-status-per-doc-stock-table.json',
]);
export const COUNTING_STATUS_PER_DOC_VARIANCE_TABLE = concatSlash([
    ROOT_PATH,
    'couting-status-per-doc-variance-table.json',
]);
export const COUNTING_STATUS_PER_DOC_FILTER_FORM = concatSlash([
    ROOT_PATH,
    'couting-status-per-doc-filter-form.json',
]);

export const REGIONAL_DASHBOARD_TABS = concatSlash([
    ROOT_PATH,
    'regional-dashboard-tabs.json',
]);
export const REGIONAL_DASHBOARD_CHART_FILTER_FORM = concatSlash([
    ROOT_PATH,
    'regional-dashboard-chart-filter-form.json',
]);

export const LOCATION_ACCURACY_DASHBOARD_SEARCH_FORM = concatSlash([
    ROOT_PATH,
    'location-accuracy-rate-search-form.json',
]);
export const LOCATION_ACCURACY_DASHBOARD_TABLE = concatSlash([
    ROOT_PATH,
    'location-accuracy-rate-table.json',
]);

export const ITEM_ACCURACY_SEARCH_FORM = concatSlash([
    ROOT_PATH,
    'item-accuracy-search-form.json',
]);
export const ITEM_ACCURACY_RATE_TABLE = concatSlash([
    ROOT_PATH,
    'item-accuracy-rate-table.json',
]);

export const STOCK_ACCURACY_GROSS_SEARCH_FORM = concatSlash([
    ROOT_PATH,
    'stock-accuracy-gross-search-form.json',
]);
export const STOCK_ACCURACY_GROSS_TABLE = concatSlash([
    ROOT_PATH,
    'stock-accuracy-gross-table.json',
]);

export const STOCK_ACCURACY_NET_SEARCH_FORM = concatSlash([
    ROOT_PATH,
    'stock-accuracy-net-search-form.json',
]);
export const STOCK_ACCURACY_NET_TABLE = concatSlash([
    ROOT_PATH,
    'stock-accuracy-net-table.json',
]);

export const STOCK_COUNTING_FREQUENCY_SEARCH_FORM = concatSlash([
    ROOT_PATH,
    'stock-counting-frequency-search-form.json',
]);
export const STOCK_COUNTING_FREQUENCY_TABLE = concatSlash([
    ROOT_PATH,
    'stock-counting-frequency-table.json',
]);

export const MARKET_PERFORMANCE_SEARCH_FORM = concatSlash([
    ROOT_PATH,
    'market-performance-search-form.json',
]);
export const MARKET_PERFORMANCE_TABLE = concatSlash([
    ROOT_PATH,
    'market-performance-table.json',
]);

export const ConfigFeatureKey = 'dashboardConfig';

export interface ConfigState {
    localDashboardConfig?: LocalDashboardConfig;
    countAssignmentConfig?: CountAssignmentConfig;
    countingStatusPerDocConfig?: CountingStatusPerDocConfig;
    principalCountResultsDashboardConfig?: PrincipalCountResultsDashboardConfig;

    regionalDashboardConfig?: RegionalDashboardConfig;
    locationAccuracyRateConfig?: LocationAccuracyRateConfig;
    itemAccuracyRateConfig?: ItemAccuracyRateConfig;
    stockAccuracyNetConfig?: StockAccuracyNetConfig;
    stockAccuracyGrossConfig?: StockAccuracyGrossConfig;
    stockCountingFrequencyConfig?: StockCountingFrequencyConfig;
    marketPerformanceConfig?: MarketPerformanceConfig;
}

/**
 *
 * Local dashboard
 */
export interface LocalDashboardConfig {
    tabViewConfig: TabConfig[];
    searchConfig: FormConfig;
}

export interface CountAssignmentConfig extends CommonListConfig {}

export interface CountingStatusPerDocConfig {
    stockTableConfig: TableColumn[];
    varianceTableConfig: TableColumn[];
    tableFilterConfig: FormConfig;
}

export interface PrincipalCountResultsDashboardConfig
    extends CommonListConfig {}

/**
 *
 * Regional dashboard
 */
export interface RegionalDashboardConfig {
    tabViewConfig: TabConfig[];
}

export interface LocationAccuracyRateConfig extends CommonListConfig {
    chartFilterConfig: FormConfig;
}

export interface ItemAccuracyRateConfig extends CommonListConfig {
    chartFilterConfig: FormConfig;
}

export interface StockAccuracyGrossConfig extends CommonListConfig {
    chartFilterConfig: FormConfig;
}

export interface StockAccuracyNetConfig extends CommonListConfig {
    chartFilterConfig: FormConfig;
}

export interface StockCountingFrequencyConfig extends CommonListConfig {}

export interface MarketPerformanceConfig extends CommonListConfig {}
