import { uniq } from 'lodash';

import { UploadTemplateType } from '../enum/common';
import { DeleteConfig, UploadConfig, UploadParams } from './definitions-upload';

export class UploaderContext {
    public uploadList: Array<FileItem> = []; // Only used to show files to
    public finalFileList: Array<UploadedFile> = [];
    public allowedTypes = [
        'csv',
        '7z',
        'rar',
        'gz',
        'z',
        'zip',
        'ppt',
        'pptx',
        'xls',
        'xlsx',
        'doc',
        'docx',
        'pdf',
        'txt',
        'jpeg',
        'JPEG',
        'jpg',
        'JPG',
        'png',
        'PNG',
        'tif',
        'tiff',
        'pst',
        'ost',
        'gif',
        'msg'
    ];
    public maximumSize; // need to defined in constructor if maximumSize !== default maximumSize (15 * this.kbPerMb * this.kbPerMb)
    public maximumSizeMB;
    public kbPerMb = 1024;
    public maximumFiles = 100;
    public uploadConfig?: UploadConfig;
    public deleteConfig?: DeleteConfig;
    public uploadTemplateType?: UploadTemplateType;
    public azureStorePlace?: string;
    public apiStoringType: UploadTemplateType;
    public isShowMessageError = false;
    public config: any = {};
    public inlineErrorMessage?: string;
    public readOnly: boolean;
    public totalUploadBytes: number = 0;
    public totalUploadedBytes: number = 0;
    public isMultipleFiles = true;
    public instruction = '';
    public concurrencyId?: string;
    public isCanDownloadAll = true;

    constructor(_readOnly = false, _apiStoringType?: any, _maximumSize = 15) {
        this.readOnly = _readOnly;
        this.apiStoringType = _apiStoringType;
        this.maximumSize = _maximumSize * this.kbPerMb * this.kbPerMb;
        this.maximumSizeMB = _maximumSize;
        // build tooltip instruction
        let allowedTypesLowerCase: any[] = [];
        this.instruction = 'Supported file formats: ';
        this.allowedTypes.forEach((element) => {
            allowedTypesLowerCase.push(element.toLowerCase());
        });
        allowedTypesLowerCase = uniq(allowedTypesLowerCase);
        allowedTypesLowerCase.forEach((element, index) => {
            if (index === 0) {
                this.instruction += `${element}`;
            } else if (index !== 0 && index < allowedTypesLowerCase.length - 1) {
                this.instruction += `, ${element}`;
            } else {
                this.instruction += ` and ${element}. Maximum file size: ${
                    this.maximumSize / this.kbPerMb / this.kbPerMb
                }MB.`;
            }
        });
    }
    public GetClassName() {
        return this.constructor.name;
    }
}

export class FileItem {
    id?: number;
    fileItem: any;
    originalName?: string;
    uploadName?: string;
    ext?: string;
    location?: string;
    percent?: number;
    size?: number;
    bVersionFile?: boolean;
    bExpanded?: boolean;
    version?: string;
    fileVersion?: number;
    parentId?: string;
    isAllowDelete?: boolean = true;
    uploadedBy?: string;
    uploadedDate?: string;
}

export class UploadedFile {
    id?: number;
    originalFileName?: string;
    storagesFileName?: string;
    storagesFolderPath?: string;
    fileExtension?: string;
    fileSize?: number;
}

export const ConfigInfo: UploadParams = {
    sas: '',
    containerName: '',
    uri: ''
};

export class UploadFileDocument {
    fileExtension?: string;
    id?: number;
    originalFileName?: string;
}

export class DownloadConfig {
    moduleRecordId?: number;
    fileVersion?: number;
    moduleStorage?: number;
}
