import { createAction, props } from '@ngrx/store';
import { FormConfig } from '../../../core/interfaces/form-config/basic-form';
import { CommonListConfig } from '../page-configs.helper';
import { AccountInfoConfig } from './account-config';

export class AccountActions {
    // Account List Configuration Actions
    static readonly loadAccountListConfigs = createAction('[Account List] Loading');
    static readonly loadAccountListSuccess = createAction(
        '[Account List] Success',
        props<{ data: CommonListConfig }>()
    );
    static readonly loadAccountListError = createAction(
        '[Account List] Error',
        props<{ error: any }>()
    );

    // Account Info Configuration Actions
    static readonly loadAccountInfoConfigs = createAction('[Account Info] Loading');
    static readonly loadAccountInfoSuccess = createAction(
        '[Account Info] Success',
        props<{ data: AccountInfoConfig }>()
    );
    static readonly loadAccountInfoError = createAction(
        '[Account Info] Error',
        props<{ error: any }>()
    );

    static readonly loadRoleSidebarConfigs = createAction('[Role Sidebar] Loading');
    static readonly loadRoleSidebarSuccess = createAction(
        '[Role Sidebar] Success',
        props<{ data: FormConfig }>()
    );
    static readonly loadRoleSidebarError = createAction(
        '[Role Sidebar] Error',
        props<{ error: any }>()
    );
}
