import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { delay, Observable, of, tap } from 'rxjs';
import { LoadingService } from './core/components/loading/loading.service';

@Injectable({ providedIn: 'root' })
export class AppResolver implements Resolve<any> {
    constructor(private loadingService: LoadingService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        return of({}).pipe(
            // delay(1400),
            tap(() => this.loadingService.hide())
        );
    }
}
