import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, of, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CoreHttpService } from '../../../core/services/common/json.service';
import { getFormConfig, getTableConfig } from '../../page-configs/page-configs.helper';
import { ROLE_PERMISSION_TABLE, ROLE_SEARCH_FORM, ROLE_TABLE } from './permission-config';
import { RoleActions } from './permission-config.actions';
import { RoleSelectors } from './permission-config.selectors';

@Injectable()
export class RoleEffects {
    roleListConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RoleActions.loadRoleList),
            withLatestFrom(this.store.select(RoleSelectors.roleListConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(ROLE_SEARCH_FORM, this._http),
                    getTableConfig(ROLE_TABLE, this._http)
                ]).pipe(
                    map(([searchFormConfig, tableConfig]) =>
                        RoleActions.loadRoleListSuccess({
                            data: { searchConfig: searchFormConfig, tableConfig }
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    rolePermissionsConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RoleActions.loadRolePermissions),
            withLatestFrom(this.store.select(RoleSelectors.rolePermissionsConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return getTableConfig(ROLE_PERMISSION_TABLE, this._http).pipe(
                    map((res) => RoleActions.loadRolePermissionsSuccess({ data: res })),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    constructor(private actions$: Actions, private _http: CoreHttpService, private store: Store) {}
}
