import { concatSlash } from "../../../core/helper/text";
import { CommonListConfig } from "../page-configs.helper";

export const ConfigFeatureKey = 'documentInfoConfig';

const rootPath = '../../../../assets/json/document';

export const DOCUMENT_INFO_SEARCH_FORM = concatSlash([rootPath, 'document-info-search-form.json']);
export const DOCUMENT_INFO_TABLE = concatSlash([rootPath, 'document-info-table.json']);

export interface ConfigState {
    documentInfo?: CommonListConfig;
}
