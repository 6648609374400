import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { LANGUAGE } from './core/constant/language';
import { AuthService } from './core/services/auth/auth.service';
import { StorageService } from './core/services/common/storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        public _auth: AuthService,
        private _translate: TranslateService,
        private _primeNG: PrimeNGConfig
    ) {
        this._auth.checkAuth();
        this._translate.setDefaultLang(LANGUAGE.ENGLISH.sortLabel);
    }

    ngOnInit(): void {
        this._primeNG.ripple = true;
        window.addEventListener('storage', this.onStorageChange, false);
        window.addEventListener('resize', this.appHeight);
        this.appHeight();
    }

    appHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    }

    private onStorageChange = (e: any) => {
        // when any tab is sign out => rest of tabs will be signed out
        if (StorageService.get(StorageService.KEY_IS_LOGGED_OUT) === '1') {
            // emit call function request SSO logout
            this._auth.signOut();
        }
    };
}
