import { createAction, props } from '@ngrx/store';
import {
    CountAssignmentConfig,
    CountingStatusPerDocConfig,
    ItemAccuracyRateConfig,
    LocalDashboardConfig,
    LocationAccuracyRateConfig,
    MarketPerformanceConfig,
    PrincipalCountResultsDashboardConfig,
    RegionalDashboardConfig,
    StockAccuracyGrossConfig,
    StockAccuracyNetConfig,
    StockCountingFrequencyConfig,
} from './dashboard-config';

export class DashboardActions {
    // Local Dashboard Configuration Actions
    static readonly loadLocalDashboardConfigs = createAction(
        '[Local Dashboard] Loading'
    );
    static readonly loadLocalDashboardSuccess = createAction(
        '[Local Dashboard] Success',
        props<{ data: LocalDashboardConfig }>()
    );

    static readonly loadCountAssignmentConfigs = createAction(
        '[Count Asignment Dashboard] Loading'
    );
    static readonly loadCountAssignmentSuccess = createAction(
        '[Count Asignment Dashboard] Success',
        props<{ data: CountAssignmentConfig }>()
    );

    static readonly loadCountingStatusPerDocConfigs = createAction(
        '[Counting Status Per Doc Dashboard] Loading'
    );
    static readonly loadCountingStatusPerDocSuccess = createAction(
        '[Counting Status Per Doc Dashboard] Success',
        props<{ data: CountingStatusPerDocConfig }>()
    );

    static readonly loadPrincipalCountResultsDashboardConfig = createAction(
        '[Principal Count Results Dashboard] Loading'
    );
    static readonly loadPrincipalCountResultsDashboardSuccess = createAction(
        '[Principal Count Results Dashboard] Success',
        props<{ data: PrincipalCountResultsDashboardConfig }>()
    );

    // Regional Dashboard Configuration Actions
    static readonly loadRegionalDashboardConfigs = createAction(
        '[Regional Dashboard] Loading'
    );
    static readonly loadRegionalDashboardSuccess = createAction(
        '[Regional Dashboard] Success',
        props<{ data: RegionalDashboardConfig }>()
    );

    static readonly loadLocationAccuracyDashboardConfigs = createAction(
        '[Location Accuracy Rate Dashboard] Loading'
    );
    static readonly loadLocationAccuracyDashboardSuccess = createAction(
        '[Location Accuracy Rate Dashboard] Success',
        props<{ data: LocationAccuracyRateConfig }>()
    );

    static readonly loadItemAccuracyRateConfigs = createAction(
        '[Regional WH Item Accuracy Rate] Loading'
    );
    static readonly loadItemAccuracyRateSuccess = createAction(
        '[Regional WH Item Accuracy Rate] Success',
        props<{ data: ItemAccuracyRateConfig }>()
    );

    static readonly loadStockAccuracyNetConfigs = createAction(
        '[Regional WH Stock Accuracy Level - Net Basis] Loading'
    );
    static readonly loadStockAccuracyNetSuccess = createAction(
        '[Regional WH Stock Accuracy Level - Net Basis] Success',
        props<{ data: StockAccuracyNetConfig }>()
    );

    static readonly loadStockAccuracyGrossConfigs = createAction(
        '[Regional WH Stock Accuracy Level - Gross Basis] Loading'
    );
    static readonly loadStockAccuracyGrossSuccess = createAction(
        '[Regional WH Stock Accuracy Level - Gross Basis] Success',
        props<{ data: StockAccuracyGrossConfig }>()
    );

    static readonly loadStockCountingFrequencyConfigs = createAction(
        '[Regional WH Stock Counting Frequency] Loading'
    );
    static readonly loadStockCountingFrequencySuccess = createAction(
        '[Regional WH Stock Counting Frequency] Success',
        props<{ data: StockCountingFrequencyConfig }>()
    );

    static readonly loadMarketPerformanceConfigs = createAction(
        '[Regional Market Performance] Loading'
    );
    static readonly loadMarketPerformanceSuccess = createAction(
        '[Regional Market Performance] Success',
        props<{ data: MarketPerformanceConfig }>()
    );
}
