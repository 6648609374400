import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, forkJoin, map, of, withLatestFrom } from 'rxjs';
import { CoreHttpService } from '../../../core/services/common/json.service';
import { getFormConfig, getTableConfig } from '../page-configs.helper';
import { DocumentInfoActions } from "./document-info-config.actions";
import { DocumentInfoSelectors } from "./document-info-config.selectors";
import { DOCUMENT_INFO_SEARCH_FORM, DOCUMENT_INFO_TABLE } from "./document-info-config";
import { switchMap } from "rxjs/operators";

@Injectable()
export class DocumentInfoEffects {
    documentInfoConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DocumentInfoActions.loadDocumentInfo),
            withLatestFrom(this.store.select(DocumentInfoSelectors.documentInfo)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(DOCUMENT_INFO_SEARCH_FORM, this._http),
                    getTableConfig(DOCUMENT_INFO_TABLE, this._http)]
                ).pipe(
                    map(([searchFormConfig, tableConfig]) =>
                        DocumentInfoActions.loadDocumentInfoSuccess({
                            data: {searchConfig: searchFormConfig, tableConfig}
                        })
                    ),
                    catchError((error) => EMPTY)
                );
            })
        );
    });

    constructor(private actions$: Actions, private _http: CoreHttpService, private store: Store) {
    }
}
