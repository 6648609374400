import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, of, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CoreHttpService } from '../../../core/services/common/json.service';
import { getFormConfig, getTableConfig } from '../page-configs.helper';
import {
    BIN_ASSIGN_FORM,
    BIN_SCAN_FORM,
    BIN_SEARCH_FORM,
    BIN_TABLE,
    PRODUCT_BIN_FORM,
    PRODUCT_FORM,
    PRODUCT_SEARCH_FORM,
    PRODUCT_TABLE
} from './stock-count-config';
import { StockCountActions } from './stock-count-config.action';
import { StockCountSelector } from './stock-count-config.selector';

@Injectable()
export class StockCountEffects {
    constructor(private actions$: Actions, private _http: CoreHttpService, private store: Store) {}

    binListConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(StockCountActions.loadBinListConfigs),
            withLatestFrom(this.store.select(StockCountSelector.binListConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(BIN_SEARCH_FORM, this._http),
                    getTableConfig(BIN_TABLE, this._http)
                ]).pipe(
                    map(([searchConfig, tableConfig]) =>
                        StockCountActions.loadBinListSuccess({
                            data: { searchConfig, tableConfig }
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    binScanDialogConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(StockCountActions.loadBinScanDialogConfigs),
            withLatestFrom(this.store.select(StockCountSelector.binScanDialogConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return getFormConfig(BIN_SCAN_FORM, this._http).pipe(
                    map((binScanForm) =>
                        StockCountActions.loadBinScanDialogConfigsSuccess({
                            data: binScanForm
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    assignDialogConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(StockCountActions.loadAssignDialogConfigs),
            withLatestFrom(this.store.select(StockCountSelector.assignDialogConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return getFormConfig(BIN_ASSIGN_FORM, this._http).pipe(
                    map((assignFormConfig) =>
                        StockCountActions.loadAssignDialogConfigsSuccess({
                            data: assignFormConfig
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    productFormConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(StockCountActions.loadProductFormConfigs),
            withLatestFrom(this.store.select(StockCountSelector.productFormConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return getFormConfig(PRODUCT_FORM, this._http).pipe(
                    map((productFormConfig) =>
                        StockCountActions.loadProductFormConfigsSuccess({
                            data: productFormConfig
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    productListConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(StockCountActions.loadProductListConfigs),
            withLatestFrom(this.store.select(StockCountSelector.productListConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(PRODUCT_BIN_FORM, this._http),
                    getFormConfig(PRODUCT_SEARCH_FORM, this._http),
                    getTableConfig(PRODUCT_TABLE, this._http)
                ]).pipe(
                    map(([binFormConfig, searchConfig, tableConfig]) =>
                        StockCountActions.loadProductListConfigSuccess({
                            data: { binFormConfig, searchConfig, tableConfig }
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });
}
