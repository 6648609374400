import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first, size } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { DialogType } from '../../constant/message';
import { IMessageItem, MessageType } from '../../interfaces/message';
import * as Model from '../../models/message';

@Injectable({ providedIn: 'root' })
export class MessageDialogService {
    private messageSource = new BehaviorSubject<IMessageItem>(undefined!);

    message$: Observable<IMessageItem> = this.messageSource.asObservable();

    constructor(private injector: Injector) {}

    /**
     * show confirmation message dialog
     */
    show(param: IMessageItem, _type: MessageType) {
        const { message, variables, action, actions, header, styleClass } = param;
        // translate content
        let _message = this.injector.get(TranslateService).instant(message || '');

        // replace variables
        if (variables) {
            _message = sprintf(_message || '', ...variables);
        }

        const btnCancel = new Model.MessageButtonCancel(action?.reject);
        const btnOk = new Model.MessageButtonOk(action?.accept);
        const btnNo = new Model.MessageButtonNo(action?.reject);
        const btnYes = new Model.MessageButtonYes(action?.accept);
        const btnClose = new Model.MessageButtonClose(action?.close);

        let messageItem: Model.MessageItem;

        switch (_type) {
            case DialogType.Confirm:
                messageItem = new Model.MessageConfirm(_message, [btnYes, btnNo]);
                break;
            case DialogType.Delete:
                messageItem = new Model.MessageDelete(_message, [btnOk, btnCancel]);
                break;
            case DialogType.Success:
                messageItem = new Model.MessageSuccess(_message, [btnOk]);
                break;
            case DialogType.Warning:
                messageItem = new Model.MessageWarning(_message, [btnYes, btnNo]);
                break;
            case DialogType.Error:
                messageItem = new Model.MessageError(_message, [btnOk]);
                break;
            case DialogType.Close:
                messageItem = new Model.MessageClose(_message, [btnYes, btnNo, btnClose]);
                break;
            default:
                messageItem = new Model.MessageItem(_message, header, styleClass, actions);
                break;
        }

        this.messageSource.next(messageItem);
    }

    /**
     * close message dialog
     */
    close() {
        this.messageSource.next(undefined!);
    }

    /**
     * show first error from BE
     * @param errorList
     */
    showFirst(errorList: any[]) {
        if (size(errorList) > 0) this.show({ message: first(errorList)?.code }, 'error');
    }
}
