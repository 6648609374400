import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControlBaseComponent } from '../../../../base/components/control.component';
import { LENGTH } from '../../../../constant/control-config';
import { NumberFormat } from '../../../../enum/common';
import { InputNumberConfig } from '../../../../interfaces/form-config';
@Component({
    selector: 'app-number',
    templateUrl: './number.component.html',
    styleUrls: ['./number.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberComponent extends ControlBaseComponent<InputNumberConfig> implements OnInit {
    LENGTH = LENGTH;
    DEFAULT_MIN = NumberFormat.DefaultMinValue;
    DEFAULT_MAX = NumberFormat.DefaultMaxValue;
    DEFAULT_MIN_DECIMAL_PLACES = NumberFormat.DefaultMinDecimalPlaces;
    DEFAULT_MAX_DECIMAL_PLACES = NumberFormat.DefaultMaxDecimalPlaces;

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
