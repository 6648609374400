<app-dynamic-control
    [ngClass]="{submit}"
    #controls
    [mode]="mode || (mode$ | async) || 'detail'"
    [value]="value || config.value"
    [options]="options || config.options"
    [config]="config"
    [submit]="submit"
    (ready)="ready.emit($event)"
    (inputActionClick)="inputActionClick.emit($event)">
</app-dynamic-control>
