import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, of, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CoreHttpService } from '../../../core/services/common/json.service';
import { getFormConfig, getTableConfig } from '../page-configs.helper';
import {
    EXTERNAL_CRUD_FORM,
    EXTERNAL_ROLE_ASSIGN_FORM,
    EXTERNAL_ROLE_DETAILS_FORM,
    EXTERNAL_ROLE_TABLE,
    EXTERNAL_SEARCH_FORM,
    EXTERNAL_TABLE
} from './external-config';
import { ExternalActions } from './external-config.action';
import { ExternalSelector } from './external-config.selector';

@Injectable()
export class ExternalEffects {
    constructor(private actions$: Actions, private _http: CoreHttpService, private store: Store) {}

    // Get Account List config
    externalAccountListConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalActions.loadExternalListConfigs),
            withLatestFrom(this.store.select(ExternalSelector.externalAccountListConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(EXTERNAL_SEARCH_FORM, this._http),
                    getTableConfig(EXTERNAL_TABLE, this._http)
                ]).pipe(
                    map(([searchFormConfig, tableConfig]) =>
                        ExternalActions.loadExternalListSuccess({
                            data: { searchConfig: searchFormConfig, tableConfig }
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    externalAccountInfoConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalActions.loadExternalInfoConfigs),
            withLatestFrom(this.store.select(ExternalSelector.externalAccountInfoConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(EXTERNAL_CRUD_FORM, this._http),
                    getFormConfig(EXTERNAL_ROLE_ASSIGN_FORM, this._http),
                    getTableConfig(EXTERNAL_ROLE_TABLE, this._http)
                ]).pipe(
                    map(([formConfig, roleFormConfig, roleTableConfig]) =>
                        ExternalActions.loadExternalInfoSuccess({
                            data: { formConfig, roleFormConfig, roleTableConfig }
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    roleSidebarConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalActions.loadRoleSidebarConfigs),
            withLatestFrom(this.store.select(ExternalSelector.roleSidebarConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return getFormConfig(EXTERNAL_ROLE_DETAILS_FORM, this._http).pipe(
                    map((formConfig) =>
                        ExternalActions.loadRoleSidebarSuccess({
                            data: formConfig
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });
}
