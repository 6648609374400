import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { lastValueFrom } from 'rxjs';
import { GlobalVariables } from '../../base/variable/global-variable';
import { FormMode } from '../../constant/form';
import { PermissionDictionary } from '../../constant/permission-dictionary';
import { UserInfo, UserMarket, UserProfile } from '../../interfaces/auth-config/user-info';
import { PermissionToken, UserToken } from '../../interfaces/auth-config/user-token';
import { StorageService } from '../common/storage.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(private _oidc: OidcSecurityService) {}

    signIn = () => this._oidc.authorize();

    signOut() {
        StorageService.add(StorageService.KEY_IS_LOGGED_OUT, '1');
        this._oidc.logoff();
    }

    async checkAuth() {
        const user = GlobalVariables.user;
        const ready$ = GlobalVariables.ready$;

        ready$.next(false);

        const auth = await lastValueFrom(this._oidc.checkAuth());

        if (auth.isAuthenticated && auth.userData) {
            StorageService.delete(StorageService.KEY_IS_LOGGED_OUT);

            this._oidc.getAccessToken().subscribe((res: string) => {
                user.profile = this.mapProfile(auth.userData);
                user.markets = this.mapPermissions(auth.userData);
                user.token = res;
                user.profile.activeMarket = this.getActiveMarket(user);
                ready$?.next(true);
            });
        } else ready$.next(true);
    }

    /**
     *
     * Re-maps tokenized profile info into a more readable form.
     * @param token
     * @returns
     */
    private mapProfile(token: UserToken): UserProfile {
        return {
            uid: Number(token.uid),
            username: token.usn || '',
            employeeName: token.epl || '',
            externalAccount: token.ext || '',
            isSystemAdmin: token.ad === 'True',
        };
    }

    /**
     *
     * Re-maps tokenized permissions into a more readable form.
     * @param token
     * @returns
     */
    private mapPermissions(token: UserToken): UserMarket[] {
        if (!token.mp) return [];

        const marketPermissions = JSON.parse(token.mp) as PermissionToken[];

        return marketPermissions.map((m) => {
            return {
                info: { id: Number(m.mid), code: m.mc || '' },
                roles: m.r,
                funcs: Object.keys(m.fp).map((func) => ({ func, perms: m.fp[func].split('') })),
            };
        });
    }

    private getActiveMarket(user: UserInfo) {
        if (!user.markets.length) return null;

        // Checks if the user already has an active market saved in Local Storage.
        const savedMarket = StorageService.get(StorageService.KEY_ACTIVE_MARKET) as any;
        if (savedMarket.uid === user.profile.uid) return savedMarket;
        else StorageService.delete(StorageService.KEY_ACTIVE_MARKET);

        // Falls back to the first available market assigned to the user.
        const defaultMarket = user.markets[0].info;
        StorageService.add(StorageService.KEY_ACTIVE_MARKET, {
            uid: user.profile.uid,
            ...defaultMarket,
        });
        return defaultMarket;
    }

    /**
     *
     * @param url Target's url.
     * @param mode Target's mode (create/detail/update).
     * @returns
     */
    isAccessAllowed(url: string, mode?: string): boolean {
        const target = PermissionDictionary.find((item) => item.url === url);
        if (!target) return false;

        const user: UserInfo = GlobalVariables.user;
        const userMarket = user.profile.isSystemAdmin
            ? user.markets[0]
            : user.markets.find((m) => m.info.id === user.profile.activeMarket?.id);

        /**
         * Check whether the target url is role-restricted
         * and whether the user has the required role to access it.
         */
        const hasRole = target.roles ? userMarket?.roles?.some((r) => target.roles?.includes(r)) : true;

        if (!hasRole) return false;

        /**
         * Check whether the target function is enabled for the user.
         */
        const requiredFunc = userMarket?.funcs?.find((f) => f.func === target.function);

        if (!requiredFunc) return false;

        /**
         * Check for the target url's mode-specific permissions, if any.
         * Then check for its common/general permissions.
         * If it has no required permissions (undefined), or the user has the required permissions, pass.
         * Otherwise, block.
         */
        if (mode) {
            const targetMode = target.modes?.find((m) => m.name === (mode ?? FormMode.Detail));
            const hasModePerm = targetMode ? targetMode?.permissions.some((p) => requiredFunc.perms.includes(p)) : true;
            if (!hasModePerm) return false;
        }

        const hasPerm = target.permissions ? target.permissions?.some((p) => requiredFunc.perms.includes(p)) : true;
        if (!hasPerm) return false;

        return true;
    }
}
