/**
 * Data Upload
 */
export const DataUploadRoutes = {
    InventoryStock: 'inventory-stock',
    InventoryDocument: 'inventory-documents',
    BinMaster: 'bin-master',
    MaterialMaster: 'material-master',
    ActionHistory: 'action-history'
};

/**
 * Data Upload child route
 * SAP Bin Master
 */
export const BinMasterRoutes = {
    List: ''
};

/**
 * Data Upload child route
 * SAP Material Master
 */
export const MaterialMasterRoutes = {
    List: ''
};

/**
 * Data Upload child route
 * SAP Inventory Documents
 */
export const InventoryDocumentRoutes = {
    List: '',
    InventoryDocumentInfo: 'document-info'
};

/**
 * Data Upload child route
 * SAP Inventory SOH
 */
export const INVENTORY_STOCK_ROUTES = {
    List: '',
    InventoryStockInfo: 'stock-info',
    InventoryStockSync: 'stock-sync'
};

/**
 * Stock Counting
 */
export const STOCK_COUNT_ROUTES = {
    BinList: '',
    ProductList: 'product-list',
    ProductInsert: 'product-insert'
};

/**
 * Stock Count Review
 */
export const STOCK_COUNT_REVIEW_ROUTES = {
    List: ''
};

export const ACTION_HISTORY_ROUTES = {
    List: ''
};
