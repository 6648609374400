<ng-container *ngIf="_config?.readonly || mode === FormMode.Detail; else input">
    <app-text [config]="config" [control]="control"></app-text>
</ng-container>

<ng-template #input>
    <input
        appNumber
        pInputText
        [type]="'text'"
        inputmode="numeric"
        [ngClass]="{
            'font-bold': _config?.fontBold,
            'border-none': _config?.noBorder,
            shadow: _config?.shadow
        }"
        [formControl]="control"
        [control]="control"
        [min]="_config?.min || DEFAULT_MIN"
        [max]="_config?.max || DEFAULT_MAX"
        [minFractionDigits]="_config?.minFractionDigits || DEFAULT_MIN_DECIMAL_PLACES"
        [maxFractionDigits]="_config?.maxFractionDigits || DEFAULT_MAX_DECIMAL_PLACES"
        [maxlength]="_config?.maxLength || LENGTH.SINGLE_LINE"
        [placeholder]="_config?.placeholder || '' | translate"
    />
</ng-template>
