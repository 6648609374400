import { DASHBOARD_ROUTES } from '../../routers/dashboard.routes';
import { STOCK_COUNT_ROUTES } from '../../routers/data-upload.routes';
import { LAYOUT_ROUTES } from '../../routers/layout.routes';
import { FormMode } from './form';
import { FUNCTION_CODE, PERMISSION_CODE } from './permission-function-code';
import { USER_GROUP } from './user-group';

export const PermissionDictionary: Function[] = [
    // CALENDAR
    {
        url: '/calendar',
        function: FUNCTION_CODE.CALENDAR,
        permissions: [PERMISSION_CODE.READ],
    },

    // DASHBOARD
    {
        url: `/${LAYOUT_ROUTES.Dashboard}`,
        function: FUNCTION_CODE.DASHBOARD,
        permissions: [PERMISSION_CODE.READ],
    },
    {
        url: `/${LAYOUT_ROUTES.Dashboard}/${DASHBOARD_ROUTES.RegionalDashboard}`,
        function: FUNCTION_CODE.DASHBOARD,
        permissions: [PERMISSION_CODE.READ],
    },
    {
        url: `/${LAYOUT_ROUTES.Dashboard}/${DASHBOARD_ROUTES.LocalDashboard}`,
        function: FUNCTION_CODE.DASHBOARD,
        permissions: [PERMISSION_CODE.READ],
    },

    // STOCK COUNTING
    {
        url: `/${LAYOUT_ROUTES.StockCount}`,
        function: FUNCTION_CODE.STOCK_COUNTING,
        permissions: [PERMISSION_CODE.READ],
    },
    {
        url: `/${LAYOUT_ROUTES.StockCount}/${STOCK_COUNT_ROUTES.ProductList}`,
        function: FUNCTION_CODE.STOCK_COUNTING,
        modes: [
            { name: FormMode.Detail, permissions: [PERMISSION_CODE.READ] },
            { name: FormMode.Edit, permissions: [PERMISSION_CODE.UPDATE] },
        ],
    },
    {
        url: `/${LAYOUT_ROUTES.StockCount}/${STOCK_COUNT_ROUTES.ProductInsert}`,
        function: FUNCTION_CODE.STOCK_COUNTING,
        permissions: [PERMISSION_CODE.CREATE],
    },

    // CONTROL CENTER > SAP MATERIAL MASTER
    {
        url: '/control-center/data-upload/material-master',
        function: FUNCTION_CODE.SAP_MATERIAL_MASTER,
        permissions: [PERMISSION_CODE.READ],
    },

    // CONTROL CENTER > SAP BIN MASTER
    {
        url: '/control-center/data-upload/bin-master',
        function: FUNCTION_CODE.SAP_BIN_MASTER,
        permissions: [PERMISSION_CODE.READ],
    },

    // CONTROL CENTER > SAP STOCK ON HAND
    {
        url: '/control-center/data-upload/inventory-stock',
        function: FUNCTION_CODE.SAP_STOCK_ON_HAND,
        permissions: [PERMISSION_CODE.READ],
    },
    {
        url: '/control-center/data-upload/inventory-stock/stock-info',
        function: FUNCTION_CODE.SAP_STOCK_ON_HAND,
        modes: [{ name: FormMode.Detail, permissions: [PERMISSION_CODE.READ] }],
    },
    {
        url: '/control-center/data-upload/inventory-stock/stock-sync',
        function: FUNCTION_CODE.SAP_STOCK_ON_HAND,
        permissions: [PERMISSION_CODE.READ],
    },

    // CONTROL CENTER > SAP INVENTORY DOC
    {
        url: '/control-center/data-upload/inventory-documents',
        function: FUNCTION_CODE.SAP_INVENTORY_DOC,
        permissions: [PERMISSION_CODE.READ],
    },
    {
        url: '/control-center/data-upload/inventory-documents/document-info',
        function: FUNCTION_CODE.SAP_INVENTORY_DOC,
        modes: [{ name: FormMode.Detail, permissions: [PERMISSION_CODE.READ] }],
    },

    // CONTROL CENTER > APLI_SAP_INVENTORY_DOC
    {
        url: '/control-center/data-upload/apli-documents',
        function: FUNCTION_CODE.APLI_SAP_INVENTORY_DOC,
        permissions: [PERMISSION_CODE.READ],
    },

    // CONTROL CENTER > ACTION HISTORY
    {
        url: '/control-center/data-upload/action-history',
        function: FUNCTION_CODE.SAP_BIN_MASTER,
        permissions: [PERMISSION_CODE.READ],
    },
    {
        url: '/control-center/data-upload/action-history',
        function: FUNCTION_CODE.SAP_BIN_MASTER,
        permissions: [PERMISSION_CODE.READ],
    },

    // CONTROL CENTER > STOCK COUNT PLAN
    {
        url: '/control-center/count-manager/plan',
        function: FUNCTION_CODE.STOCK_COUNT_PLAN,
        permissions: [PERMISSION_CODE.READ],
    },
    {
        url: '/control-center/count-manager/plan/plan-info',
        function: FUNCTION_CODE.STOCK_COUNT_PLAN,
        modes: [
            { name: FormMode.Create, permissions: [PERMISSION_CODE.CREATE] },
            { name: FormMode.Detail, permissions: [PERMISSION_CODE.READ] },
            { name: FormMode.Edit, permissions: [PERMISSION_CODE.UPDATE] },
        ],
    },

    // CONTROL CENTER > TEAM MANAGEMENT
    {
        url: '/control-center/count-manager/team',
        function: FUNCTION_CODE.TEAM_ASSIGNMENT,
        permissions: [PERMISSION_CODE.READ],
    },
    {
        url: '/control-center/count-manager/team/team-info',
        function: FUNCTION_CODE.TEAM_ASSIGNMENT,
        modes: [
            { name: FormMode.Create, permissions: [PERMISSION_CODE.CREATE] },
            { name: FormMode.Detail, permissions: [PERMISSION_CODE.READ] },
            { name: FormMode.Edit, permissions: [PERMISSION_CODE.UPDATE] },
        ],
    },

    // CONTROL CENTER > TEAM MEMBER MANAGEMENT
    {
        url: '/control-center/count-manager/team-member',
        function: FUNCTION_CODE.TEAM_ASSIGNMENT,
        permissions: [PERMISSION_CODE.READ],
    },
    {
        function: FUNCTION_CODE.TEAM_ASSIGNMENT,
        url: '/control-center/count-manager/team-member/member-info',
        modes: [
            { name: FormMode.Create, permissions: [PERMISSION_CODE.CREATE] },
            { name: FormMode.Detail, permissions: [PERMISSION_CODE.READ] },
            { name: FormMode.Edit, permissions: [PERMISSION_CODE.UPDATE] },
        ],
    },

    // CONTROL CENTER > COUNT ASSIGNMENT
    {
        url: '/control-center/count-manager/count-assignment',
        function: FUNCTION_CODE.COUNT_ASSIGNMENT,
        permissions: [PERMISSION_CODE.READ],
    },
    {
        url: '/control-center/count-manager/count-assignment/compare',
        function: FUNCTION_CODE.COUNT_ASSIGNMENT,
        permissions: [PERMISSION_CODE.READ],
    },

    // CONTROL CENTER > STOCK COUNT REVIEW
    {
        url: '/control-center/stock-count-review',
        function: FUNCTION_CODE.STOCK_COUNT_REVIEW,
        permissions: [PERMISSION_CODE.READ],
    },

    // HELP DESK MESSAGES
    {
        url: '/messages',
        function: FUNCTION_CODE.HELP_DESK_MESSAGES,
        permissions: [PERMISSION_CODE.READ],
    },
    {
        url: '/messages/create',
        function: FUNCTION_CODE.HELP_DESK_MESSAGES,
        modes: [{ name: FormMode.Create, permissions: [PERMISSION_CODE.CREATE] }],
    },
    {
        url: '/messages/chat',
        function: FUNCTION_CODE.HELP_DESK_MESSAGES,
        permissions: [PERMISSION_CODE.READ],
    },

    // REPORTS > STOCK VARIANCE REPORT
    {
        url: '/reports/variance-report',
        function: FUNCTION_CODE.STOCK_VARIANCE_REPORT,
        permissions: [PERMISSION_CODE.READ],
    },

    // REPORTS > AUDIT TRAIL LOGS
    {
        url: '/reports/trail-logs',
        function: FUNCTION_CODE.AUDIT_TRAIL_LOGS,
        permissions: [PERMISSION_CODE.READ],
    },

    // SYSTEM ADMIN > MARKET MANAGEMENT
    {
        url: '/system-admin/markets',
        function: FUNCTION_CODE.MARKET_MANAGEMENT,
        permissions: [PERMISSION_CODE.READ],
    },
    {
        url: '/system-admin/markets/market-info',
        function: FUNCTION_CODE.MARKET_MANAGEMENT,
        modes: [
            { name: FormMode.Create, permissions: [PERMISSION_CODE.CREATE] },
            { name: FormMode.Detail, permissions: [PERMISSION_CODE.READ] },
            { name: FormMode.Edit, permissions: [PERMISSION_CODE.UPDATE] },
        ],
    },

    // SYSTEM ADMIN > ACCOUNT MANAGEMENT
    {
        url: '/system-admin/accounts',
        function: FUNCTION_CODE.ACCOUNT_MANAGEMENT,
        permissions: [PERMISSION_CODE.READ],
    },
    {
        function: FUNCTION_CODE.ACCOUNT_MANAGEMENT,
        url: '/system-admin/accounts/account-info',
        modes: [
            { name: FormMode.Create, permissions: [PERMISSION_CODE.CREATE] },
            { name: FormMode.Detail, permissions: [PERMISSION_CODE.READ] },
            { name: FormMode.Edit, permissions: [PERMISSION_CODE.UPDATE] },
        ],
    },
    {
        function: FUNCTION_CODE.ACCOUNT_MANAGEMENT,
        url: '/system-admin/accounts/external-info',
        modes: [
            { name: FormMode.Create, permissions: [PERMISSION_CODE.CREATE] },
            { name: FormMode.Detail, permissions: [PERMISSION_CODE.READ] },
            { name: FormMode.Edit, permissions: [PERMISSION_CODE.UPDATE] },
        ],
    },
    {
        url: '/system-admin/accounts/external-list',
        function: FUNCTION_CODE.ACCOUNT_MANAGEMENT,
        permissions: [PERMISSION_CODE.READ],
    },

    // SYSTEM ADMIN > PERMISSION MANAGMENT
    {
        url: '/system-admin/permissions',
        function: FUNCTION_CODE.ACCOUNT_MANAGEMENT,
        roles: [USER_GROUP.SYSTEM_ADMIN],
        permissions: [PERMISSION_CODE.READ],
    },
    {
        url: '/system-admin/permissions/permission-info',
        function: FUNCTION_CODE.ACCOUNT_MANAGEMENT,
        roles: [USER_GROUP.SYSTEM_ADMIN],
        modes: [
            { name: FormMode.Detail, permissions: [PERMISSION_CODE.READ] },
            { name: FormMode.Edit, permissions: [PERMISSION_CODE.UPDATE] },
        ],
    },

    // SYSTEM ADMIN > BIN CONFIGURATIONS MANAGEMENT
    {
        url: '/system-admin/bin-configuration',
        function: FUNCTION_CODE.BIN_CONFIGURATION,
        permissions: [PERMISSION_CODE.READ],
    },
];

interface Function {
    url: string;
    function: string;
    permissions?: string[];
    roles?: string[];
    modes?: { name: string; permissions: string[] }[];
}
