import { HTTP_STATUS } from "../enum/http-status";

export const HTTP_STATUS_CODE: {[err: string]:any} = {
    200: {
        code: HTTP_STATUS.OK,
        content: 'OK',
        header: '',
        message: '',
        status: 'ok',
    },
    201: {
        code: HTTP_STATUS.CREATED,
        content: 'Created',
        header: '',
        message: '',
        status: 'ok',
    },
    204: {
        code: HTTP_STATUS.NO_CONTENT,
        content: 'No Content',
        header: '',
        message: '',
        status: 'ok',
    },
    304: {
        code: HTTP_STATUS.NOT_MODIFIED,
        content: 'Not Modified',
        header: '',
        message: '',
        status: 'ok',
    },
    400: {
        code: HTTP_STATUS.BAD_REQUEST,
        content: 'Bad Request',
        header: 'Bad Request',
        message: 'The server could not serve the request due to invalid or corrupt data.',
        status: 'false',
    },
    401: {
        code: HTTP_STATUS.UNAUTHORIZED,
        content: 'Unauthorized',
        header: 'You are not allowed to access this system.',
        message: 'Contact your administrator or redirect to login page.',
        status: 'false',
    },
    403: {
        code: HTTP_STATUS.FORBIDDEN,
        content: 'Forbidden',
        header: 'Forbidden',
        message: 'You does not have access rights to the content, so the server is refusing to give the requested resource.',
        status: 'false',
    },
    404: {
        code: HTTP_STATUS.PAGE_NOT_FOUND,
        content: 'Not Found',
        header: 'Not Found',
        message: 'The resource requested could not be found on this server.',
        status: 'false',
    },
    0: {
        code: HTTP_STATUS.PAGE_NOT_FOUND,
        content: 'Not Found',
        header: 'Not Found',
        message: 'The resource requested could not be found on this server.',
        status: 'false',
    },
    409: {
        code: HTTP_STATUS.CONFLICT,
        content: 'Conflict',
        header: '',
        message: '',
        status: 'false',
    },
    500: {
        code: HTTP_STATUS.SERVER_ERROR,
        content: 'Internal Server Error',
        header: 'Internal Server Error',
        message: 'There is something has gone wrong on the web site\'s server which it doesn\'t know how to handle.',
        status: 'false',
    },
    502: {
        code: HTTP_STATUS.BAD_GATEWAY,
        content: 'Internal Server Error',
        header: 'Bad Gateway',
        message: 'There is an error with a network between servers on the internet or application timeout problem.',
        status: 'false',
    },
};

