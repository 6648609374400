import { ChangeDetectorRef, NgModule, Pipe, PipeTransform } from '@angular/core';

import { trimField } from '../../core/helper/text';

@Pipe({
    name: 'tooltip',
})

export class TooltipPipe implements PipeTransform {
    transform(value: number | string, element: HTMLElement, w: number, cdr: ChangeDetectorRef): any {
        const val = trimField(String(value));
        if (!val) return '';
        const isOverFlow = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;

        setTimeout(() => {
            cdr.markForCheck();
        }, 100);

        return isOverFlow ? (val || element.innerText) : '';
    }
}


@NgModule({
    declarations: [TooltipPipe],
    exports: [TooltipPipe]
  })
  export class EZTooltipModule { }