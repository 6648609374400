import { Message } from '../constant/message';
import { IMessageButton, IMessageItem } from '../interfaces/message';

export class MessageItem implements IMessageItem {
    message: string;
    header?: string;
    styleClass?: string;
    actions?: IMessageButton[];

    constructor(_message: string, _header?: string, _style?: string, _action?: IMessageButton[]) {
        this.message = _message;
        this.header = _header;
        this.styleClass = _style;
        this.actions = _action;
    }
}

export class MessageButton implements IMessageButton {
    btnName: string = '';
    icon?: string = '';
    styleClass?: string;
    action?: () => void;

    constructor(btnName: string, icon?: string, styleClass?: string, action?: () => void) {
        this.btnName = btnName;
        this.styleClass = styleClass;
        this.icon = icon;
        this.action = action;
    }
}

export class MessageConfirm extends MessageItem {
    constructor(message: string, action?: IMessageButton[]) {
        super(message, Message.Confirm.header, Message.Confirm.styleClass, action);
    }
}

export class MessageDelete extends MessageItem {
    constructor(message: string, action?: IMessageButton[]) {
        super(message, Message.Delete.header, Message.Delete.styleClass, action);
    }
}

export class MessageSuccess extends MessageItem {
    constructor(message: string, action?: IMessageButton[]) {
        super(message, Message.Success.header, Message.Success.styleClass, action);
    }
}

export class MessageWarning extends MessageItem {
    constructor(message: string, action?: IMessageButton[]) {
        super(message, Message.Warning.header, Message.Error.styleClass, action);
    }
}

export class MessageError extends MessageItem {
    constructor(message: string, action?: IMessageButton[]) {
        super(message, Message.Error.header, Message.Error.styleClass, action);
    }
}

export class MessageClose extends MessageItem {
    constructor(message: string, action?: IMessageButton[]) {
        super(message, Message.Confirm.header, Message.Confirm.styleClass, action);
    }
}

export class MessageButtonYes extends MessageButton {
    constructor(action?: () => void) {
        super('BTN_YES', 'pi pi-check', 'btn-secondary', action);
    }
}

export class MessageButtonNo extends MessageButton {
    constructor(action?: () => void) {
        super('BTN_NO', 'pi pi-undo', 'btn-gray', action);
    }
}

export class MessageButtonOk extends MessageButton {
    constructor(action?: () => void) {
        super('BTN_OK', 'pi pi-check', 'btn-secondary', action);
    }
}

export class MessageButtonCancel extends MessageButton {
    constructor(action?: () => void) {
        super('BTN_CANCEL', 'pi pi-times', 'btn-gray', action);
    }
}

// Specifically for use in dialogs with 3 buttons
export class MessageButtonClose extends MessageButton {
    constructor(action?: () => void) {
        super('BTN_CANCEL', 'pi pi-times', 'btn-gray', action);
    }
}
