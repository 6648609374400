import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    assignIn,
    concat,
    filter,
    includes,
    isArray,
    map,
    reduce,
    uniqBy,
} from 'lodash';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { ControlBaseComponent } from '../../../../base/components/control.component';
import { MultiSelectConfig } from '../../../../interfaces/form-config';
import { CoreHttpService } from '../../../../services/common/json.service';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { EZTooltipModule } from '@app/shared/pipes/tooltip.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { TextModule } from '../text/text.module';
import {
    MultiSelectValueLabelPipe,
    MultiSelectOptionLabelPipe,
} from './multi-select.pipe';

@Component({
    standalone: true,
    selector: 'app-multi-select',
    template: `
        <ng-container
            *ngIf="_config?.readonly || mode === FormMode.Detail; else input"
        >
            <app-text
                [config]="config"
                [control]="control"
                [customLabel]="
                    control.value | multiSelectValueLabel : _config : options
                "
            >
            </app-text>
        </ng-container>

        <ng-template #input>
            <p-multiSelect
                #select
                [ngClass]="{ 'font-bold': _config?.fontBold }"
                [display]="'chip'"
                [formControl]="control"
                [options]="options || _config?.options || []"
                [optionLabel]="_config?.optionLabel || 'label'"
                [optionValue]="_config?.optionValue || 'value'"
                [placeholder]="_config?.placeholder || '' | translate"
                [showToggleAll]="_config?.showToggleAll || false"
                [showClear]="_config?.showClear || false"
                [filter]="_config?.filter || false"
                [filterBy]="_config?.filterBy || ''"
                [filterValue]="filterValue"
                [autofocusFilter]="!isTouchscreen"
                [resetFilterOnHide]="_config?.resetFilterOnHide || false"
                (onFilter)="onFilter($event)"
                (onChange)="onChange($event)"
            >
                <ng-template
                    let-item
                    pTemplate="selectedItem"
                >
                    <div
                        #el
                        class="max-w-xs truncate"
                        [title]="
                            item | multiSelectOptionLabel : _config | translate
                        "
                    >
                        <span>
                            {{
                                item
                                    | multiSelectOptionLabel : _config
                                    | translate
                            }}
                        </span>
                    </div>
                </ng-template>

                <ng-template
                    let-item
                    pTemplate="item"
                >
                    <div
                        #el
                        class="max-w-xs truncate"
                        [title]="
                            item | multiSelectOptionLabel : _config | translate
                        "
                    >
                        <span>
                            {{
                                item
                                    | multiSelectOptionLabel : _config
                                    | translate
                            }}
                        </span>
                    </div>
                </ng-template>
            </p-multiSelect>
        </ng-template>
    `,
    styleUrls: ['./multi-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        MultiSelectModule,
        MultiSelectOptionLabelPipe,
        MultiSelectValueLabelPipe,
        TextModule,
        TooltipModule,
        EZTooltipModule,
    ],
})
export class MultiSelectComponent
    extends ControlBaseComponent<MultiSelectConfig>
    implements OnInit
{
    @Input() options?: any[];

    @Output() optionsChanges = new EventEmitter();

    keyword$ = new Subject();
    destroy$ = new Subject();
    results: any[] = [];
    selectedItems: any[] = [];
    filterValue = '';

    isTouchscreen: boolean = false;

    constructor(
        private _http: CoreHttpService,
        private _cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.isTouchscreen = 'ontouchstart' in document.documentElement;
        this.setUpFilterApi();
    }

    setUpFilterApi() {
        if (this._config?.filterApi) {
            const optionLabel = this._config.optionLabel || 'label';
            const optionValue = this._config.optionValue || 'value';

            this.keyword$
                .pipe(
                    takeUntil(this.destroy$),
                    debounceTime(300),
                    distinctUntilChanged()
                )
                .subscribe((data) => {
                    if (!data) data = '';

                    const params = this.getExtraParams();
                    const url = `${this._config?.filterApi}${data}${
                        params || ''
                    }`;
                    this.filterValue = data as string;

                    this._http
                        .search(this._http.setActionUrl(url))
                        .subscribe((res) => {
                            let newResult = map(res.data as any, (e) => {
                                return assignIn(e, {
                                    [optionLabel]:
                                        this._config?.format === 2
                                            ? this.getTemplate(
                                                  e,
                                                  optionValue,
                                                  optionLabel
                                              )
                                            : e[optionLabel],
                                });
                            });

                            this.options = uniqBy(
                                concat(this.selectedItems, newResult),
                                optionValue
                            );
                            if (this._config?.filterApi && this._config.cache)
                                this.optionsChanges.emit(this.options);
                            this._cdr.markForCheck();
                        });
                });
        }
    }

    onFilter(event: any) {
        if (this._config?.filterApi) this.keyword$.next(event.filter);
    }

    onChange(event: any) {
        const newSelections = event.value || [];
        const optionValue = this._config?.optionValue || 'value';
        if (newSelections.length > this.selectedItems.length) {
            newSelections.forEach((e: any) => {
                const foundItem = this.options?.find(
                    (x) => x[optionValue] === e
                );
                if (foundItem) this.selectedItems.push(foundItem);
            });
            this.selectedItems = uniqBy(this.selectedItems, optionValue);
        } else {
            this.selectedItems = filter(this.selectedItems, (e) =>
                includes(newSelections, e[optionValue])
            );
        }
    }

    private getExtraParams() {
        return reduce(
            this._config?.extra,
            (pre, cur) => {
                if (!cur.param) console.error('Params search not exist');

                // Map params when filterApi keyword has multiple values.
                const multipleValues = (): string => {
                    let values = '';
                    this.extra[cur.key].map((item: any) => {
                        values += `&${cur.param}=${
                            cur.value ||
                            item?.[cur.optionValue || 'value'] ||
                            item
                        }`;
                    });
                    return values;
                };

                const params = !isArray(this.extra[cur.key])
                    ? `&${cur.param}=${
                          cur.value ||
                          this.extra[cur.key]?.[cur.optionValue || 'value'] ||
                          this.extra[cur.key]
                      }`
                    : multipleValues();

                return pre + params;
            },
            ''
        );
    }

    private getTemplate(data: any, value: any, label: any) {
        const temp = [];

        if (data[value]) temp.push(data[value]);
        if (data[label]) temp.push(data[label]);

        return temp.join(' - ');
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
        this.keyword$.complete();
    }
}
