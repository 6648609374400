import { createReducer, on } from "@ngrx/store";
import { ConfigState } from "./market-config";
import { MarketConfigActions } from "./market-config.action";

export const reducer = createReducer(
    {} as ConfigState,
    on(MarketConfigActions.loadMarketSuccess, (state, { data }) => ({
        ...state,
        marketConfig: data
    })),
    on(MarketConfigActions.loadWarehouseSuccess, (state, { data }) => ({
        ...state,
        warehouseConfig: data
    })),
    on(MarketConfigActions.loadMarketInfoSuccess, (state, { data }) => ({
        ...state,
        marketInfoConfig: data
    }))
);