export enum UploadTemplateType {
    Document = 1,
    ProtocolDocument,
}

export class DateFormat {
    static readonly DateOnly = 'yyyy-MM-dd';
    static readonly DateOnlyFullMonth = 'yyyy-MMMM-dd';
    static readonly DateOnlyShortMonth = 'yyyy-MMM-dd';
    static readonly DateTime = 'yyyy-MM-dd HH:mm';
    static readonly DateTimeWithSecond = 'yyyy-MM-dd HH:mm:ss';
}

export class NumberFormat {
    static readonly DefaultMinValue = 0;
    static readonly DefaultMaxValue = 9999999.999;
    static readonly DefaultMinDecimalPlaces = 0;
    static readonly DefaultMaxDecimalPlaces = 3;
}

export enum StockCountOptionsType {
    Product = 1,
    Vendor,
    PID,
    Team,
    StorageBin,
    StorageType,
    StorageSection,
    Aisle,
    BinSection,
    BinLevel,
    HU,
}

export enum ProductInformationOptionType {
    Batch = 1,
    Owner,
}

export enum MaterialOptionType {
    EAN = 1,
    Vendor,
}

export enum DocumentType {
    SOH = 0,
    PID = 1,
}
