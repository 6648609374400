import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { GlobalVariables } from '@app/core/base/variable/global-variable';
import { APP_ROUTES } from '@app/routers/app.routes';
import { isUndefined } from 'lodash';
import { LoadingService } from '../../components/loading/loading.service';

@Injectable({ providedIn: 'root' })
export class AppGuard implements CanActivate {
    user = GlobalVariables.user;

    constructor(private _router: Router, private _loading: LoadingService) {}

    canActivate() {
        if (this.user.token.length) {
            if (isUndefined(this.user.profile.uid)) {
                this._router.navigate([APP_ROUTES.Unauthorized], {
                    state: { logout: true },
                });
            }

            this._loading.hide();
            return true;
        }

        this._router.navigate([APP_ROUTES.Home]);
        this._loading.hide();
        return false;
    }
}
