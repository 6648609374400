import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ConfigFeatureKey } from "./material-master-config";
import { reducer } from "./material-master-config.reducer";
import { MaterialMasterEffects } from "./material-master-config.effects";

@NgModule({
    imports: [StoreModule.forFeature(ConfigFeatureKey, reducer), EffectsModule.forFeature([MaterialMasterEffects])]
})
export class MaterialMasterConfigModule { }