import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({ selector: '[appendToDiv]' })
export class AppendToDivDirective implements AfterViewInit {
    @Input() group?: string;

    constructor(public elementRef: ElementRef) {}

    ngAfterViewInit(): void {
        const elements = document.querySelectorAll(`[group="${this.group}"]`);
        
        elements.forEach((e, i) => {
            (this.elementRef.nativeElement as Element).append(e);
        });
    }
}
