import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { EZTooltipModule } from 'projects/ezstock/src/app/shared/pipes/tooltip.pipe';

import { TextComponent } from './text.component';
import { OptionLabelPipe } from './text.pipe';

@NgModule({
    declarations: [TextComponent, OptionLabelPipe],
    imports: [CommonModule, TooltipModule, EZTooltipModule],
    exports: [TextComponent, OptionLabelPipe],
})
export class TextModule {}
