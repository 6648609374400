import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConfigFeatureKey } from './count-assignment-config';
import { CountAssignmentEffects } from './count-assignment-config.effects';
import { reducer } from './count-assignment-config.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(ConfigFeatureKey, reducer),
        EffectsModule.forFeature([CountAssignmentEffects])
    ]
})
export class CountAssignmentConfigModule {}
