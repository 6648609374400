import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalVariables } from '../../base/variable/global-variable';
import { CommonFunction } from '../../constant/common';
import { StorageService } from '../common/storage.service';

@Injectable({ providedIn: 'root' })
export class HttpRequestInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Added items to Header
        let headers = req.headers
            .set('Content-Type', 'application/json')
            .append('Accept-Language', GlobalVariables.language)
            .append('requestId', CommonFunction.autoGenerateGuidId())
            .append('language', GlobalVariables.language);

        // Access Token authorize for API
        if (GlobalVariables.user && GlobalVariables.user.token) {
            const authorizeToken = GlobalVariables.user.token;
            headers = headers.set('Authorization', `Bearer ${authorizeToken}`);

            const activeMarket = StorageService.get(StorageService.KEY_ACTIVE_MARKET) as any;
            const market_id = activeMarket
                ? activeMarket.id
                : GlobalVariables.user.profile?.activeMarket?.code;
            headers = headers.set('market-id', `${market_id}`);
            const timeZone = new Date().getTimezoneOffset();
            headers = headers.set('tz', `${timeZone}`);
        }

        req = req.clone({ headers: headers });

        return next.handle(req);
    }
}
