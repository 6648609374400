import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpBaseService } from '../../base/service/http-base.service';

@Injectable({providedIn: 'root'})
export class CoreHttpService extends HttpBaseService {
    constructor(private https: HttpClient) { 
        super(https)
    }

}