import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, of, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CoreHttpService } from '../../../core/services/common/json.service';
import { getFormConfig, getTableConfig } from '../page-configs.helper';
import {
    ASSIGN_FORM,
    STOCK_COUNT_REVIEW_DETAIL_FORM,
    STOCK_COUNT_REVIEW_OVERVIEW_FORM,
    STOCK_COUNT_REVIEW_SEARCH_FORM,
    STOCK_COUNT_REVIEW_TABLE,
} from './stock-count-review-config';
import { StockCountReviewActions } from './stock-count-review-config.action';
import { StockCountReviewSelector } from './stock-count-review-config.selector';

@Injectable()
export class StockCountReviewListEffects {
    constructor(private actions$: Actions, private _http: CoreHttpService, private store: Store) {}

    // Get Stock count list config
    stockCountReviewListConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(StockCountReviewActions.loadStockCountReviewConfigs),
            withLatestFrom(this.store.select(StockCountReviewSelector.listPageConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(STOCK_COUNT_REVIEW_SEARCH_FORM, this._http),
                    getTableConfig(STOCK_COUNT_REVIEW_TABLE, this._http),
                ]).pipe(
                    map(([searchFormConfig, tableConfig]) =>
                        StockCountReviewActions.loadStockCountReviewSuccess({
                            data: { searchConfig: searchFormConfig, tableConfig },
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    // Get Stock count detail config
    stockCountReviewOverviewConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(StockCountReviewActions.loadStockCountReviewOverviewConfigs),
            withLatestFrom(this.store.select(StockCountReviewSelector.overviewFormConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([getFormConfig(STOCK_COUNT_REVIEW_OVERVIEW_FORM, this._http)]).pipe(
                    map(([formConfig]) =>
                        StockCountReviewActions.loadStockCountReviewOverviewSuccess({
                            data: formConfig,
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    // Get Stock count detail config
    stockCountReviewDetailConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(StockCountReviewActions.loadStockCountReviewDetailConfigs),
            withLatestFrom(this.store.select(StockCountReviewSelector.detailsFormConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([getFormConfig(STOCK_COUNT_REVIEW_DETAIL_FORM, this._http)]).pipe(
                    map(([formConfig]) =>
                        StockCountReviewActions.loadStockCountReviewDetailSuccess({
                            data: formConfig,
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    // Get assign config
    assignConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(StockCountReviewActions.loadStockCountReviewAssignConfigs),
            withLatestFrom(this.store.select(StockCountReviewSelector.assignFormConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([getFormConfig(ASSIGN_FORM, this._http)]).pipe(
                    map(([assignFormConfig]) =>
                        StockCountReviewActions.loadStockCountReviewAssignConfigsSuccess({
                            data: assignFormConfig,
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });
}
