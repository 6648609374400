import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, of, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CoreHttpService } from '../../../core/services/common/json.service';
import { getFormConfig, getTableConfig } from '../page-configs.helper';
import {
    ACCOUNT_CRUD_FORM,
    ACCOUNT_ROLE_ASSIGN_FORM,
    ACCOUNT_ROLE_DETAILS_FORM,
    ACCOUNT_ROLE_TABLE,
    ACCOUNT_SEARCH_FORM,
    ACCOUNT_TABLE
} from './account-config';
import { AccountActions } from './account-config.action';
import { AccountSelector } from './account-config.selector';

@Injectable()
export class AccountEffects {
    constructor(private actions$: Actions, private _http: CoreHttpService, private store: Store) {}

    // Get Account List config
    accountListConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AccountActions.loadAccountListConfigs),
            withLatestFrom(this.store.select(AccountSelector.accountListConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(ACCOUNT_SEARCH_FORM, this._http),
                    getTableConfig(ACCOUNT_TABLE, this._http)
                ]).pipe(
                    map(([searchFormConfig, tableConfig]) =>
                        AccountActions.loadAccountListSuccess({
                            data: { searchConfig: searchFormConfig, tableConfig }
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    accountInfoConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AccountActions.loadAccountInfoConfigs),
            withLatestFrom(this.store.select(AccountSelector.accountInfoConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return forkJoin([
                    getFormConfig(ACCOUNT_CRUD_FORM, this._http),
                    getFormConfig(ACCOUNT_ROLE_ASSIGN_FORM, this._http),
                    getTableConfig(ACCOUNT_ROLE_TABLE, this._http)
                ]).pipe(
                    map(([formConfig, roleFormConfig, roleTableConfig]) =>
                        AccountActions.loadAccountInfoSuccess({
                            data: { formConfig, roleFormConfig, roleTableConfig }
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });

    roleSidebarConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AccountActions.loadRoleSidebarConfigs),
            withLatestFrom(this.store.select(AccountSelector.roleSidebarConfig)),
            switchMap(([, state]) => {
                if (state) return of();
                return getFormConfig(ACCOUNT_ROLE_DETAILS_FORM, this._http).pipe(
                    map((formConfig) =>
                        AccountActions.loadRoleSidebarSuccess({
                            data: formConfig
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        );
    });
}
