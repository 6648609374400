export const Control = {
    Calendar: 'calendar',
    Checkbox: 'checkbox',
    Chips: 'chips',
    Div: 'div',
    Empty: 'empty',
    Label: 'label',
    InputText: 'inputtext',
    InputNumber: 'inputnumber',
    InputSwitch: 'inputswitch',
    TextArea: 'textarea',
    Dropdown: 'dropdown',
    MultiSelect: 'multiselect',
    MultiCheckbox: 'multicheckbox',
    FileUpload: 'fileupload',
    Radio: 'radio',
    Number: 'number',
    StatusLabel: 'statusLabel',
    Panel: 'panel',
    SelectButton: 'selectbutton',
};

export const FormType = {
    Custom: 'custom',
    Form: 'form',
    Table: 'table',
};

export const FormMode = {
    Detail: 'detail',
    Edit: 'edit',
    Create: 'create',
};

export const IgnoreControl = [Control.Panel, Control.Label];
