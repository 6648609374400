import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LazyLoadTranslateModule } from '@app/core/modules/lazy-load-translate.module';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ControlBaseComponent } from '../../../../base/components/control.component';
import { LENGTH } from '../../../../constant/control-config';
import { TextAreaConfig } from '../../../../interfaces/form-config/text-area';
import { TextModule } from '../text/text.module';

@Component({
    standalone: true,
    selector: 'app-text-area',
    template: `
        <ng-container
            *ngIf="_config?.readonly || mode === FormMode.Detail; else input">
            <app-text
                [config]="config"
                [control]="control">
            </app-text>
        </ng-container>

        <ng-template #input>
            <textarea
                [ngClass]="{
                    'font-bold': _config?.fontBold,
                    'border-none': _config?.noBorder,
                    shadow: _config?.shadow
                }"
                class="flex textarea-height"
                type="textarea"
                pInputTextarea
                [formControl]="control"
                [placeholder]="_config?.placeholder || '' | translate"
                [maxlength]="_config?.maxLength || MAX_LENGTH">
            </textarea>
        </ng-template>
    `,
    styleUrls: ['./text-area.component.scss'],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        LazyLoadTranslateModule,
        InputTextareaModule,
        TextModule,
    ],
})
export class TextAreaComponent extends ControlBaseComponent<TextAreaConfig> {
    MAX_LENGTH = LENGTH.MULTI_LINE;

    constructor() {
        super();
    }
}
