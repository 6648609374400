<ng-container *ngIf="messageItem$ | async as messageItem">
    <p-dialog
        [(visible)]="visible"
        [styleClass]="'message-dialog'"
        [modal]="true"
        [baseZIndex]="90000"
        [draggable]="false"
        [closeOnEscape]="false"
        [closable]="true"
        (onHide)="onHide()"
    >
        <div class="msg">
            <div class="msg-icon">
                <img
                    alt=""
                    [ngClass]="messageItem?.styleClass || ''"
                    [src]="'../../../../assets/img/d-' + messageItem?.styleClass + '.svg'"
                />
            </div>
            <span class="msg-header">{{ messageItem?.header || '' | translate }}</span>
            <span class="msg-content">{{ messageItem?.message || '' | translate }}</span>
        </div>

        <ng-template pTemplate="footer">
            <div class="flex justify-center">
                <app-button
                    [label]="item.btnName | translate"
                    [icon]="item.icon || ''"
                    *ngFor="let item of messageItem?.actions"
                    [styleClass]="'btn ' + item.styleClass"
                    (actionClick)="onAction(item)"
                ></app-button>
            </div>
        </ng-template>
    </p-dialog>
</ng-container>
