import { createReducer, on } from '@ngrx/store';
import { ConfigState } from './action-history-config';
import { ActionHistoryActions } from './action-history-config.action';

export const reducer = createReducer(
    {} as ConfigState,
    on(ActionHistoryActions.loadSearchFormSuccess, (state, {data}) => ({
        ...state,
        searchConfig: data
    })),
    on(ActionHistoryActions.loadMaterialTableSuccess, (state, {data}) => ({
        ...state,
        materialTable: data
    })),
    on(ActionHistoryActions.loadProductTableSuccess, (state, {data}) => ({
        ...state,
        productTable: data
    })),
    on(ActionHistoryActions.loadStorageTableSuccess, (state, {data}) => ({
        ...state,
        storageBinTable: data
    })),
    on(ActionHistoryActions.loadActionHistoryDetailSuccess, (state, {data}) => ({
        ...state,
        detailConfig: data
    }))
);
