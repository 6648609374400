import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConfigFeatureKey } from './action-history-config';
import { ActionHistoryEffects } from './action-history-config.effects';
import { reducer } from './action-history-config.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(ConfigFeatureKey, reducer),
        EffectsModule.forFeature([ActionHistoryEffects])
    ]
})
export class ActionHistoryConfigModule {
}
