import { Pipe, PipeTransform } from '@angular/core';
import { InputNumberConfig } from '../../../../interfaces/form-config';

@Pipe({
    standalone: true,
    name: 'inputNumberClass',
})
export class InputNumberClassPipe implements PipeTransform {
    transform(config: InputNumberConfig | undefined): string {
        const styleClass = ['w-full'];

        if (config?.fontBold) styleClass.push('bold');
        if (config?.noBorder) styleClass.push('border-none');

        return styleClass.join(' ');
    }
}
