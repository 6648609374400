import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DocumentInfoEffects } from "./document-info-config.effects";
import { ConfigFeatureKey } from "./document-info-config";
import { reducer } from "./document-info-config.reducer";

@NgModule({
    imports: [StoreModule.forFeature(ConfigFeatureKey, reducer), EffectsModule.forFeature([DocumentInfoEffects])]
})
export class DocumentInfoConfigModule {
}
