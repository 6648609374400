import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LazyLoadTranslateModule } from '@app/core/modules/lazy-load-translate.module';
import { EZTooltipModule } from '@app/shared/pipes/tooltip.pipe';
import {
    assignIn,
    every,
    first,
    get,
    head,
    isEqual,
    isObject,
    isString,
    isUndefined,
    map,
    reduce,
    some,
    uniqBy,
} from 'lodash';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { ControlBaseComponent } from '../../../../base/components/control.component';
import { LENGTH } from '../../../../constant/control-config';
import { isEmpty } from '../../../../helper/validate';
import { DropdownConfig } from '../../../../interfaces/form-config';
import { CoreHttpService } from '../../../../services/common/json.service';
import { ButtonComponent } from '../../../button/button/button.component';
import { TextModule } from '../text/text.module';
import { Control } from '@app/core/constant/form';
import { ControlAction } from '@app/core/interfaces/form-config/basic-form';

@Component({
    standalone: true,
    selector: 'app-dropdown',
    template: `
        <div class="input-wrapper">
            <ng-container
                *ngIf="
                    _config?.readonly || mode === FormMode.Detail;
                    else input
                "
            >
                <app-text
                    [config]="config"
                    [control]="control"
                    [customLabel]="
                        options || _config?.options || []
                            | optionLabel : control.value : _config
                    "
                >
                </app-text>
            </ng-container>

            <ng-template #input>
                <p-dropdown
                    [ngClass]="{ 'font-bold': _config?.fontBold }"
                    [formControl]="control"
                    [options]="options || _config?.options || []"
                    [optionLabel]="_config?.optionLabel || 'label'"
                    [optionValue]="_config?.optionValue || 'value'"
                    [placeholder]="_config?.placeholder || '' | translate"
                    [filter]="_config?.filter || false"
                    [filterBy]="_config?.filterBy || ''"
                    [autofocusFilter]="!isTouchscreen"
                    (onFilter)="onFilter($event)"
                    [showClear]="_config?.showClear || false"
                    [appendTo]="_config?.appendTo || undefined"
                >
                    <ng-template
                        let-item
                        pTemplate="selectedItem"
                    >
                        <span>
                            {{
                                item[_config?.optionLabel || 'label']
                                    | translate
                            }}
                        </span>
                    </ng-template>

                    <ng-template
                        let-item
                        pTemplate="item"
                    >
                        <div
                            #el
                            class="truncate"
                            [pTooltip]="
                                item[_config?.optionLabel || 'label']
                                    | translate
                                    | tooltip : el : el.scrollWidth : _cdr
                            "
                        >
                            <span>
                                {{
                                    item[_config?.optionLabel || 'label']
                                        | translate
                                }}
                            </span>
                        </div>
                    </ng-template>
                </p-dropdown>

                <app-button
                    *ngIf="_config?.button"
                    [label]="_config?.button?.label || 'Button' | translate"
                    [icon]="_config?.button?.icon || ''"
                    [styleClass]="'btn ' + _config?.button?.styleClass"
                    (actionClick)="onActionClick()"
                >
                </app-button>
            </ng-template>
        </div>
    `,
    styleUrls: ['./dropdown.component.scss'],
    imports: [
        CommonModule,
        LazyLoadTranslateModule,
        ReactiveFormsModule,
        DropdownModule,
        TextModule,
        TooltipModule,
        EZTooltipModule,
        ButtonComponent,
    ],
})
export class DropdownComponent
    extends ControlBaseComponent<DropdownConfig>
    implements OnInit, OnDestroy
{
    @Input() options?: any[];

    @Output() searchData = new EventEmitter<any[]>();
    @Output() actionClick = new EventEmitter<ControlAction>();

    keyword$ = new Subject();
    destroy$ = new Subject();
    filterValue = '';

    isTouchscreen: boolean = false;

    constructor(
        private _http: CoreHttpService,
        public _cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.isTouchscreen = 'ontouchstart' in document.documentElement;
        this.setUpFilterApi();
    }

    onActionClick() {
        this.actionClick.emit({
            controlKey: this.key,
            controlType: Control.Dropdown,
        });
    }

    setUpFilterApi() {
        if (this._config?.filterApi) {
            const optionLabel = this._config.optionLabel || 'label';
            const optionValue = this._config.optionValue || 'value';
            // Search key when has value to get option for dropdown
            this.control.valueChanges
                .pipe(
                    takeUntil(this.destroy$),
                    debounceTime(300),
                    distinctUntilChanged()
                )
                .subscribe((res) => {
                    if (
                        res &&
                        isString(res) &&
                        every(this.options, (e) => e[optionValue] !== res)
                    ) {
                        const code = head(res.split(' - '));
                        this.keyword$.next(code);
                    }
                });

            this.keyword$
                .pipe(
                    takeUntil(this.destroy$),
                    debounceTime(300),
                    distinctUntilChanged()
                )
                .subscribe((data: any) => {
                    if (!data) data = '';

                    if (data && data?.length > LENGTH.SINGLE_LINE)
                        data = data?.slice(0, LENGTH.SINGLE_LINE);

                    const params = this.getExtraParams();
                    const url = `${this._config?.filterApi}${data}${
                        params || ''
                    }`;

                    this._http
                        .search(this._http.setActionUrl(url))
                        .subscribe((res) => {
                            this.options =
                                map(res.data as any, (e) => {
                                    return assignIn(e, {
                                        [optionLabel]: `${e[optionLabel]}`,
                                    });
                                }) || [];
                            this.searchData.emit(this.options);
                            this._cdr.markForCheck();
                        });
                });
        }
    }

    private getExtraParams() {
        return reduce(
            this._config?.extra,
            (pre, cur) => {
                if (!cur.param) console.error('Params search not exist');

                const params = `&${cur.param}=${
                    cur.value ||
                    this.extra[cur.key]?.[cur.optionValue || 'value'] ||
                    this.extra[cur.key]
                }`;

                return pre + params;
            },
            ''
        );
    }

    private getTemplate(data: any, value: any, label: any) {
        const temp = [];

        if (data[value]) temp.push(data[value]);
        if (data[label] && !this._config?.onlyValue) temp.push(data[label]);

        return {
            [value]: temp.join(' - '),
            [label]: temp.join(' - '),
        };
    }

    onFilter(event: any) {
        if (this._config?.filterApi) this.keyword$.next(event.filter);
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.unsubscribe();
    }

    override onChanges(changes: SimpleChanges): void {
        let currentOptions = changes['options']?.currentValue;
        let previousOptions = changes['options']?.previousValue;
        const value = changes['value']?.currentValue;
        const optionValue = this._config?.optionValue || 'value';
        const optionLabel = this._config?.optionLabel || 'label';
        const options =
            this._config?.keepOldValue === true && !isUndefined(previousOptions)
                ? uniqBy(currentOptions?.concat(previousOptions), 'id')
                : currentOptions;
        // set mac dinh khi co config hasDefaultValue nhung khong co control value
        if (
            options?.length > 0 &&
            this._config?.hasDefaultValue &&
            !this.control.value
        ) {
            this.control.setValue(
                (first(options) as any)?.[optionValue] || null
            );
        }

        // reset value neu trong option khong co option value nao bang control value
        if (
            options?.length > 0 &&
            !isEmpty(this.control.value) &&
            every(
                options,
                (e) => !isEqual(e[optionValue], this.control.value)
            ) &&
            this._config?.keepOldValue !== true
        ) {
            this.control.setValue(null, { emitModelToViewChange: false });
        }

        // auto chon option co option value bang control value
        if (
            some(options, (e) =>
                isEqual(e[optionValue], this.control.value || this.value)
            )
        ) {
            this.control.setValue(this.control.value || this.value, {
                emitModelToViewChange: false,
            });
        }

        // If value is object and option is empty so auto set option and value as a first element
        if (value && isObject(value)) {
            const optionTemp = this.getTemplate(
                value,
                optionValue,
                optionLabel
            );
            if (isEmpty(this.options)) {
                this.options = [optionTemp];
            } else {
                this.options?.unshift(optionTemp);
                this.options = uniqBy(this.options, optionValue);
            }
            this.control.setValue(get(this.options, [0, optionValue]), {
                emitModelToViewChange: false,
            });
        }
    }
}
