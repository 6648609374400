import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigFeatureKey, ConfigState } from './external-config';

const PageConfigState = createFeatureSelector<ConfigState>(ConfigFeatureKey);

export class ExternalSelector {
    static readonly externalAccountListConfig = createSelector(
        PageConfigState,
        (state) => state.externalAccountList
    );

    static readonly externalAccountInfoConfig = createSelector(
        PageConfigState,
        (state) => state.externalAccountInfo
    );

    static readonly roleSidebarConfig = createSelector(
        PageConfigState,
        (state) => state.roleSidebar
    );
}
