
export enum HTTP_STATUS {
    UNKNOWN = '0',
    OK = '200',
    CREATED = '201',
    NO_CONTENT = '204',
    NOT_MODIFIED = '304',
    BAD_REQUEST = '400',
    UNAUTHORIZED = '401',
    FORBIDDEN = '403',
    PAGE_NOT_FOUND = '404',
    CONFLICT = '409',
    SERVER_ERROR = '500',
    BAD_GATEWAY = '502'
}