import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigFeatureKey, ConfigState } from './permission-config';

const PageConfigState = createFeatureSelector<ConfigState>(ConfigFeatureKey);

export class RoleSelectors {
    static readonly roleListConfig = createSelector(PageConfigState, (state) => state.roleList);
    static readonly rolePermissionsConfig = createSelector(
        PageConfigState,
        (state) => state.rolePermissions
    );
}
